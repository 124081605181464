import axios from "axios";
import { getAuthorizationToken } from "../config/user";
const REACT_APP_API_URL = process.env.REACT_APP_API_URL;

function gethospitals(searchKey, page, limit) {
  return axios({
    method: "get",
    url: `${REACT_APP_API_URL}/hospital-name-list?searchKey=${searchKey}&page=${page} &limit=${limit}`,
    headers: getAuthorizationToken(),
  });
}
function getHospitalSubscriptionList(searchKey, page, limit) {
  return axios({
    method: "get",
    url: `${REACT_APP_API_URL}/hospital-subscription-and-history?searchKey=${searchKey}&page=${page}&limit=${limit}`,
    headers: getAuthorizationToken(),
  });
}
function getHospitalUserList(searchKey, page = 1, limit = 10) {
  return axios({
    method: "get",
    url: `${REACT_APP_API_URL}/hospital-user-list?searchKey=${searchKey}&page=${page}&limit=${limit}`,
    headers: getAuthorizationToken(),
  });
}

function getHospitalCases(searchKey, page, limit, status = "") {
  return axios({
    method: "get",
    url: `${REACT_APP_API_URL}/hospital-all-cases?searchKey=${searchKey}&page=${page}&limit=${limit}&status=${status}`,
    headers: getAuthorizationToken(),
  });
}
function getHospitalList(searchKey, page = 1, limit = 48) {
  return axios({
    method: "get",
    url: `${REACT_APP_API_URL}/hospital-subscription-and-history?searchKey=${searchKey}&page=${page}&limit=${limit}`,
    headers: getAuthorizationToken(),
  });
}

function addNewHospital(data) {
  return axios({
    method: "post",
    url: `${REACT_APP_API_URL}/add-new-hospital`,
    data: data,
    headers: getAuthorizationToken(),
  });
}
export const getInactiveHospitals = (searchKey, page, limit) => {
  return axios({
    method: "get",
    url: `${REACT_APP_API_URL}/inactive-hospitals?searchKey=${searchKey}&page=${page}&limit=${limit}`,
    headers: getAuthorizationToken(),
  });
};

export const addHospitalDetails = (values) => {
  return axios({
    method: "post",
    url: `${REACT_APP_API_URL}/add-new-hospital`,
    headers: getAuthorizationToken(),
    data: {
      name: values.name,
      country: values.country,
      state: values.state,
      city: values.city,
      pincode: values.pincode,
      businessDocumentType: values.businessDocumentType,
      businessDocumentUrl: values.businessDocumentUrl,
      businessDocumentNumber: values.businessDocumentNumber,
      pmjayUserId: "",
    },
  });
};
export const addHospitalUser = (values) => {
  return axios({
    method: "post",
    url: `${REACT_APP_API_URL}/add-hospital-user`,
    headers: getAuthorizationToken(),
    data: {
      name: values.name,
      roleName: "Hospital",
      phone: values.phone,
      email: values.email,
      hospitalId: values.hospitalId,
      password: values.password,
    },
  });
};
export const mapSubscriptionToHospital = (values) => {
  return axios({
    method: "post",
    url: `${REACT_APP_API_URL}/superadmin-subscription-mapping`,
    headers: getAuthorizationToken(),
    data: {
      hospitalId: values.hospitalId,
      subscriptionId: values.subscriptionId,
      transactionId: values.transactionId,
      paymentType: values.paymentType,
      amount: values.amount,
      transactionDate: values.transactionDate,
      status: "paid",
    },
  });
};
export const mapSubscriptionPlan = (data) => {
  return axios({
    method: "post",
    url: `${REACT_APP_API_URL}/map-subscription-plan`,
    headers: getAuthorizationToken(),
    data: data,
  });
};

export const updatehospital = (hospitalId, data) => {
  return axios({
    method: "put",
    url: `${REACT_APP_API_URL}/update-hospital-details/${hospitalId}`,
    data: data,
    headers: getAuthorizationToken(),
  });
};

export const hospitalupdate = (hospitalId, data) => {
  return axios({
    method: "put",
    url: `${REACT_APP_API_URL}/update-hospital-details/${hospitalId}`,
    data: data,
    headers: getAuthorizationToken(),
  });
};

export const updateUserRpaDetails = (data) => {
  return axios({
    method: "patch",
    url: `${REACT_APP_API_URL}/update-user`,
    data: data,
    headers: getAuthorizationToken(),
  });
};

export const approveHospital = (hospitalId, status) => {
  return axios({
    method: "put",
    url: `${REACT_APP_API_URL}/hospital-status-update/${hospitalId}?status=${status}`,
    headers: getAuthorizationToken(),
  });
};
export const addDoctor = (values) => {
  const data = [{ ...values, specialityIds: [values.specialityIds] }];
  return axios({
    method: "post",
    url: `${REACT_APP_API_URL}/add-doctor`,
    data: data,
    headers: getAuthorizationToken(),
  });
};
function getUniqueSpecialityList(searchKey, page, limit) {
  return axios({
    method: "get",
    url: `${REACT_APP_API_URL}/unique-speciality-list?searchKey=${searchKey}&page=${page}&limit=${limit}`,
    headers: getAuthorizationToken(),
  });
}
function getDoctorList(searchKey, page, limit, hospitalId) {
  return axios({
    method: "get",
    url: `${REACT_APP_API_URL}/doctors-list/${hospitalId}?specialityId=${searchKey}&page=${page}&limit=${limit}`,
    headers: getAuthorizationToken(),
  });
}

export const HospitalServices = {
  gethospitals,
  getHospitalCases,
  addNewHospital,
  mapSubscriptionPlan,
  getHospitalSubscriptionList,
  addDoctor,
  getUniqueSpecialityList,
  getDoctorList,

  getHospitalList,
  getHospitalUserList,
  updatehospital,
  hospitalupdate,
  updateUserRpaDetails,
};
