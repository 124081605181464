import { useState, useEffect, useRef } from "react";
import CloseIcon from "../../../assets/crossIcon.svg";
import { PreinitiateServices } from "../../../services/PreinitiateServices";
import { AuthServices } from "../../../services/AuthServices";
import { CaseServices } from "../../../services/CaseServices";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { logOut } from "../../../config/user";
import DeleteIcon from "../../../assets/deleteIcon.svg";
import TooltipIcon from "../../../assets/tooltipTriangle.svg";
import MapDocumentToUrl from "./MapDocumentToUrl";
import UnmapDocument from "./UnmapDocument";
import RejectionPopUp from "./RejectionPopUp";
import { CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";
import AddIcon from "../../../assets/addMoreIcon.svg";
import PdfIcon from "../../../assets/pdfIcon.png";
import ImagesGallery from "./ImagesGallery";
import CollectionsOutlinedIcon from "@mui/icons-material/CollectionsOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import NewReleasesOutlinedIcon from "@mui/icons-material/NewReleasesOutlined";
import { useNavigate } from "react-router-dom";
import { pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

const PreinitiateModal = ({
  selectedCase,
  isOpen,
  subStatusList,
  AllCases,
}) => {
  const [referenceDocuments, setReferenceDocuments] = useState([]);
  const [isDocUploading, setDocUploading] = useState(false);
  const [description, setDescription] = useState(null);
  const [docUrl, setDocUrl] = useState(null);
  const [reUploadedDocUrl, setReuploadedDocUrl] = useState(null);
  // const [sequence, setSequence] = useState(1);
  const [pdfDocument, setPdfDocument] = useState(null);
  // const [documentName, setDocumentName] = useState(null);
  const [images, setImages] = useState([]);
  const [referencePendingModal, setReferencePendingModal] = useState(false);
  const [percentage, setPercentage] = useState(0);
  const [isReuploading, setReuploading] = useState(false);
  const [selectedDoc, setSelectedDoc] = useState(null);
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isRejectionPopUp, setRejectionPopUp] = useState(false);
  const [openModalId, setOpenModalId] = useState(false);
  const [mapMultipleDocument, setMultipleDocument] = useState(false);
  const [openImagesGallery, setOpenImagesGallery] = useState(false);
  const [UnmapDocId, setUnmapDocId] = useState(null);
  const [reason, setReason] = useState("");
  const navigate = useNavigate();
  const fileInputRef = useRef(null);
  const reUploadFileRef = useRef(null);
  const currentRole = useSelector((store) => store.currentRole.value);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };
  const handleReUploadButtonClick = () => {
    reUploadFileRef.current.click();
  };
  //Method to upload the pdf doc
  async function uploadDocument(event) {
    setDocUploading(true);
    try {
      const file = event.target.files[0];
      const fileType = file.type.split("/")[0];
      if (fileType === "application" && file.name.endsWith(".pdf")) {
        setDescription(event.target.files[0].name.slice(0, -4));
        const uri = URL.createObjectURL(file);
        const _PDF_DOC = await pdfjs.getDocument(uri).promise;
        setPdfDocument(_PDF_DOC);
      } else if (fileType === "image") {
        const imageBlob = URL.createObjectURL(file);
        setImages([imageBlob]);
      }
    } catch (error) {
      alert(error.message);
    }
  }
  //Method to get the image list from the uploaded pdf document
  async function getImageList() {
    const imagesList = [];
    const canvas = document.createElement("canvas");
    canvas.setAttribute("class", "canv");
    try {
      for (let i = 1; i <= pdfDocument.numPages; i++) {
        const page = await pdfDocument.getPage(i);
        const viewport = page.getViewport({ scale: 3 });
        canvas.height = viewport.height;
        canvas.width = viewport.width;
        const renderContext = {
          canvasContext: canvas.getContext("2d"),
          viewport: viewport,
        };

        await page.render(renderContext).promise;
        canvas.toBlob((blob) => {
          if (blob) {
            const blobUrl = URL.createObjectURL(blob);
            imagesList.push(blobUrl);
            if (i === pdfDocument.numPages) {
              setImages(imagesList);
            }
          }
        }, "image/png");
      }
    } catch (error) {
      console.error("Error getting image list:", error);
    } finally {
    }
  }
  //Method to upload the image list's blob data to s3 bucket
  const uploadImages = async () => {
    let data = new FormData();
    for (let index = 0; index < images.length; index++) {
      let image = images[index];
      if (!(image instanceof Blob)) {
        const response = await fetch(image);
        image = await response.blob();
      }
      data.append("images", image, `${description + "_" + index}.jpeg`);
    }
    try {
      let res = await AuthServices.uploadPdfToImage(data, setPercentage);
      console.log("Image data resonse from apis", res.data.urls);
      setDocUrl(res?.data?.urls);
      setPdfDocument(null);
      setImages([]);
      window.alert("Image uploaded successfully");
    } catch (error) {
      console.error("Upload failed:", error);
    }
    setDocUploading(false);
  };
  const uploadImage = async (e) => {
    setDocUploading(true);
    setDescription(e.target.files[0].name.slice(0, -4));
    try {
      let data = new FormData();
      data.append("image", e.target.files[0]);
      let res = await AuthServices.splitAndUploadImage(data);
      window.alert("Image uploaded successfully");
      setDocUrl(res?.data?.urls);
    } catch (error) {
      if (error?.response?.status === 401) {
        logOut();
      } else {
        window.alert(error);
      }
    }
    setDocUploading(false);
  };
  const reUploadImage = async (e) => {
    setReuploading(true);
    try {
      let data = new FormData();
      data.append("image", e.target.files[0]);
      let res = await AuthServices.uploadImage(data);

      await PreinitiateServices.updateReferenceDocument(
        {
          documentUrl: res?.data?.url,
        },
        selectedDoc.id
      );
      window.alert("Image uploaded successfully");
      getDocumentsData();
      setReuploadedDocUrl(res?.data?.url);
    } catch (error) {
      if (error?.response?.status === 401) {
        logOut();
      } else {
        window.alert(error);
      }
    }
    setReuploading(false);
  };
  const getDocumentsData = async () => {
    setIsLoading(true);
    setSelectedDocuments([]);
    try {
      const response = await PreinitiateServices.getReferenceDocuments(
        selectedCase.id
      );
      setReferenceDocuments(response.data);
    } catch (error) {
      window.alert(error);
    }
    setIsLoading(false);
  };
  const handleStatusChange = async () => {
    setIsLoading(true);
    const filteredStage = subStatusList?.filter(
      (status, idx) => status.id === 176
    );
    if (reason) {
      try {
        const postStageData = {
          hsaStage: filteredStage[0]?.hsaWebStatus,
          commandCeneterStage: filteredStage[0]?.commandCenterStageStatus,
          nextStepUser: filteredStage[0]?.nextStepUser,
          caseDetails: [
            {
              query: "",
              queryResponse: reason,
            },
          ],
        };
        const stageResponse = await CaseServices.updateSubMasterStage(
          selectedCase.id,
          postStageData
        );
        window.alert(stageResponse.data.message);
        setReferencePendingModal(false);
        isOpen(false);

        navigate("/dashboard/ActiveCases");
      } catch (error) {
        if (error?.response?.status === 401) {
          logOut();
        } else {
          window.alert(error);
        }
      }
    } else {
      window.alert("Reason is Required");
    }
    setIsLoading(false);
  };
  const handleDocumentSelect = (doc) => {
    //if selected document is not present in the list then this block will get executed
    if (
      selectedDocuments.findIndex((document) => document.id === doc.id) === -1
    ) {
      setSelectedDocuments([...selectedDocuments, doc]);
    }
    //If document is already present in the list then in that case we are removing it from the list
    else {
      const modifiedData = selectedDocuments.filter(
        (document) => document.id !== doc.id
      );
      setSelectedDocuments(modifiedData);
    }
  };

  //This method is to save the updated document data
  const updateDocumentData = async () => {
    const data = {
      documentUrl: reUploadedDocUrl,
    };
    try {
      if (selectedDoc.id) {
        await PreinitiateServices.updateReferenceDocument(data, selectedDoc.id);
      }

      setSelectedDoc(null);
      setReuploadedDocUrl(null);
      getDocumentsData();
    } catch (error) {
      window.alert(error);
    }
  };

  //This is called to delete a selected document
  const deleteDocument = async (docId) => {
    try {
      const response = PreinitiateServices.deleteReferenceDocument(docId);

      getDocumentsData();
    } catch (error) {
      window.alert(error);
    }
  };

  //This method is called to save the newly uploaded document
  const addDocument = async () => {
    setIsSaving(true);
    if (docUrl) {
      const sequence =
        referenceDocuments.length > 0 ? referenceDocuments.length + 1 : 1; //here we are generating sequence for the newly added document
      const modifiedData = docUrl?.map((url, idx) => {
        return {
          caseId: selectedCase.id,
          documentUrl: url,
          sequence: sequence + idx,
          description: `${description}_${idx}`,
        };
      });
      try {
        await PreinitiateServices.addReferenceDocuments(modifiedData);
        window.alert("Documents Added Successfully ");
        getDocumentsData();
      } catch (error) {
        window.alert(error);
      }
      setDescription(null);
    }
    setPercentage(0);
    setIsSaving(false);
  };
  const getMappedDocumentName = (mappedDocs) => {
    if (mappedDocs.length > 0) {
      const modifiedData = mappedDocs?.map((doc) => doc.documentName);
      return modifiedData.join(", ");
    }
  };
  useEffect(() => {
    getDocumentsData();
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);
  useEffect(() => {
    pdfDocument && getImageList();
  }, [pdfDocument]);
  useEffect(() => {
    images.length > 0 && uploadImages();
  }, [images]);
  console.log("substatus list:", subStatusList);
  return (
    <div className="fixed top-5 left-0 w-screen h-screen flex justify-center items-center bg-[#017C5780] z-[1200]">
      <div className="relative bg-white rounded-[16px] w-[90%] xl:w-[85%] 2xl:w-[75%] h-[80%] p-4 xl:p-8">
        <div className="absolute flex gap-2 top-4 right-4 xl:top-5 xl:right-12">
          {currentRole?.role?.roleName?.toLowerCase().includes("team") &&
            referenceDocuments &&
            referenceDocuments.length > 0 && (
              <div
                className="flex items-center gap-1 text-sm text-white bg-[#017C57] px-4 py-1 rounded-full cursor-pointer"
                onClick={() => setOpenImagesGallery(true)}
              >
                <CollectionsOutlinedIcon />
                View Gallery
              </div>
            )}
          {openImagesGallery && (
            <ImagesGallery
              setOpen={setOpenImagesGallery}
              data={referenceDocuments}
            />
          )}
          {currentRole?.role?.roleName?.toLowerCase().includes("team") &&
            selectedDocuments &&
            selectedDocuments.length > 0 && (
              <div
                className="flex items-center gap-1 text-sm text-white bg-[#017C57] px-4 py-1 rounded-full cursor-pointer"
                onClick={() => setMultipleDocument(true)}
              >
                <CollectionsOutlinedIcon />
                Map Multiple Documents
              </div>
            )}
          {mapMultipleDocument && (
            <MapDocumentToUrl
              caseId={selectedCase.id}
              selectedDoc={selectedDocuments}
              isOpen={setMultipleDocument}
              getDocumentsData={getDocumentsData}
            />
          )}
          <img
            src={CloseIcon}
            height={30}
            width={30}
            alt="close-icon"
            onClick={() => {
              isOpen(false);
              AllCases();
            }}
            className="cursor-pointer"
          />
        </div>
        <div className="absolute top-4 left-4 xl:top-5 xl:left-8">
          <div className="text-[#191919] font-semibold text-xl xl:text-2xl mb-2 xl:mb-4">
            Documents Added
          </div>
        </div>
        <div className="flex flex-col justify-between h-full">
          <div className="flex flex-col items-center mt-5 overflow-y-scroll mb-2 xl:mb-4 min-h-[50%]">
            {isLoading ? (
              <div className=" mt-12">
                <CircularProgress />{" "}
              </div>
            ) : referenceDocuments && referenceDocuments.length > 0 ? (
              <div className="grid grid-cols-1 xl:grid-cols-2 justify-center w-[100%] xl:w-[90%] gap-x-6 gap-y-4 my-4 xl:my-8">
                {referenceDocuments?.map((doc, idx) => {
                  return (
                    <div
                      key={idx}
                      style={{
                        border:
                          doc.status === "REJECTED"
                            ? "1px solid #CF363630"
                            : "1px solid #E4E4E4",
                      }}
                      className={`relative grid grid-cols-2 xl:grid-cols-5 gap-2 gap-y-3 items-center ${
                        doc.status === "REJECTED"
                          ? "bg-[#FFD6DB80]"
                          : "bg-[#F9F9F9]"
                      } p-2 rounded-[8px]`}
                    >
                      {currentRole?.role?.roleName
                        ?.toLowerCase()
                        .includes("team") &&
                        doc.status === "MAPPED" && (
                          <div className="absolute flex gap-1 top-1 left-[25%] text-xs font-semibold">
                            {" "}
                            <CheckCircleOutlineOutlinedIcon
                              style={{ color: "#017C57", fontSize: "16px" }}
                            />
                            <div>{doc.mappedWith?.split(",").length}</div>
                            <div className="group relative ">
                              <InfoOutlinedIcon
                                style={{
                                  color: "#017C57",
                                  fontSize: "16px",
                                  cursor: "pointer",
                                }}
                                onClick={() => setUnmapDocId(doc.id)}
                              />
                              <div className="absolute w-[200px] hidden group-hover:block  text-xs   z-20">
                                <div className="w-full flex justify-start pl-2">
                                  <img src={TooltipIcon} alt="toolti-icon" />
                                </div>
                                <div className="text-[12px] text-center text-white bg-[#017C57] py-2 px-1 rounded-[8px]">
                                  {getMappedDocumentName(doc?.mappedDocs)}
                                </div>
                              </div>
                              {UnmapDocId === doc.id && (
                                <UnmapDocument
                                  refDocId={doc.id}
                                  mappedDocs={doc.mappedDocs}
                                  getDocumentsData={getDocumentsData}
                                  isOpen={setUnmapDocId}
                                />
                              )}
                            </div>
                          </div>
                        )}
                      <div className="flex items-center justify-center ">
                        {currentRole?.role?.roleName
                          ?.toLowerCase()
                          .includes("team") &&
                          doc.status !== "REJECTED" && (
                            <div>
                              <input
                                type="checkbox"
                                onClick={() => handleDocumentSelect(doc)}
                                className="h-[16px] w-[16px] mr-2"
                              />
                            </div>
                          )}

                        {doc?.documentUrl?.slice(-3) === "pdf" ? (
                          <img
                            src={PdfIcon}
                            alt="pdf-icon"
                            width={48}
                            height={48}
                          />
                        ) : (
                          <div className="w-12 h-12 xl:w-[80px] xl:h-[80px] flex items-center">
                            <img
                              src={doc.documentUrl}
                              alt={`${doc.description}-image`}
                              height={48}
                              width={48}
                              className="object-cover w-12 h-12 xl:w-[80px] xl:h-[80px]"
                            />
                          </div>
                        )}
                      </div>
                      <div className="font-semibold text-sm text-center">
                        {" "}
                        {doc.description}
                      </div>
                      {currentRole?.role?.roleName
                        ?.toLowerCase()
                        .includes("hospital") && (
                        <div className="col-span-2 flex justify-center">
                          {selectedDoc &&
                          selectedDoc.id === doc.id &&
                          isReuploading ? (
                            <CircularProgress />
                          ) : (
                            <button
                              style={{
                                borderRadius: "32px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                fontFamily: "Inter, sans-serif",
                                fontWeight: "600",
                                fontSize: "14px",
                                backgroundColor: "#017c57",
                                color: "white",
                                border: "none",
                                gap: "8px",
                                cursor: "pointer",
                                paddingBlock: "6px",
                                paddingInline: "16px",
                              }}
                              onClick={() => {
                                setSelectedDoc(doc);
                                handleReUploadButtonClick();
                              }}
                            >
                              <FileUploadOutlinedIcon fontSize="small" />{" "}
                              RE-UPLOAD
                            </button>
                          )}

                          {/* <div
                              style={{ border: "1px solid #017C57" }}
                              className="text-sm text-[#017C57] text-center font-semibold bg-white rounded-full px-4 py-2 cursor-pointer "
                              onClick={() => {
                                if (selectedDoc && selectedDoc.id === doc.id) {
                                  updateDocumentData();
                                }
                              }}
                            >
                              Save
                            </div> */}
                        </div>
                      )}
                      {currentRole?.role?.roleName
                        ?.toLowerCase()
                        .includes("hospital") && (
                        <div
                          className="flex justify-center cursor-pointer"
                          onClick={() => deleteDocument(doc.id)}
                        >
                          <img src={DeleteIcon} alt="delete-icon" width={20} />
                        </div>
                      )}
                      {/* {currentRole?.role?.roleName
                          ?.toLowerCase()
                          .includes("hospital") && (
                          
                        )} */}
                      {currentRole?.role?.roleName
                        ?.toLowerCase()
                        .includes("team") && (
                        <div className="hidden xl:block" />
                      )}
                      {currentRole?.role?.roleName
                        ?.toLowerCase()
                        .includes("team") && (
                        <div className="col-span-2 flex justify-around xl:block">
                          {doc.status !== "REJECTED" ? (
                            <div>
                              <div
                                style={{ border: "1px solid #017c57" }}
                                className="w-full h-fit  text-[10px] xl:text-xs text-[#017C57] text-center bg-white font-semibold  cursor-pointer rounded-full py-1 px-4 xl:py-2"
                                onClick={() => setOpenModalId(idx)}
                              >
                                Map Document
                              </div>
                              <div
                                style={{ border: "1px solid #017c57" }}
                                className="w-full h-fit text-[10px] xl:text-xs text-[#017C57] text-center bg-white font-semibold cursor-pointer rounded-full px-4 py-1  xl:py-2 xl:mt-1"
                                onClick={() => {
                                  setSelectedDoc(doc);
                                  setRejectionPopUp(true);
                                  // handleRejectDocument();
                                }}
                              >
                                Review Document
                              </div>
                            </div>
                          ) : (
                            <div className="text-center text-sm text-[#191919] font-semibold t">
                              {" "}
                              REJECTED
                            </div>
                          )}
                        </div>
                      )}
                      {isRejectionPopUp && (
                        <RejectionPopUp
                          isOpen={setRejectionPopUp}
                          selectedDoc={selectedDoc}
                          getDocumentsData={getDocumentsData}
                        />
                      )}
                      {openModalId === idx && (
                        <MapDocumentToUrl
                          caseId={selectedCase.id}
                          selectedDoc={[doc]}
                          isOpen={setOpenModalId}
                          getDocumentsData={getDocumentsData}
                        />
                      )}
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className="flex justify-center items-center text-xl text-[#191919] font-semibold h-full">
                No Document Uploaded
              </div>
            )}
          </div>

          <div>
            {/* Showing upload progress here */}
            {isDocUploading && (
              <div className="flex items-center gap-1">
                <div
                  style={{ width: `${percentage + 1}%` }}
                  className="h-[15px] bg-blue-500 rounded-full"
                />{" "}
                {percentage}%
              </div>
            )}
            <div className="flex justify-center">
              <div
                style={{ border: "1px solid #E4E4E4" }}
                className="w-[100%] xl:w-[90%]  bg-[#F9F9F9] rounded-[16px] p-4 xl:p-5 "
              >
                <div className="flex  justify-between items-center gap-4 ">
                  <div className="text-[18px] font-semibold text-[#191919]">
                    Upload new document
                  </div>
                  <div>
                    {isDocUploading ? (
                      <div>
                        <CircularProgress />
                      </div>
                    ) : (
                      <div>
                        <input
                          type="file"
                          onChange={(e) => uploadDocument(e)}
                          style={{ display: "none" }}
                          ref={fileInputRef}
                          accept=".pdf,.png,.jpg,.jpeg"
                        />
                        <button
                          style={{
                            height: "34px",
                            width: "192px",
                            borderRadius: "32px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            fontFamily: "Inter, sans-serif",
                            fontWeight: "600",
                            fontSize: "14px",
                            border: "1px solid #017C57",
                            backgroundColor: "white",
                            color: "#017c57",
                            cursor: "pointer",
                            gap: "8px",
                          }}
                          onClick={() => handleButtonClick()}
                        >
                          <FileUploadOutlinedIcon
                            fontSize="small"
                            style={{ color: "#017C57" }}
                          />{" "}
                          UPLOAD
                        </button>
                        <div className="text-xs text-[#797979] text-center mt-1">
                          Files Supported : .pdf, .png. .jpg
                        </div>
                      </div>
                    )}
                  </div>

                  <div className=" w-[100%] xl:w-[328px]">
                    <input
                      type="text"
                      placeholder="Enter doc description"
                      value={description}
                      style={{ border: "1px solid #e4e4e4" }}
                      className="w-full h-[40px] text-[18px] text-[#797979] rounded-[8px]
            pl-[12px] "
                      onChange={(e) => setDescription(e.target.value)}
                    />
                  </div>
                  <div>
                    {isSaving ? (
                      <CircularProgress />
                    ) : (
                      <div
                        style={{ border: "1px solid #017C57" }}
                        className="w-fit flex gap-2 text-sm text-white font-semibold bg-[#017C57] rounded-full px-4 py-2 cursor-pointer"
                        onClick={addDocument}
                      >
                        <img src={AddIcon} alt="add-icon" />
                        ADD DOCUMENT
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div>
              {/* <input
                type="file"
                onChange={(e) => uploadImage(e)}
                style={{ display: "none" }}
                ref={fileInputRef}
              /> */}
              <input
                type="file"
                onChange={(e) => reUploadImage(e)}
                style={{ display: "none" }}
                ref={reUploadFileRef}
                accept="image/*"
              />
            </div>
            <div className="flex justify-center mt-2">
              <div
                style={{ border: "1px solid #D32F2F" }}
                className="flex items-center gap-2 text-sm text-white bg-[#D32F2F] font-semibold uppercase px-8 py-2 rounded-full cursor-pointer"
                title="Change Case Stage to Reference Document Pending"
                onClick={() => setReferencePendingModal(true)}
              >
                <NewReleasesOutlinedIcon /> Reference Documents Pending
              </div>
            </div>
          </div>
          {referencePendingModal && (
            <div className="fixed w-screen h-screen top-0 left-0 bg-[#017C5780] flex justify-center items-center z-[1200]">
              <div className="relative w-[50%] h-[40%] 2xl:h-[30%] bg-[white] rounded-[16px]">
                <div
                  className="absolute top-4 right-4 cursor-pointer"
                  onClick={() => setReferencePendingModal(false)}
                >
                  <img src={CloseIcon} alt="cross-icon" />
                </div>
                <div className="text-xl text-[#191919] text-center font-medium mt-5">
                  Change Case Status
                </div>
                <div className="flex justify-center gap-4 mt-5">
                  <div className="text-[#191919] font-medium">
                    Please Specify Reason *
                  </div>
                  <textarea
                    className="w-[60%] h-[90px] rounded-[8px] p-2"
                    onChange={(e) => setReason(e.target.value)}
                  />
                </div>
                <div className="flex justify-center">
                  {isLoading ? (
                    <CircularProgress />
                  ) : (
                    <div
                      className="text-sm text-white bg-[#017C57] font-semibold rounded-full px-8 py-2 mt-8 cursor-pointer"
                      onClick={handleStatusChange}
                    >
                      Change Status
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PreinitiateModal;
