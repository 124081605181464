import React, { useEffect, useState, useRef } from "react";
import UploadIcon from "../../assets/uploadIcon.svg";
import SearchIcon from "../../assets/searchIcon.svg";
import DeleteIcon from "../../assets/deleteIcon.svg";
import PreinitiateModal from "./preInitiateModal/PreinitiateModal";
import DeactivateOrDeleteCase from "./DeactivateOrDeleteCase";
import CaseStageUpdateConfirmation from "./caseStageUpdateConfirmation";
import { Paper } from "@mui/material";
import Select from "react-select";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import EditIcon from "../../assets/EditCircleIcon.svg";
import TooltipIcon from "../../assets/tooltipTriangle.svg";
import PermPhoneMsgOutlinedIcon from "@mui/icons-material/PermPhoneMsgOutlined";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import CustomPagination from "../../utils/paginationUtils";
import { logOut, logOut401 } from "../../config/user";
import { CaseServices } from "../../services/CaseServices";
import { ReportServices } from "../../services/ReportServices";
import { NotificationServices } from "../../services/NotificationServices";
import { HospitalServices } from "../../services/HospitalServices";
import { InterUserMangementServices } from "../../services/InternalUserMangementServices";
import SequentialCountdownTimer from "./sequentialCountDownTimer/SequentialCountDownTimer";
import IncreamentalTimer from "./increamentalTimer/IncreamentalTimer";
import AssignUserToCase from "./assignUserToCase/AssignUserToCase";
import "./caseList.scss";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import CountdownTimer from "../../utils/countDownTimer";
import CaseRecordings from "./caseRecordings/CaseRecordings";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import { getUser } from "../../config/user";
import {
  updateMember,
  updatePageNumber,
  updateStatus,
  updateSubStatus,
  updateSelectedHospital,
} from "../../redux/caseDetailsSlice";

const CaseListV2 = ({
  caseId,
  selectedStage,
  getStageCaseReport,
  getStageColor,
}) => {
  const [cases, setCases] = useState([1, 2, 3]);
  const [totalPages, setTotalPages] = useState(1);
  const [totalFilteredCases, setTotalFilteredCases] = useState(0);
  const [totalSettlemedCases, setTotalSettlemedCases] = useState(0);
  const [users, setUsers] = useState(null);
  const [stageTimeColor, setStageTimeColor] = useState(null);
  const { pageNo, member, status, subStatus, selectedHospitalId } = useSelector(
    (state) => state.caseDetails
  );
  const [page, setPage] = useState(pageNo ? pageNo : 1);
  const [isLoading, setIsLoading] = useState(true);
  const [caseStatus, setCaseStatus] = useState(status ? status : "");
  const [nextStepUser, setNextStepUser] = useState(member ? member : "");
  const [commandCenterStage, setCommandCenterStage] = useState(
    subStatus ? subStatus : ""
  );
  const [searchKey, setSearchKey] = useState("");
  const [isPreinitiateOpen, setPreinitiateOpen] = useState(false);
  const [hospitalList, setHospitalList] = useState([]);
  const [roleList, setRoleList] = useState([]);
  const [subStatusList, setSubStatusList] = useState([]);
  const [teamUserList, setTeamUserList] = useState([]);
  const [teamUserId, setTeamUserId] = useState("");
  const [hospitalId, setHospitalId] = useState(
    selectedHospitalId ? selectedHospitalId : ""
  );
  const [openConfirmationPopup, setConfirmationPopup] = useState(false);
  const [updateStageMethodName, setUpdateStageMethodName] = useState({
    method: "",
    case: "",
  });
  const [newPmjayId, setNewPmjayId] = useState({
    pmjayId: "",
    caseId: "",
  });
  const [selectedCase, setSelectedCase] = useState(null);
  const [showRecordings, setShowRecordings] = useState(null);
  const [showDeactivateModal, setShowDeactvateModal] = useState(null);
  const [assignUser, setAssignUser] = useState(null);
  const outsideUser = useSelector((state) => state.currentRole.isOutsideUser);
  const storeCaseId = useSelector((state) => state.caseDetails.caseId);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let searchTimeRef = useRef();
  const user = getUser();
  let limit = 10;
  const statusList = [
    { value: "active", label: "Active" },
    { value: "inactive", label: "Inactive" },
  ];
  const getTime = (createdDate) => {
    const currentDate = new Date();
    const diffInMs = currentDate - new Date(createdDate);

    const days = Math.floor(diffInMs / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (diffInMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((diffInMs % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((diffInMs % (1000 * 60)) / 1000);

    return `${days > 0 ? days + "D " : ""}${hours > 0 ? hours + "H " : ""} ${
      minutes > 0 ? minutes + "M" : ""
    } ${!days && !hours && !minutes ? seconds + "S" : ""}`;
  };
  async function AllCases() {
    try {
      setIsLoading(true);
      let res = selectedStage
        ? await CaseServices.allCases(
            searchKey,
            page,
            limit,
            caseStatus,
            caseId,
            hospitalId,
            nextStepUser,
            "",
            teamUserId,
            selectedStage
          )
        : await CaseServices.allCases(
            searchKey,
            page,
            limit,
            caseStatus,
            caseId,
            hospitalId,
            nextStepUser,
            commandCenterStage,
            teamUserId
          );
      setCases(res?.data?.caseList);
      setTotalPages(res?.data?.totalPages);
      setTotalSettlemedCases(res?.data?.totalSettlemedCases);
      setTotalFilteredCases(res?.data?.totalFilteredCases);

      setIsLoading(false);
    } catch (error) {
      if (error?.response?.status === 401) {
        logOut401();
        navigate("/");
      } else {
        window.alert(error);
      }
      setIsLoading(false);
    }
  }
  const getUsersDetails = async () => {
    try {
      const response = await ReportServices.getUsers();
      setUsers(response.data.users);
      console.log("users list:", response.data);
    } catch (error) {
      window.alert(error);
    }
  };
  const handleSearch = (e) => {
    //debouncing structure
    if (searchTimeRef.current) {
      clearInterval(searchTimeRef.current);
    }
    searchTimeRef.current = setTimeout(() => {
      setSearchKey(e.target.value);
    }, 500);
  };
  const handlePmjayIdSave = async () => {
    try {
      const response = CaseServices.updateCase(
        {
          pmjayId: newPmjayId?.pmjayId,
        },
        newPmjayId?.caseId
      );
      console.log("response:", response);
      await AllCases();
    } catch (error) {
      window.alert(error);
    }
  };
  const calculatePrcentage = (uploaded, total) => {
    let percent = 0;
    if (total !== 0) {
      percent = (uploaded / total) * 100;
    }
    return percent;
  };
  const getHospitalList = async () => {
    try {
      const response = await HospitalServices.gethospitals("", 1, 1500);
      setHospitalList(response.data.list);
    } catch (error) {
      if (error?.response?.status === 401) {
        logOut();
      } else {
        window.alert(error);
      }
    }
  };
  const getRoleList = async () => {
    try {
      const response = await NotificationServices.getRoleList();
      setRoleList(response?.data?.allRoles);
    } catch (error) {
      window.alert(error);
    }
  };
  const getSubStatusList = async () => {
    try {
      const response = await NotificationServices.getSubMasterStages(200);
      setSubStatusList(response?.data?.subMasterStages);
    } catch (error) {
      window.alert(error);
    }
  };
  const getTeamUsers = async () => {
    try {
      const response = await InterUserMangementServices.getTeamUser(100, 1);
      setTeamUserList(response.data.teamUsers);
      console.log("internsl user response:", response.data);
    } catch (error) {
      window.alert(error);
    }
  };
  const getDefaultStageTimeColor = async () => {
    try {
      const response = await ReportServices.getDefaultStageTimeColor();
      console.log("time color response:", response.data);
      setStageTimeColor(response.data);
    } catch (error) {
      window.alert(error);
    }
  };

  useEffect(() => {
    AllCases();
  }, [
    page,
    searchKey,
    hospitalId,
    caseStatus,
    nextStepUser,
    commandCenterStage,
    storeCaseId,
    selectedStage,
    teamUserId,
  ]);
  useEffect(() => {
    getHospitalList();
    getRoleList();
    getSubStatusList();
    getDefaultStageTimeColor();
    getUsersDetails();
    getTeamUsers();
  }, []);
  useEffect(() => {
    dispatch(updateMember(nextStepUser));
  }, [nextStepUser]);
  useEffect(() => {
    dispatch(updateStatus(caseStatus));
  }, [caseStatus]);
  useEffect(() => {
    dispatch(updateSubStatus(commandCenterStage));
  }, [commandCenterStage]);
  useEffect(() => {
    dispatch(updateSelectedHospital(hospitalId));
  }, [hospitalId]);
  useEffect(() => {
    dispatch(updatePageNumber(page));
  }, [page]);
  //This useEffect is used to handle when someone selects the cards to see details in that case we need to reset page number to 1
  useEffect(() => {
    setPage(pageNo);
  }, [pageNo]);
  return (
    <div className="case-list-container">
      <div
        style={{ border: "1px solid #D4D4D4" }}
        className=" px-6 pb-3 my-5 rounded-[12px]"
      >
        <div className="flex justify-between items-end">
          <div className="sub-heading">
            Filter by
            <Select
              name="teamMember"
              isClearable
              options={roleList}
              getOptionLabel={(options) => {
                return options["roleName"];
              }}
              getOptionValue={(options) => {
                return options["roleText"];
              }}
              onChange={(item) => {
                setPage(1);
                item ? setNextStepUser(item.roleText) : setNextStepUser("");
              }}
              value={roleList.filter(
                (option) => option.roleText === nextStepUser
              )}
              placeholder="TEAM - MEMBER"
            />
            <Select
              name="caseStatus"
              isClearable
              options={statusList}
              onChange={(item) => {
                setPage(1);
                item ? setCaseStatus(item.value) : setCaseStatus("");
              }}
              value={statusList.filter((option) => option.value === caseStatus)}
              placeholder="FILTER STATUS WISE"
            />
            <Select
              name="subStatus"
              isClearable
              options={subStatusList}
              getOptionLabel={(options) => {
                return options["commandCenterStageStatus"];
              }}
              getOptionValue={(options) => {
                return options["commandCenterStageStatus"];
              }}
              onChange={(item) => {
                setPage(1);
                item
                  ? setCommandCenterStage(item.commandCenterStageStatus)
                  : setCommandCenterStage("");
              }}
              value={subStatusList.filter(
                (item) => item.commandCenterStageStatus === commandCenterStage
              )}
              placeholder="FILTER SUB STATUS WISE"
            />
            <Select
              name="hospitalId"
              isClearable
              options={hospitalList}
              getOptionLabel={(options) => {
                return options["name"];
              }}
              getOptionValue={(options) => {
                return options["id"];
              }}
              onChange={(item) => {
                setPage(1);
                item ? setHospitalId(item.id) : setHospitalId("");
              }}
              value={hospitalList.filter((item) => item.id === hospitalId)}
              placeholder="FILTER HOSPITAL WISE"
            />
            <Select
              name="teamUser"
              isClearable
              options={teamUserList}
              getOptionLabel={(options) => {
                return options["fullName"];
              }}
              getOptionValue={(options) => {
                return options["id"];
              }}
              onChange={(item) => {
                setPage(1);
                item ? setTeamUserId(item.id) : setTeamUserId("");
              }}
              value={teamUserList.filter((item) => item.id === teamUserId)}
              placeholder="FILTER USER WISE"
            />
          </div>
          <div
            style={{ border: "1px solid #DEDEDE" }}
            className="flex items-center gap-2 px-4 py-[6px] ml-4 2xl:ml-0 rounded-[8px] bg-white"
          >
            <img src={SearchIcon} alt="search-icon" />
            <input
              type="text"
              className="outline-none border-0"
              onChange={handleSearch}
              placeholder="Search Anything"
            />
          </div>
        </div>
        <div className="text-[18px] text-[#797979] mt-2">
          Total Cases{" "}
          <span className="font-semibold">
            {totalFilteredCases}
            {" / "}
            {totalSettlemedCases}
          </span>
        </div>
      </div>
      <Paper style={{ backgroundColor: "#f5f5f5" }} className="list-paper">
        {/* <div style={{ border: "1px solid #e5e5e5" }} className="mb-10 mt-6" /> */}

        {isLoading ? (
          <div className="flex items-center justify-center">
            <CircularProgress />
          </div>
        ) : (
          <div>
            <div className="grid grid-cols-9 text-[#191919] font-semibold px-[14px] py-4">
              <div>Beneficiary No.</div>
              <div>Patient Name</div>
              <div>Pmjay Case ID</div>
              <div>HUID</div>
              <div>Hospital Name</div>
              <div>Area</div>
              <div>City</div>
              <div>Gender/Age</div>
              <div>Registered Date</div>
            </div>
            <div className="h-screen overflow-y-scroll">
              {cases && cases.length > 0 ? (
                cases.map((cas, idx) => {
                  return (
                    <div
                      key={idx}
                      style={{
                        border: "1px solid #E5E5E5",
                        marginTop: idx === 0 ? "4px" : "24px",
                      }}
                      className="bg-white px-[14px] py-4 rounded-[8px] mb-3 hovernow"
                    >
                      <div className="grid grid-cols-9 text-sm 2xl:text-base text-[#191919] mb-5">
                        <div className="flex gap-1 items-start">
                          <input
                            type="checkbox"
                            className="h-[16px] w-[16px]"
                          />
                          <div>{cas?.patient?.beneficiaryNo}</div>
                        </div>
                        <div
                          className="overflow-hidden whitespace-nowrap text-ellipsis"
                          title={cas?.patient?.patientName}
                        >
                          {cas?.patient?.patientName}
                        </div>
                        {cas?.pmjayId ? (
                          <div
                            className=" overflow-hidden whitespace-nowrap text-ellipsis"
                            title={cas?.pmjayId}
                            onClick={() =>
                              navigator.clipboard.writeText(cas?.pmjayId)
                            }
                          >
                            <ContentCopyIcon
                              className="cursor-pointer active:bg-slate-400 mr-1"
                              fontSize="12px"
                              onClick={() =>
                                navigator.clipboard.writeText(cas?.pmjayId)
                              }
                            />

                            {cas.pmjayId}
                          </div>
                        ) : (
                          <div className="flex gap-1 items-center justify-center">
                            <input
                              type="text"
                              style={{
                                width: newPmjayId.pmjayId ? "50%" : "80%",
                              }}
                              className="w-[30%] focus:w-[80%] border-none text-center focus:text-start p-1"
                              placeholder="--"
                              onChange={(e) => {
                                if (e.target.value) {
                                  setNewPmjayId({
                                    caseId: cas.id,
                                    pmjayId: e.target.value,
                                  });
                                } else {
                                  setNewPmjayId({
                                    caseId: "",
                                    pmjayId: "",
                                  });
                                }
                              }}
                            />
                            {newPmjayId.caseId === cas.id && (
                              <SaveOutlinedIcon
                                fontSize="12px"
                                className="text-[#017C57] cursor-pointer"
                                onClick={handlePmjayIdSave}
                              />
                            )}
                          </div>
                        )}
                        <div
                          className="overflow-hidden whitespace-nowrap text-ellipsis"
                          title={cas?.hospitalId}
                        >
                          {cas?.hospitalId}
                        </div>
                        <div
                          className="overflow-hidden whitespace-nowrap text-ellipsis pl-1"
                          title={cas?.hospital?.name}
                        >
                          {cas?.hospital?.name}
                        </div>
                        <div>Locality</div>
                        <div>{cas?.hospital?.city}</div>
                        <div>
                          {cas?.patient?.gender?.slice(0, 1)}/
                          {cas?.patient?.age}
                        </div>
                        <div>
                          {cas?.patient?.registeredDate && (
                            <DatePicker
                              selected={new Date(cas?.patient?.registeredDate)}
                              className="text-[#191919] font-medium border-none bg-transparent"
                              timeInputLabel="Time:"
                              dateFormat="dd/MM/yyyy h:mm aa"
                              showTimeInput
                              disabled
                            />
                          )}
                        </div>
                      </div>

                      <div className="flex justify-between items-center">
                        <div className="flex gap-2 items-center ">
                          <div
                            className="text-[10px] 2xl:text-xs font-semibold px-4 py-1 rounded-full"
                            style={
                              cas?.status === "INACTIVE"
                                ? {
                                    backgroundColor: "#FFEAC6",
                                    color: "#9D6B2A ",
                                  }
                                : {
                                    backgroundColor: "#CDFAF4",
                                    color: "#017C57",
                                  }
                            }
                          >
                            {cas?.status}
                          </div>
                          <SequentialCountdownTimer
                            colorData={
                              stageTimeColor?.colorData[
                                stageTimeColor?.subMainStageIdPair[
                                  cas?.submasterstagestatuses[0]?.commandCenterStage?.toLowerCase()
                                ]
                              ]
                            }
                            createdAt={
                              cas?.submasterstagestatuses[0]?.createdAt
                            }
                          />
                          <div
                            className={`${
                              cas?.submasterstagestatuses[0]?.commandCenterStage
                                .toLowerCase()
                                .includes("pending")
                                ? "text-[#9D6B2A] bg-[#FFEAC6]"
                                : cas?.submasterstagestatuses[0]?.commandCenterStage
                                    .toLowerCase()
                                    .includes("rejected")
                                ? "text-[#CF3636] bg-[#FFD6DB]"
                                : "text-[#017C51] bg-[#CDFAF4]"
                            }  text-[10px] 2xl:text-xs font-semibold uppercase rounded-full px-4 py-1`}
                          >
                            {cas?.submasterstagestatuses &&
                              cas?.submasterstagestatuses[0] &&
                              cas?.submasterstagestatuses[0]
                                ?.commandCenterStage}
                          </div>
                          {/* <div className="ml-2">
                            {new Date(cas.deadline) == "Invalid Date" ? (
                              <div className="right-container"></div>
                            ) : (
                              <div className="flex text-sm text-[#797979] gap-1">
                                Time :
                                <div className="font-semibold text-[#191919]">
                                  <CountdownTimer deadline={cas.deadline} />
                                </div>
                              </div>
                            )}
                          </div> */}
                          {/* <div className="h-1 w-1 bg-[#191919] rounded-full mx-2" /> */}
                          {cas?.submasterstagestatuses[0]?.assignedTo ? (
                            <div className="text-[#191919] text-sm font-semibold">
                              {users &&
                                users[cas.submasterstagestatuses[0].assignedTo]}
                            </div>
                          ) : (
                            <div
                              style={{
                                border: "1px solid #017C57",
                              }}
                              className="text-xs text-[#017C57] font-semibold px-3 py-1 rounded-full cursor-pointer"
                              title="Assign Case Stage to User"
                              onClick={() =>
                                setAssignUser({
                                  caseId: cas?.id,
                                  commandCenterStage:
                                    cas?.submasterstagestatuses[0]
                                      ?.commandCenterStage,
                                  commandCenterStageId:
                                    cas?.submasterstagestatuses[0]?.id,
                                })
                              }
                            >
                              Assign
                            </div>
                          )}
                          <div className="text-[#797979] text-sm mx-2">
                            NSU :{" "}
                            <span className="text-xs 2xl:text-sm text-[#191919] font-semibold">
                              {cas?.submasterstagestatuses &&
                              cas?.submasterstagestatuses[0]?.nextStepUser
                                ? cas?.submasterstagestatuses[0].nextStepUser
                                : "---"}
                            </span>
                          </div>
                          <div
                            className="text-[#797979] text-sm flex gap-1 items-center"
                            title="Current Stage Time"
                          >
                            <span>
                              <IncreamentalTimer
                                colorData={
                                  stageTimeColor?.colorData[
                                    stageTimeColor?.subMainStageIdPair[
                                      cas?.submasterstagestatuses[0]?.commandCenterStage?.toLowerCase()
                                    ]
                                  ]
                                }
                                createdAt={
                                  cas?.submasterstagestatuses[0]?.createdAt
                                }
                              />
                            </span>
                          </div>
                        </div>

                        <div className="flex gap-3 items-center">
                          {cas?.submasterstagestatuses[0]?.commandCenterStage
                            ?.toLowerCase()
                            .includes("approved by team c") ||
                          cas?.submasterstagestatuses[0]?.commandCenterStage
                            ?.toLowerCase()
                            .includes("approved by team e") ? (
                            <div
                              style={{ border: "1px solid #017C57" }}
                              className="text-xs bg-white text-[#017C57] font-semibold px-3 py-1 rounded-full cursor-pointer"
                              onClick={() => {
                                setConfirmationPopup(true);
                                setUpdateStageMethodName({
                                  method: "handleInitiateOnTMS",
                                  case: cas,
                                });
                              }}
                            >
                              {cas?.submasterstagestatuses[0]?.commandCenterStage
                                ?.toLowerCase()
                                .includes("admission")
                                ? "Initiate Admission on TMS"
                                : cas?.submasterstagestatuses[0]?.commandCenterStage
                                    ?.toLowerCase()
                                    .includes("enhancement")
                                ? "Initiate Enhancement on TMS"
                                : cas?.submasterstagestatuses[0]?.commandCenterStage
                                    ?.toLowerCase()
                                    .includes("claim")
                                ? "Initiate Claim on TMS"
                                : ""}
                            </div>
                          ) : cas?.submasterstagestatuses[0]?.commandCenterStage
                              ?.toLowerCase()
                              .includes("request to admission") ? (
                            <div
                              style={{ border: "1px solid #017C57" }}
                              className="text-xs bg-white text-[#017C57] font-semibold px-3 py-1 rounded-full cursor-pointer"
                              onClick={() => {
                                if (cas?.totalReferenceDocuments) {
                                  setConfirmationPopup(true);
                                  setUpdateStageMethodName({
                                    method: "handleInitiateAdmission",
                                    case: cas,
                                  });
                                } else {
                                  window.alert(
                                    "Please upload atleast one reference document before initiating the admission"
                                  );
                                  setSelectedCase(cas);
                                  setPreinitiateOpen(true);
                                }
                              }}
                            >
                              Initiate Admission
                            </div>
                          ) : cas?.submasterstagestatuses[0]?.commandCenterStage
                              ?.toLowerCase()
                              .includes("admission initiated on tms") ||
                            cas?.submasterstagestatuses[0]?.commandCenterStage
                              ?.toLowerCase()
                              .includes("enhancement initiated on tms") ||
                            cas?.submasterstagestatuses[0]?.commandCenterStage
                              ?.toLowerCase()
                              .includes("claim initiated on tms") ? (
                            <div
                              style={{ border: "1px solid #017C57" }}
                              className="text-xs bg-white text-[#017C57] font-semibold px-3 py-1 rounded-full cursor-pointer"
                              onClick={() => {
                                setConfirmationPopup(true);
                                setUpdateStageMethodName({
                                  method: "handleApprovedOnTMS",
                                  case: cas,
                                });
                              }}
                            >
                              {cas?.submasterstagestatuses[0]?.commandCenterStage
                                ?.toLowerCase()
                                .includes("admission")
                                ? "Admission Approved on TMS"
                                : cas?.submasterstagestatuses[0]?.commandCenterStage
                                    ?.toLowerCase()
                                    .includes("enhancement")
                                ? "Enhancement Approved on TMS"
                                : cas?.submasterstagestatuses[0]?.commandCenterStage
                                    ?.toLowerCase()
                                    .includes("claim")
                                ? "Claim Approved on TMS"
                                : ""}
                            </div>
                          ) : cas?.submasterstagestatuses[0]?.commandCenterStage
                              ?.toLowerCase()
                              .includes("claim approved on tms") ? (
                            <div
                              style={{ border: "1px solid #017C57" }}
                              className="text-xs bg-white text-[#017C57] font-semibold px-3 py-1 rounded-full cursor-pointer"
                              onClick={() => {
                                setConfirmationPopup(true);
                                setUpdateStageMethodName({
                                  method: "handleClaimPaidByBank",
                                  case: cas,
                                });
                              }}
                            >
                              {" "}
                              Claim Amount Paid
                            </div>
                          ) : (
                            <div style={{ width: "46px" }}>
                              <CircularProgressbarWithChildren
                                value={calculatePrcentage(
                                  cas?.totalDocuments -
                                    cas?.totalDuringTreatmentDocuments -
                                    cas?.admissionDocumentsPending -
                                    cas?.admissionDocumentsRejected -
                                    cas?.latestEnhancementPending -
                                    cas?.latestEnhancementRejected -
                                    cas?.onDischargePending -
                                    cas?.onDischargeRejected,
                                  cas?.totalDocuments -
                                    cas?.totalDuringTreatmentDocuments
                                )} // value in percentage
                                strokeWidth={13}
                                styles={{
                                  path: {
                                    stroke: "#017C57",
                                    strokeLinecap: "round",
                                    transformOrigin: "center center",
                                  },
                                  trail: {
                                    stroke: "#CDFAF4",
                                    transformOrigin: "center center",
                                  },
                                }}
                              >
                                {" "}
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: "100%",
                                    fontSize: "10px",
                                    color: "#017C57",
                                    fontFamily: "inter, sans-serif",
                                    fontWeight: 500,
                                    // paddingBottom: "14px",
                                  }}
                                >
                                  {cas?.totalDocuments -
                                    cas?.totalDuringTreatmentDocuments -
                                    cas?.admissionDocumentsPending -
                                    cas?.admissionDocumentsRejected -
                                    cas?.latestEnhancementPending -
                                    cas?.latestEnhancementRejected -
                                    cas?.onDischargePending -
                                    cas?.onDischargeRejected}
                                  /
                                  {cas?.totalDocuments -
                                    cas?.totalDuringTreatmentDocuments}
                                </div>
                              </CircularProgressbarWithChildren>
                            </div>
                          )}
                          <div
                            style={{ border: "1px solid #017C57" }}
                            className="group relative flex  gap-2 px-2 py-2 rounded-full"
                          >
                            <div className=" h-[14px] w-[14px] text-[10px] text-center text-[#FF1818] bg-[#FFC8C8] rounded-full">
                              {cas?.referenceDocumentsRejected}
                            </div>
                            <div className=" h-[14px] w-[14px] text-[10px] text-center  text-[#017C57] bg-[#CDFAF4] rounded-full">
                              {cas?.totalReferenceDocuments}
                            </div>
                            <img
                              src={UploadIcon}
                              alt="upload-icon"
                              className="w-[12px] cursor-pointer"
                              onClick={() => {
                                setPreinitiateOpen(true);
                                setSelectedCase(cas);
                              }}
                            />
                            <div className="w-[200%] hidden group-hover:block absolute text-xs top-[105%] -left-[50%] z-20">
                              <div className="w-full flex justify-center">
                                <img src={TooltipIcon} alt="toolti-icon" />
                              </div>
                              <div className="text-[12px] text-center text-white bg-[#017C57] py-2 px-1 rounded-[8px]">
                                Reference Documents
                              </div>
                            </div>
                          </div>
                          <div
                            className="cursor-pointer"
                            onClick={() => setShowRecordings(cas?.id)}
                          >
                            <PermPhoneMsgOutlinedIcon
                              style={{ color: "#017C57" }}
                            />
                          </div>
                          {showRecordings === cas?.id && (
                            <CaseRecordings
                              caseId={showRecordings}
                              isOpen={setShowRecordings}
                            />
                          )}
                          <div className="group relative">
                            <img
                              src={EditIcon}
                              alt="edit-icon"
                              className="cursor-pointer"
                              onClick={() => {
                                outsideUser
                                  ? navigate("/Dashboard/EditCases/v2", {
                                      state: {
                                        selectedCase: cas,
                                        subStatusList: subStatusList,
                                      },
                                    })
                                  : navigate("/Dashboard/EditCases", {
                                      state: {
                                        selectedCase: cas,
                                        subStatusList: subStatusList,
                                      },
                                    });
                              }}
                            />
                            <div className="w-[300%] hidden group-hover:block absolute text-xs top-[100%] -left-[90%] z-20">
                              <div className="w-full flex justify-center">
                                <img src={TooltipIcon} alt="tooltip-icon" />
                              </div>
                              <div className="text-[12px] text-center text-white bg-[#017C57] py-2 px-1 rounded-[8px]">
                                Edit Case
                              </div>
                            </div>
                          </div>
                          {user?.user?.id ===
                            "f72e5386-1bcd-4b81-91fc-412c68d0d9e2" && (
                            <div>
                              <img
                                src={DeleteIcon}
                                alt="delete-icon"
                                className="cursor-pointer"
                                onClick={() => {
                                  setShowDeactvateModal(true);
                                  setSelectedCase(cas);
                                }}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="text-xl text-center font-semibold mt-8">
                  {" "}
                  No Records Found
                </div>
              )}
            </div>
          </div>
        )}
      </Paper>
      {isPreinitiateOpen && (
        <div>
          <PreinitiateModal
            selectedCase={selectedCase}
            isOpen={setPreinitiateOpen}
            subStatusList={subStatusList}
            AllCases={AllCases}
          />
        </div>
      )}
      {showDeactivateModal && (
        <div>
          <DeactivateOrDeleteCase
            selectedCase={selectedCase}
            setOpen={setShowDeactvateModal}
          />
        </div>
      )}
      {openConfirmationPopup && (
        <CaseStageUpdateConfirmation
          setConfirmationPopup={setConfirmationPopup}
          updateStageMethodName={updateStageMethodName}
          AllCases={AllCases}
          getStageCaseReport={getStageCaseReport}
        />
      )}
      {assignUser && (
        <AssignUserToCase
          commandCenterStage={assignUser.commandCenterStage}
          commandCenterStageId={assignUser.commandCenterStageId}
          isOpen={setAssignUser}
          AllCases={AllCases}
        />
      )}
      <div className="list-pagination">
        <CustomPagination
          page={page}
          setPage={setPage}
          totalPages={totalPages}
        />
      </div>
    </div>
  );
};

export default CaseListV2;
