import { useState, useEffect, useRef } from "react";
import WidgetsOutlinedIcon from "@mui/icons-material/WidgetsOutlined";
import SearchIcon from "@mui/icons-material/Search";
import ViewMore from "../assets/viewMore.svg";
import EditIcon from "../assets/editIcon.svg";
import ChevronDown from "../assets/chevron-down.svg";
import AddMoreIcon from "../assets/addMoreIcon.svg";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Doughnut } from "react-chartjs-2";
import CustomPagination from "../utils/paginationUtils";
import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { HospitalServices } from "../services/HospitalServices";
import { logOut } from "../config/user";
import CloseIcon from "../assets/crossIcon.svg";
import SubscriptionCard from "../addhospital/SubscriptionCard";
import {
  mapSubscriptionPlan,
  offlinePaySubscriptionPlan,
} from "../services/SubscriptionServices";
import { CaseServices } from "../services/CaseServices";
import RazorpayPayment from "../payment/RazorpayPayment";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";

Chart.register(CategoryScale, ChartDataLabels);
const Hospitallist = () => {
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchKey, setSearchKey] = useState("");
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [hospitalList, setHospitalList] = useState([]);
  const [hospitalId, setHospitalId] = useState(null);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [subscriptionPlans, setSubscriptionPlans] = useState([]);
  const [selectedDuration, setSelectedDuration] = useState("quarterly");
  const [filteredPlan, setFilteredPlan] = useState([]);
  const [selectedHospital, setSelectedHospital] = useState({});
  const [subscriptionIds, setSubscriptionIds] = useState([]);
  const [orderId, setOrderId] = useState(null);
  const [amountToPay, setAmountToPay] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [paymentDetails, setPaymentDetails] = useState({
    name: "",
    phone: "",
    email: "",
  });
  const [payment, setPayment] = useState({
    subtotal: "",
    discount: "",
    total: "",
  });
  let searchTimeRef = useRef();
  const limit = 10;
  const handleSearch = (e) => {
    //debouncing structure
    if (searchTimeRef.current) {
      clearInterval(searchTimeRef.current);
    }
    searchTimeRef.current = setTimeout(() => {
      setSearchKey(e.target.value);
    }, 500);
  };
  const navigate = useNavigate();
  const chartData = {
    labels: ["Total Cases", "Total Pending cases"],
    datasets: [
      {
        label: "Users Gained ",
        data: [10, 20],
        backgroundColor: ["#cdfaf4", "#FFF0B4"],
        borderColor: "white",
        borderWidth: 1,
        borderRadius: 80,
      },
    ],
  };

  const getDate = (date) => {
    const day =
      new Date(date).getDate() > 9
        ? new Date(date).getDate()
        : `0${new Date(date).getDate()}`;
    const month =
      new Date(date).getMonth() + 1 > 9
        ? new Date(date).getMonth() + 1
        : `0${new Date(date).getMonth() + 1}`;
    const year = new Date(date).getFullYear();
    return `${day}-${month}-${year}`;
  };
  const handleEdit = (hospitalId) => {
    setIsEditOpen(true);
    setHospitalId(hospitalId);
  };

  const handleSubscriptionMapping = async () => {
    setIsLoading(true);
    const data = {
      subscriptionIds: subscriptionIds,
      payment: payment,
      global: {
        couponCode: "",
        hospitalId: selectedHospital.id,
      },
    };
    try {
      const response = await offlinePaySubscriptionPlan(data);
      // setOrderId(response.data.id);
      // setAmountToPay(response.data.amount / 100);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      window.alert(error);
    }
  };
  const getHospiptalList = async () => {
    setIsLoading(true);
    try {
      const response = await HospitalServices.getHospitalSubscriptionList(
        searchKey,
        page,
        limit
      );
      setHospitalList(response.data.list);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      if (error?.response?.status === 401) {
        logOut();
      } else {
        window.alert(error);
      }
    }
    setIsLoading(false);
  };
  const handlePaymentDetails = (name, value) => {
    setPaymentDetails((prev) => ({ ...prev, [name]: value }));
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response1 = await CaseServices.subscriptionall();
        setSubscriptionPlans(response1.data);
      } catch (error) {
        if (error?.response?.status === 401) {
          logOut();
        } else {
          window.alert(error);
        }
      }
    };

    fetchData();
  }, []);
  useEffect(() => {
    getHospiptalList();
  }, [page, searchKey]);
  useEffect(() => {
    const filteredData = subscriptionPlans?.filter(
      (plan) => plan.planType.toLowerCase() === selectedDuration
    );
    setFilteredPlan(filteredData);
  }, [subscriptionPlans, selectedDuration]);
  useEffect(() => {
    if (selectedPlan) {
      setSubscriptionIds([selectedPlan.id]);
      setPayment({
        ...payment,
        subtotal: selectedPlan.totalWithGst,
        total: selectedPlan.totalWithGst,
      });
    }
  }, [selectedPlan]);
  return (
    <div
      style={{
        fontFamily: "Inter",
        padding: 32,
        marginTop: 93,
        width: "100%",
      }}
    >
      <span className="path_line">
        <WidgetsOutlinedIcon style={{ height: "24px" }} /> &nbsp; &nbsp;/ &nbsp;
        &nbsp; Dashboard &nbsp; &nbsp;/ &nbsp; &nbsp; Hospital List
      </span>

      <div className="flex justify-between items-center">
        <div className="heading">Hospital List</div>
        <div className="flex justify-center items-center gap-4 font-semibold text-sm">
          <div
            className="rounded-full text-[#017C57] w-fit px-4 py-2 cursor-pointer"
            style={{ border: "1px solid #017C57" }}
          >
            {" "}
            FREE
          </div>
          <div
            className="rounded-full text-[#017C57] w-fit px-4 py-2 cursor-pointer"
            style={{ border: "1px solid #017C57" }}
          >
            {" "}
            SUBSCRIBED
          </div>
          <div
            className="flex gap-2 items-center rounded-full text-white bg-[#017C57] w-fit px-4 py-2 cursor-pointer"
            style={{ border: "1px solid #017C57" }}
            onClick={() => navigate("/dashboard/addhospital")}
          >
            {" "}
            <img src={AddMoreIcon} alt="add-more-icon" />
            ADD HOSPITAL
          </div>
        </div>
      </div>

      <hr
        style={{
          color: "#E7E7E7",
        }}
      />
      <div
        style={{ border: "1px solid #E4E4E4" }}
        className="rounded-[16px] p-6"
      >
        <div className="flex justify-between font-semibold pt-2">
          <div className="text-xl text-[#191919]">Subscription Status</div>
          <div
            style={{ border: "1px solid #017C57" }}
            className="w-fit text-sm rounded-full text-[#017C57] px-4 py-2 "
          >
            THIS MONTH
          </div>
        </div>
        <hr
          style={{
            color: "#E7E7E7",
            margin: "24px 0",
          }}
        />
        <div className="flex justify-between">
          <div className="space-y-4">
            <div className="flex items-center gap-4 text-[#797979]">
              <div className="w-[16px] h-[16px] bg-[#CDFAF4]" />
              <div>Subscribed</div>
              <div className="h-[4px] w-[4px] rounded-full bg-[#191919]" />
              <div>10</div>
            </div>
            <div className="flex items-center gap-4 text-[#797979]">
              <div className="w-[16px] h-[16px] bg-[#FFF0B4]" />
              <div>Free Hospitals</div>
              <div className="h-[4px] w-[4px] rounded-full bg-[#191919]" />
              <div>20</div>
            </div>
          </div>
          <div className="h-[170px] w-[170px]">
            {" "}
            <Doughnut
              data={chartData}
              options={{
                cutout: "75%",
                plugins: {
                  legend: {
                    display: false,
                    position: "left",
                    align: "start",
                  },
                },
              }}
            />
          </div>
        </div>
        <div className="flex justify-between mt-10 text-[#191919] font-semibold">
          Hospitals
          <div
            style={{ border: "1px solid #F5F5F5" }}
            className="flex gap-2 px-4 py-1 rounded-[8px]"
          >
            <SearchIcon />
            <input
              type="text"
              className="outline-none border-none"
              placeholder="Search"
              onChange={handleSearch}
            />
          </div>
        </div>
        <hr
          style={{
            color: "#E7E7E7",
            margin: "24px 0 16px 0",
          }}
        />
        {/* hospital List start from here */}
        {isLoading ? (
          <div className="flex justify-center">
            <CircularProgress />
          </div>
        ) : (
          <div className="bg-white">
            {hospitalList.length > 0 &&
              hospitalList?.map((hospital, idx) => {
                return (
                  <Accordion
                    key={idx}
                    style={{
                      marginTop: "12px",
                      borderRadius: "8px",
                      border: "0px solid #e4e4e4",
                      backgroundColor:
                        selectedHospital.id === hospital.id
                          ? "#f9f9f9"
                          : "white",
                    }}
                    expanded={hospital.id === selectedHospital.id}
                  >
                    <AccordionSummary
                      // expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                      {" "}
                      <div className="flex justify-between items-center text-[#191919] font-semibold text-sm w-full">
                        <div className="flex items-center gap-4">
                          <div>{hospital.name}</div>
                          <div className="w-[4px] h-[4px] rounded-full bg-[#191919]" />
                          <div className="flex gap-2 items-center">
                            <div className="text-xs">HUID</div>
                            <div className="text-[#797979] font-normal">
                              {hospital.code}
                            </div>
                          </div>
                          <div className="w-[4px] h-[4px] rounded-full bg-[#191919]" />
                          {hospital.subscriptionmappings.length > 0 ? (
                            <div className="text-xs text-[#017C57] bg-[#CDFAF4] rounded-full px-4 py-1 ">
                              {" "}
                              SUBSCRIBED
                            </div>
                          ) : (
                            <div className="text-xs text-[#BCA03C] bg-[#FFF3C6] rounded-full px-4 py-1 ">
                              {" "}
                              FREE
                            </div>
                          )}
                        </div>
                        <div className="flex gap-8 items-center">
                          <div className="text-[#797979] font-normal">
                            {hospital.subscriptionmappings.length > 0 &&
                              hospital.subscriptionmappings[0].subscriptionplan
                                .planType}
                          </div>
                          <div>
                            {hospital.subscriptionmappings.length > 0 &&
                              hospital.subscriptionmappings[0].subscriptionplan
                                .description}
                          </div>
                          <div className="flex items-center gap-4 cursor-pointer">
                            <img
                              src={EditIcon}
                              alt="edit-icon"
                              onClick={() => {
                                setSelectedHospital(hospital);
                                handleEdit(hospital.id);
                              }}
                            />
                            <img
                              src={ChevronDown}
                              alt="down-icon"
                              className={`${
                                selectedHospital.id === hospital.id
                                  ? "-rotate-90"
                                  : ""
                              }`}
                              onClick={() => {
                                if (selectedHospital.id === hospital.id) {
                                  setSelectedHospital({});
                                } else {
                                  setSelectedHospital(hospital);
                                }
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="text-[#797979] text-sm font-normal">
                        {/* <div>Apollo Hospitals Rd, Block E, Sector 26,</div> */}
                        <div>{`${hospital.city}, ${hospital.state} ${hospital.pincode}`}</div>
                      </div>
                      <hr
                        style={{
                          color: "#E7E7E7",
                          margin: "16px 0",
                        }}
                      />
                      <div className="grid grid-cols-4 text-[#191919] text-xs font-semibold py-[10px] pl-2">
                        <div>Date of Registration</div>
                        <div>Date of Subscription</div>
                        <div />
                        <div className="flex justify-end">Expiration</div>
                      </div>
                      <div className="grid grid-cols-4 bg-white text-[#797979] text-xs font-normal  rounded-[8px] pl-4 py-2">
                        <div>{getDate(hospital.createdAt)}</div>
                        <div>
                          {hospital.subscriptionmappings.length > 0
                            ? getDate(
                                hospital.subscriptionmappings[0].createdAt
                              )
                            : "--"}
                        </div>
                        <div />
                        <div className="flex justify-end">
                          {" "}
                          {hospital.subscriptionmappings.length > 0
                            ? `${hospital.subscriptionmappings[0].numberOfDays} days`
                            : "--"}
                        </div>
                      </div>
                      {hospital.subscriptionmappings.length > 0 && (
                        <div className="grid grid-cols-4 items-center font-semibold text-xs text-[#191919] pl-2 py-[10px] mt-2 pr-10">
                          <div>Subscription History</div>
                          <div>Amount (INR)</div>
                          <div className="flex justify-end">Period</div>
                          <div className="flex justify-end">Plan</div>
                        </div>
                      )}
                      {hospital?.subscriptionmappings?.map(
                        (subscription, idx) => {
                          return (
                            <div
                              key={idx}
                              className="grid grid-cols-4 items-center bg-white font-normal text-sm text-[#797979]  rounded-[8px] pl-4 py-2 mt-2 pr-10"
                            >
                              <div>{getDate(subscription.createdAt)}</div>
                              <div>
                                {subscription.subscriptionplan.totalWithGst}
                              </div>
                              <div className="flex justify-end">
                                {subscription.subscriptionplan.planType}
                              </div>
                              <div className="font-semibold text-[#191919] flex justify-end">
                                {subscription.subscriptionplan.name}
                              </div>
                            </div>
                          );
                        }
                      )}

                      <div className="flex justify-center items-center mt-2">
                        <div className="w-fit text-xs text-[#017C57] font-semibold">
                          VIEW MORE
                        </div>

                        <img src={ViewMore} alt="view-more-icon" />
                      </div>
                    </AccordionDetails>
                  </Accordion>
                );
              })}
          </div>
        )}
        <div className="flex justify-center mt-5 ">
          <CustomPagination
            page={page}
            setPage={setPage}
            totalPages={totalPages}
          />
        </div>
      </div>

      {isEditOpen && (
        <div className="fixed top-[5%] left-0 w-screen h-screen flex justify-center items-center bg-[#017C5780] z-10">
          <div className="relative w-[85%] h-[80%]  bg-white rounded-[16px] overflow-y-scroll p-4">
            <div
              className="absolute top-[24px] right-[24px] cursor-pointer"
              onClick={() => {
                setIsEditOpen(false);
                setHospitalId(null);
                setSelectedPlan(null);
                setOrderId(null);
                setPaymentDetails({
                  name: "",
                  phone: "",
                  email: "",
                });
              }}
            >
              <img src={CloseIcon} alt="cross-icon" />
            </div>
            <div
              style={{
                border: "1px solid #E4E4E4",
              }}
              className="bg-white rounded-[16px] p-4"
            >
              <div className="flex justify-between">
                <div className="text-[#191919] font-semibold">
                  Upgrade your plan
                </div>
              </div>
              <div className="flex justify-center">
                <div
                  className="grid grid-cols-2 w-[220px]  text-sm font-semibold text-[#797979] rounded-full relative overflow-hidden  cursor-pointer"
                  style={{
                    border: "1px solid #E4E4E4",
                  }}
                >
                  <div
                    className={`
                 ${selectedDuration === "quarterly" ? "text-white" : ""} 
              rounded-full py-2 px-4 transition-all  duration-700 ease-in-out bg-transparent z-10 text-center `}
                    onClick={() => setSelectedDuration("quarterly")}
                  >
                    QUARTERLY
                  </div>
                  <div
                    className={`${
                      selectedDuration === "yearly" ? "text-white" : ""
                    }  rounded-full py-2 px-4 transition-all   duration-700 ease-in-out bg-transparent z-10 text-center `}
                    onClick={() => setSelectedDuration("yearly")}
                  >
                    YEARLY
                  </div>
                  <div
                    className={`absolute top-0 -left-[50%] h-full w-full bg-[#017C57] rounded-full ${
                      selectedDuration === "yearly"
                        ? "translate-x-[100%]"
                        : "translate-x-[0%]"
                    } transition-all duration-700`}
                  ></div>
                </div>
              </div>
              <div className="p-4">
                <section className=" mt-8">
                  <div className="flex justify-between flex-wrap">
                    {filteredPlan?.map((plan, idx) => {
                      return (
                        <div
                          key={idx}
                          className="cursor-pointer"
                          onClick={() => setSelectedPlan(plan)}
                        >
                          <SubscriptionCard
                            subscriptionPrice={plan.monthlyFees}
                            subscriptionType={plan.name}
                            planFeatures={plan.features}
                            isSelected={selectedPlan?.id === plan?.id}
                          />
                        </div>
                      );
                    })}
                  </div>
                </section>
              </div>
              <div className="flex justify-center">
                {isLoading ? (
                  <CircularProgress />
                ) : (
                  <button
                    style={{
                      width: "125px",
                      height: "34px",
                      borderRadius: "32px",
                      padding: "8px, 16px, 8px, 16px",
                      gap: "8px",
                      backgroundColor: "rgba(1, 124, 87, 1)",
                      color: "white",
                      border: "none",
                      cursor: "pointer",
                    }}
                    onClick={handleSubscriptionMapping}
                  >
                    SAVE DETAILS
                  </button>
                )}
              </div>
            </div>

            {/* <div>
              {orderId && (
                <div>
                  <div className="flex flex-wrap gap-y-4 mt-4">
                    <label htmlFor="amountToPay" className="w-[33%]">
                      <div className="font-semibold mb-2">Amount</div>
                      <input
                        name="amountToPay"
                        type="text"
                        value={`₹${amountToPay}`}
                        disabled
                        style={{ border: "1px solid #e4e4e4" }}
                        className="h-[40px] text-[18px] text-[#797979] rounded-[8px]
            pl-[12px] w-[90%]"
                      />
                    </label>
                    <label htmlFor="name" className="w-[33%]">
                      <div className="font-semibold mb-2">Enter Name</div>
                      <input
                        name="name"
                        type="text"
                        style={{ border: "1px solid #e4e4e4" }}
                        className="h-[40px] text-[18px] text-[#797979] rounded-[8px]
            pl-[12px] w-[90%]"
                        onChange={(e) =>
                          handlePaymentDetails("name", e.target.value)
                        }
                      />
                    </label>
                    <label htmlFor="phone" className="w-[33%]">
                      <div className="font-semibold mb-2">
                        Enter Phone Number
                      </div>
                      <input
                        name="phone"
                        type="text"
                        style={{ border: "1px solid #e4e4e4" }}
                        className="h-[40px] text-[18px] text-[#797979] rounded-[8px]
            pl-[12px] w-[90%]"
                        onChange={(e) =>
                          handlePaymentDetails("phone", e.target.value)
                        }
                      />
                    </label>
                    <label htmlFor="email" className="w-[33%]">
                      <div className="font-semibold mb-2">Enter Email</div>
                      <input
                        name="email"
                        type="email"
                        style={{ border: "1px solid #e4e4e4" }}
                        className="h-[40px] text-[18px] text-[#797979] rounded-[8px]
            pl-[12px] w-[90%]"
                        onChange={(e) =>
                          handlePaymentDetails("email", e.target.value)
                        }
                      />
                    </label>
                  </div>
                  {paymentDetails.name &&
                    paymentDetails.phone &&
                    paymentDetails.email && (
                      <div className="flex justify-center mt-4">
                        <RazorpayPayment
                          amount={amountToPay}
                          orderId={orderId}
                          paymentDetails={paymentDetails}
                        />
                      </div>
                    )}
                </div>
              )}
            </div> */}
          </div>
        </div>
      )}
    </div>
  );
};

export default Hospitallist;
