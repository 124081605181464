import axios from "axios";
import { getAuthorizationToken } from "../config/user";

const REACT_APP_API_URL = process.env.REACT_APP_API_URL;

function getCaseQueries(caseId) {
  return axios({
    method: "get",
    url: `${REACT_APP_API_URL}/all-queries`,
    headers: getAuthorizationToken(),
  });
}

export const CaseQueriesServices = {
  getCaseQueries,
};
