import React, { useState } from "react";
import "./loginpage.scss";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import { AuthServices } from "../services/AuthServices";
import ReCAPTCHA from "react-google-recaptcha";
import { useDispatch } from "react-redux";
import { selectRole } from "../redux/roleSlice";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { CircularProgress } from "@mui/material";
import { toast } from "react-toastify";
import * as yup from "yup";

export const Loginpage = ({ setSignup }) => {
  const navigate = useNavigate();
  const [verified, setVerified] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dispatch = useDispatch();

  const signUp = () => {
    setSignup(true);
  };

  const forgotPassword = () => {
    navigate("/forgotpassword");
  };

  const onCaptchaChange = (value) => {
    setVerified(true);
  };

  const initialValues = {
    email: "",
    password: "",
    keepSignedIn: false,
  };

  const errorValidation = yup.object({
    email: yup.string().email().required("Required Field !"),
    password: yup.string().required("Password is required !"),
  });

  const handleSubmit = async (values) => {
    try {
      setIsSubmitting(true);
      let response = await AuthServices.login(values);
      if (response.data) {
        if (response.data.isDeactivated) {
          // Assuming the API returns `isDeactivated` if the user is inactive
          toast.error("This sub user is inactivated, please contact admin");
        } else {
          dispatch(selectRole(response?.data?.roles[0]));
          let userDetails = JSON.stringify(response?.data);
          localStorage.setItem("userDetails", userDetails);
          toast.success("Login Successful");
          setTimeout(() => {
            navigate("/dashboard/ActiveCases");
          }, 1500);
        }
      }
    } catch (error) {
      window.alert("Email or Password is incorrect"); // Show alert on error
    }
    setIsSubmitting(false);
  };

  return (
    <div className="main1">
      <Formik
        initialValues={initialValues}
        validationSchema={errorValidation}
        onSubmit={handleSubmit}
      >
        {({ errors, touched, values, setFieldValue }) => (
          <Form className="form-container">
            <h2 style={{ marginBottom: "5px" }}>Let's get you started!</h2>
            <div style={{ marginTop: "-10px", paddingTop: "10px" }}>
              <span style={{ color: "#797979" }}>Welcome to Settlemed</span>
            </div>
            <hr
              style={{
                width: "100%",
                height: "1px",
                color: "rgba(228, 228, 228, 1)",
                marginTop: "25px",
              }}
            />

            <div>
              <div className="label" style={{ marginBottom: "15px" }}>
                <span>Email Id</span>
                <Field
                  name="email"
                  className="input"
                  placeholder="Enter your email"
                  // style={{
                  //   width: "458px",
                  //   height: "40px",
                  //   borderRadius: "8px",
                  //   border: "1px solid rgba(228, 228, 228, 1)",
                  //   padding: "12px",
                  //   marginTop: "8px",
                  //   marginBottom: "8px",
                  // }}
                />
                {touched.email && errors.email && (
                  <span className="error">{errors.email}</span>
                )}
              </div>
              <div className="label">
                <span>Enter Password</span>
                <Field
                  name="password"
                  type="password"
                  className="input"
                  placeholder="Enter your password"
                  // style={{
                  //   width: "458px",
                  //   height: "40px",
                  //   borderRadius: "8px",
                  //   border: "1px solid rgba(228, 228, 228, 1)",
                  //   padding: "12px",
                  //   marginTop: "8px",
                  //   marginBottom: "8px",
                  // }}
                />
                {touched.password && errors.password && (
                  <span className="error">{errors.password}</span>
                )}
              </div>
            </div>

            <div
              style={{
                display: "flex",
                width: "159px",
                height: "29px",
                gap: "4px",
                justifyContent: "center",
                marginLeft: "-13px",
              }}
            >
              <div className="error-icon-wrapper">
                <ErrorOutlineIcon className="error-icon" />
              </div>
              <button
                type="button"
                className="forgot-password-link"
                onClick={forgotPassword}
              >
                Forgot Password
              </button>
            </div>

            <div
              className="keep-signed-in"
              style={{ width: "100%", display: "flex", alignItems: "center" }}
            >
              <Field
                type="checkbox"
                name="keepSignedIn"
                id="keepSignedIn"
                className="checkbox"
              />
              <label htmlFor="keepSignedIn" className="keep-signed-in-label">
                Keep me signed in
              </label>
            </div>
            <div className="captcha-box">
              {verified ? (
                <ReCAPTCHA
                  sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                  onChange={onCaptchaChange}
                />
              ) : null}
            </div>
            {isSubmitting ? (
              <div className="flex justify-center">
                <CircularProgress />
              </div>
            ) : (
              <button
                disabled={isSubmitting}
                type="submit"
                className="sign-in-button"
              >
                GET STARTED
              </button>
            )}
          </Form>
        )}
      </Formik>
      <span
        style={{
          font: "Inter",
          fontWeight: "600",
          fontSize: "14px",
          lineHeight: "20px",
          letterSpacing: "-0.4px",
          alignItems: "center",
          color: "rgba(121, 121, 121, 1)",
        }}
      >
        Don't have an account?{" "}
        <button className="text-button" onClick={signUp}>
          Sign up here
        </button>
      </span>
    </div>
  );
};
