import axios from "axios";
import { getAuthorizationToken } from "../config/user";
const REACT_APP_API_URL = process.env.REACT_APP_API_URL;

function managerpa(caseId, data) {
  return axios({
    method: "put",
    url: `${REACT_APP_API_URL}/update-hospital-details/:hospitalid${caseId}`,
    data: data,
    headers: getAuthorizationToken(),
  });
}

function manageApplicationId() {
  return axios({
    method: "get",
    url: `${REACT_APP_API_URL}/rpa-application-list`,
    headers: getAuthorizationToken(),
  });
}

function getHospitalsFromId(applicationnid) {
  return axios({
    method: "get",
    url: `${REACT_APP_API_URL}/rpa-app-hospital-list?applicationId=${applicationnid}&page=${1}`,
    headers: getAuthorizationToken(),
  });
}

function updateRpaDeviceDetails(data) {
  return axios({
    method: "put",
    url: `${REACT_APP_API_URL}/update-rpa-devices-details`,
    headers: getAuthorizationToken(),
    data: data,
  });
}

function getTmsQueries(id) {
  return axios({
    method: "get",
    url: `${REACT_APP_API_URL}/case-queries/${id}`,
    headers: getAuthorizationToken(),
  });
}
function manageQueries(status, page, limit, searchKey, hospitalId) {
  return axios({
    method: "get",
    url: `${REACT_APP_API_URL}/all-queries?status=${status}&page=${page}&limit=${limit}&hospitalId=${hospitalId}&searchKey=${searchKey}`,
    headers: getAuthorizationToken(),
  });
}
function updateTmsQueriesStatus(queryId, status) {
  return axios({
    method: "put",
    url: `${REACT_APP_API_URL}/case-query-status-update?queryId=${queryId}&status=${status}`,
    headers: getAuthorizationToken(),
  });
}

export const RpaServices = {
  managerpa,
  manageApplicationId,
  getHospitalsFromId,
  updateRpaDeviceDetails,
  getTmsQueries,
  updateTmsQueriesStatus,
  manageQueries,
};
