import "./Hospitaldashboard.scss";
import CasewiseDashboard from "./CasewiseDashboard";
import CaseStatus from "./CaseStatus";
import WidgetsOutlinedIcon from "@mui/icons-material/WidgetsOutlined";
import Undersettled from "./Undersettled";
import Lengthbar from "./Lengthbar";
import RevenueAnalytics from "./RevenueAnalytics";
import { useEffect } from "react";
import { dashboardServices } from "../services/DashBoardServices";

function Hospitaldashboard() {
  const getDashboardData = async () => {
    try {
      const response = await dashboardServices.getDashboardData("ACTIVE");
    } catch (error) {
      window.alert(error);
    }
  };
  useEffect(() => {
    getDashboardData();
  }, []);
  return (
    <div>
      <div
        style={{
          fontFamily: "Inter",
          padding: 32,
          marginTop: 93,
          // width: "100%",
        }}
      >
        <span className="path-line">
          <WidgetsOutlinedIcon
            style={{ height: "24px", marginTop: "0px", marginTop: "-4px" }}
          />
          &nbsp; &nbsp;/ &nbsp; &nbsp; Dashboard
        </span>
      </div>
      <div className="Box-Yourdashboard">
        <text
          style={{
            fontFamily: "Inter",
            fontWeight: "600",
            fontSize: "24px",
            lineHeight: "29.05px",
            color: "rgba(25, 25, 25, 1)",
            marginLeft: "30px",
          }}
        >
          Your Dashboard
        </text>
        <hr
          style={{
            width: "100%",
            border: "1px solid rgba(228, 228, 228, 1)",
            marginTop: "30px",
          }}
        />
      </div>

      <div style={{ display: "flex", flexDirection: "row" }}>
        <div style={{ width: "64%" }}>
          <CaseStatus />
          <CasewiseDashboard />
        </div>
        <Undersettled />
        <Lengthbar />
        <div
          style={{
            marginTop: "720px",
            marginLeft: "-500px",
            width: "496.33px",
            height: "748px",
            gap: "10px",
          }}
        >
          <RevenueAnalytics />
        </div>
      </div>

      {/* <CasewiseDashboard /> */}
    </div>
  );
}

export default Hospitaldashboard;
