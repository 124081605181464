import axios from "axios";
import { getAuthorizationToken } from "../config/user";

const REACT_APP_API_URL = process.env.REACT_APP_API_URL;

function getDefaultStageTimeColor() {
  return axios({
    method: "get",
    url: `${REACT_APP_API_URL}/default-stage-time-color`,
    headers: getAuthorizationToken(),
  });
}
function getCaseStageReport(
  startDate,
  endDate,
  hospitalId,
  page,
  status,
  searchKey
) {
  return axios({
    method: "get",
    url: `${REACT_APP_API_URL}/case-stage-report?startDate=${new Date(
      startDate
    ).toISOString()}&endDate=${new Date(
      endDate
    ).toISOString()}&hospitalId=${hospitalId}&page=${page}&status=${status}&searchKey=${searchKey}`,
    headers: getAuthorizationToken(),
  });
}

function getCaseStageUserList(commandCenterStage) {
  return axios({
    method: "get",
    url: `${REACT_APP_API_URL}/case-stage-user-list?commandCenterStage=${commandCenterStage}`,
    headers: getAuthorizationToken(),
  });
}
function assignCaseStage(stageId, userId) {
  return axios({
    method: "put",
    url: `${REACT_APP_API_URL}/assign-case-stage/${stageId}?assignedTo=${userId}`,
    headers: getAuthorizationToken(),
  });
}
function getUsers() {
  return axios({
    method: "get",
    url: `${REACT_APP_API_URL}/all-userid-username`,
    headers: getAuthorizationToken(),
  });
}
export const ReportServices = {
  getCaseStageReport,
  getDefaultStageTimeColor,
  getCaseStageUserList,
  assignCaseStage,
  getUsers,
};
