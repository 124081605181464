import { useState, useEffect } from "react";
import { CaseServices } from "../../../services/CaseServices";
import CloseIcon from "../../../assets/crossIcon.svg";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";
const MoveToNextStage = ({ setNextStage, caseId }) => {
  const [latestStage, setLatestStage] = useState(null);
  const [nextPossibleStage, setNextPossibleStage] = useState(null);
  const [selectedNextStage, setSelectedNextStage] = useState(null);
  const [reason, setReason] = useState("");
  const [isUploadingStatus, setUploadingStatus] = useState(false);
  const navigate = useNavigate();
  const getLatestCaseStage = async () => {
    try {
      const response = await CaseServices.getLatestCaseStage(caseId);
      setLatestStage(response.data);
      console.log("latest response :", response.data);
    } catch (error) {
      window.alert(error);
    }
  };
  const getNextPossibleStage = async () => {
    try {
      const response = await CaseServices.getNextPossibleSubStages(
        latestStage?.currnetSubMasterStage[0]?.commandCenterStage
      );
      console.log("next stage response:", response);
      setNextPossibleStage(response.data);
    } catch (error) {
      window.alert(error);
    }
  };
  const handleUpdateStatus = async () => {
    setUploadingStatus(true);
    if (reason) {
      try {
        const postStageData = {
          hsaStage: selectedNextStage?.hsaWebStatus,
          commandCeneterStage: selectedNextStage?.commandCenterStageStatus,
          nextStepUser: selectedNextStage?.nextStepUser,
          remark: "Forcefully updated",
          reason: reason,
          caseDetails: [
            {
              query: "",
              queryResponse: "",
            },
          ],
        };
        const stageResponse = await CaseServices.updateSubMasterStage(
          caseId,
          postStageData
        );
        window.alert(stageResponse.data.message);
        navigate("/dashboard/ActiveCases");
      } catch (error) {
        window.alert(Error);
      }
    } else {
      window.alert("Please Enter Reason");
    }
    setUploadingStatus(false);
  };
  useEffect(() => {
    getLatestCaseStage();
  }, []);
  useEffect(() => {
    if (latestStage) {
      getNextPossibleStage();
    }
  }, [latestStage]);

  return (
    <div className="fixed top-0 left-0 bg-[#017C5780] h-screen w-screen  flex justify-center items-center z-[1200]">
      <div className="w-[40%] h-fit bg-white rounded-[24px] p-4 pb-8">
        <div
          className="flex justify-end cursor-pointer"
          onClick={() => setNextStage(false)}
        >
          <img src={CloseIcon} height={30} width={30} alt="close-icon" />
        </div>

        <div className="mt-5">
          <div className="text-xl text-[#191919] font-semibold text-center">
            Current Stage
          </div>
          <div className="text-medium text-center">
            {latestStage?.currnetSubMasterStage[0]?.commandCenterStage}
          </div>
          <div className="flex flex-col items-center">
            <div className="w-[50%] mt-4">
              {" "}
              <div className="font-semibold mb-2">Next Stages</div>
              <Select
                options={nextPossibleStage?.nextPossibleStages}
                getOptionLabel={(options) => {
                  return options["commandCenterStageStatus"];
                }}
                onChange={(item) => setSelectedNextStage(item)}
              />
            </div>

            <div className="w-[50%] mt-4">
              <div className="font-semibold mb-2">Reason</div>
              <div
                style={{ border: "1px solid #E5E5E5" }}
                className="px-2 py-2 rounded-[8px]"
              >
                <input
                  type="text"
                  placeholder="Enter Reason"
                  className="w-full border-none outline-none"
                  onChange={(e) => setReason(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="flex justify-center mt-5">
            {isUploadingStatus ? (
              <CircularProgress />
            ) : (
              <div
                className="text-sm font-semibold text-white bg-[#017C57] px-4 py-2 rounded-full cursor-pointer"
                onClick={() => {
                  if (selectedNextStage) {
                    handleUpdateStatus();
                  } else {
                    window.alert("Please Select Next Stage");
                  }
                }}
              >
                Update Status
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MoveToNextStage;
