import React, { useState, useEffect } from "react";
import { CircularProgress } from "@mui/material";
import WidgetsOutlinedIcon from "@mui/icons-material/WidgetsOutlined";
import SearchIcon from "@mui/icons-material/Search";
import { RpaServices } from "../services/RpaServices";
import CustomPagination from "../utils/paginationUtils";
import "./ManageRpa.scss";
import Close from "@mui/icons-material/Close";
import { Switch,  } from "@mui/material";
import EditIcon from "../assets/editIcon.svg";
import UpdateApplication from "./UpdateApplication";
import VisibilityIcon from "@mui/icons-material/Visibility";
const ManageApplication = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [rpaApplicationList, setRpaApplicationList] = useState([]);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [popupOpenForApplication, setPopUpOpenForApplication] = useState([]);
  const [selectedApplication, setSelectedApplication] = useState(null);
  const [reloadPage, setReloadPage] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  // const limit = 10;
  const getApplicationIdList = async () => {
    try {
      setIsLoading(true);
      const response = await RpaServices.manageApplicationId();
      console.log("Response is", response?.data);
      if (response?.data?.applications) {
        setRpaApplicationList(response.data.applications);
        setTotalPages(response.data.totalPages || 1);
      } else {
        setRpaApplicationList([]);
      }
    } catch (error) {
      console.error("Error fetching application data:", error);
      setRpaApplicationList([]);
    } finally {
      setIsLoading(false);
      setReloadPage(false);
    }
  };
  useEffect(() => {
    getApplicationIdList();
  }, [page, reloadPage]);

  const handleStatusChange = async (application, status) => {
    const updatedStatus =
      application?.status === "ACTIVE" ? "INACTIVE" : "ACTIVE";
    const updatedData = {
      applicationId: application.applicationId,
      status: updatedStatus,
    };
    try {
      const response = await RpaServices.updateRpaDeviceDetails(updatedData);
      console.log(response.status, response.data);
      if (response?.status === 201) {
        setReloadPage((prev) => !prev);
        console.log("Status updated", response.data);
      }
    } catch (error) {
      console.error("Error while changing status:", error);
      alert("Error while changing status", error);
    }
  };

  const getapplicationsForApplicationId = async (id) => {
    setIsPopupVisible(true);
    try {
      const response = await RpaServices.getHospitalsFromId(id);
      setPopUpOpenForApplication(response.data);
      console.log("applications for Application ID:", id, response);
    } catch (error) {
      console.error("Error fetching applications for application ID:", error);
    }
  };

  return (
    <div className="initiateadmission-box">
      <span className="path_line">
        <WidgetsOutlinedIcon style={{ height: "24px" }} /> &nbsp;/ &nbsp;
        Dashboard &nbsp;/ &nbsp; Manage application
      </span>

      <div className="flex justify-between items-center">
        <div className="heading">Manage application</div>
        <div
          style={{ border: "1px solid #E4E4E4" }}
          className="flex gap-2 px-2 py-2 rounded-[8px]"
        >
          <SearchIcon />
          <input
            type="text"
            className="outline-none border-0"
            placeholder="Search Anything"
          />
        </div>
      </div>
      <hr style={{ color: "#E7E7E7", marginBottom: "20px" }} />

      <div className="Second-container">
        <div className="flex justify-between">
          {isLoading ? (
            <div className="w-full h-[500px] flex justify-center items-center">
              <CircularProgress />
            </div>
          ) : rpaApplicationList.length > 0 ? (
            <section className="mt-1 w-full">
              <div className="grid grid-cols-4 text-[#191919] font-semibold px-8 py-2 gap-4">
                <div className="text-[18px]">Application Id</div>
                <div className="text-[18px]">Application Username</div>
                <div className="text-[18px]">Application Status</div>
                <div className="text-[18px]">Actions</div>
              </div>

              {rpaApplicationList?.length > 0 ? (
                rpaApplicationList.map((application, idx) => (
                  <article
                    key={idx}
                    className={`py-6 px-8 rounded-[16px] ${
                      idx % 2 === 0 ? "bg-white" : "bg-[#F9F9F9]"
                    }`}
                  >
                    <div className="grid grid-cols-4 text-[#797979] text-[18px] gap-4">
                      <div>{application?.applicationId ?? "--"}</div>
                      <div>{application?.userName ?? "--"}</div>
                      <div className="flex items-center gap-2">
                        <span style={{ textTransform: "capitalize" }}>
                          {application?.status === "ACTIVE"
                            ? "Active"
                            : "Inactive"}
                        </span>
                        <Switch
                          checked={application?.status === "ACTIVE"}
                          onChange={() =>
                            handleStatusChange(application, application?.status)
                          }
                          inputProps={{ "aria-label": "toggle status" }}
                          style={{
                            color:
                              application?.status === "ACTIVE"
                                ? "#017C57"
                                : "#B0B0B0",
                          }}
                          sx={{
                            "& .MuiSwitch-thumb": {
                              backgroundColor:
                                application?.status === "ACTIVE"
                                  ? "#017C57"
                                  : "#D3D3D3",
                            },
                            "& .MuiSwitch-track": {
                              backgroundColor:
                                application?.status === "ACTIVE"
                                  ? "#017C57"
                                  : "#D3D3D3",
                            },
                          }}
                        />
                      </div>
                      <div className="flex gap-4">
                        <VisibilityIcon
                          fontSize="medium"
                          sx={{ color: "#017C57", cursor: "pointer" }}
                          onClick={() =>
                            application?.applicationId &&
                            getapplicationsForApplicationId(
                              application.applicationId
                            )
                          }
                        />

                        <img
                          src={EditIcon}
                          alt="edit-icon"
                          className="cursor-pointer w-5 h-5"
                          onClick={() => setSelectedApplication(application)}
                        />
                      </div>
                    </div>
                  </article>
                ))
              ) : (
                <p className="text-center text-[#797979] py-4">
                  No applications available.
                </p>
              )}
            </section>
          ) : (
            <div className="w-full h-full flex justify-center items-center font-semibold text-xl text-[#191919]">
              No Data Found
            </div>
          )}
        </div>
      </div>
      {isPopupVisible && (
        <div className="popup-overlay">
          <div className="popup-container relative h-[92%] w-[328px] xl:w-[50%] bg-white rounded-[16px] p-6 xl:p-8 z-[1200] overflow-y-auto flex flex-col items-center justify-center">
            <div className="absolute right-12 top-6 cursor-pointer">
              <Close
                alt="close-icon"
                onClick={() => {
                  setIsPopupVisible(false);
                }}
              />
            </div>

            {popupOpenForApplication?.hospitals &&
            popupOpenForApplication?.hospitals?.length > 0 ? (
              <table className="w-full max-w-[300px] border-collapse border border-gray-300 text-left mt-4">
                <thead className="bg-gray-200 my-2">
                  <tr>
                    <th className="border border-gray-300 p-2 my-2">
                      Mapped Hospital List
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {popupOpenForApplication.hospitals.map((hospital, index) => (
                    <tr key={index} className="hover:bg-gray-100">
                      <td className="border border-gray-300 p-2">
                        {hospital.name || "--"}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <div className="text-gray-500 text-center mt-4">
                No hospital mapped
              </div>
            )}

            {/* {popupOpenForApplication?.applications === undefined && (
        <div className="text-red-500 text-center mt-4">
          Error fetching hospital list
        </div>
      )} */}
          </div>
        </div>
      )}

      <div className="flex justify-center mt-4">
        <CustomPagination
          page={page}
          setPage={setPage}
          totalPages={totalPages}
        />
      </div>

      {selectedApplication && (
        <UpdateApplication
          application={selectedApplication}
          // getUserData={getUserData}
          isOpen={setSelectedApplication}
          getApplicationIdList={getApplicationIdList}
        />
      )}
    </div>
  );
};

export default ManageApplication;
