import { useState, useEffect } from "react";
import CloseIcon from "../../../assets/crossIcon.svg";
import { CaseServices } from "../../../services/CaseServices";
import { CircularProgress } from "@mui/material";
import { PreinitiateServices } from "../../../services/PreinitiateServices";
const MapDocumentToUrl = ({
  caseId,
  selectedDoc,
  isOpen,
  getDocumentsData,
}) => {
  const [selectedDocumentType, setSelectedDocumentType] = useState(null);
  const [selectedDocumentId, setSelectedDocumentId] = useState(null);
  const [mappedDocuments, setMappedDocuments] = useState(null);
  const [documentNameList, setDocumentNameList] = useState(null);
  const [saving, setSaving] = useState(false);

  //Method to update the document status
  const handleMappedDocumentStatus = async (filteredData) => {
    //Changing the status of the images to "mapped"
    const data = filteredData.map((doc) => {
      return {
        documentId: doc.id,
        status: "MAPPED",
        mappedWith: selectedDocumentId,
      };
    });
    try {
      await PreinitiateServices.updateReferenceDocumentStatus(data, caseId);
      window.alert("Document status updated Successfully");
      isOpen(false);
      getDocumentsData();
    } catch (error) {
      window.alert(error);
    }
  };
  const handleSave = async () => {
    setSaving(true);
    // filtering those images which are not mapped with the selected document yet
    const filteredData = selectedDoc.filter(
      (doc) => !doc.mappedWith?.includes(`${selectedDocumentId}`)
    );
    const data = filteredData.map((doc) => ({
      sequence: doc.sequence,
      documentUrl: doc.documentUrl,
    }));
    //Checking if document name and document type is selected or not
    if (selectedDocumentType && selectedDocumentId) {
      //Checking whether the we have any image map or not
      if (data.length > 0) {
        try {
          const response = await CaseServices.saveDocumentUrl(
            selectedDocumentId,
            data
          );
          window.alert(response.data.message);
          handleMappedDocumentStatus(filteredData);
        } catch (error) {
          window.alert(error);
        }
      } else {
        window.alert("Images already mapped with the selected document");
      }
    } else {
      window.alert("Document Type and Document Name Required");
    }
    setSaving(false);
  };
  const getMappedDocuments = async () => {
    try {
      const response = await CaseServices.getMappedDocuments(caseId);
      const data = [];
      for (let i in response?.data?.mappedDocuments) {
        let tempData = { key: i, value: response?.data?.mappedDocuments[i] };
        data.push(tempData);
      }

      setMappedDocuments(data);
    } catch (error) {
      window.alert(error);
    }
  };
  useEffect(() => {
    if (selectedDocumentType) {
      for (let i of mappedDocuments) {
        if (i.key === selectedDocumentType) {
          setDocumentNameList(i.value);
        }
      }
    }
  }, [selectedDocumentType]);
  useEffect(() => {
    getMappedDocuments();
  }, []);

  return (
    <div className="fixed top-0 left-0 h-full w-full flex justify-center items-center bg-[#017C5780] z-20">
      <div className="relative bg-white rounded-[16px] w-[90%] h-[30%] xl:w-[30%] p-4 xl:p-8">
        <div
          className="absolute top-4 right-4 xl:top-7 xl:right-8 cursor-pointer"
          onClick={() => isOpen(false)}
        >
          <img src={CloseIcon} height={30} width={30} alt="close-icon" />
        </div>
        <div className="flex flex-col xl:flex-row gap-4 mt-12">
          <select
            name="documentType"
            className="h-[40px] text-[18px] text-[#797979] rounded-[8px]
            pl-[12px] w-[100%] xl:w-[50%] "
            onChange={(e) => setSelectedDocumentType(e.target.value)}
          >
            <option value="">Select Document Type</option>
            {mappedDocuments &&
              mappedDocuments.length > 0 &&
              mappedDocuments?.map((document, idx) => {
                return (
                  <option key={idx} value={document.key}>
                    {document.key}
                  </option>
                );
              })}
          </select>
          <select
            name="documentName"
            className="h-[40px] text-[18px] text-[#797979] rounded-[8px]
            pl-[12px] w-[100%] xl:w-[50%]"
            // onChange={(e) => handleDocumentSelect(e.target.value)}
            onChange={(e) => setSelectedDocumentId(e.target.value)}
          >
            <option value="">Select Document Name</option>{" "}
            {documentNameList &&
              documentNameList.length > 0 &&
              documentNameList?.map((doc, idx) => {
                return (
                  <option key={idx} value={doc.id}>
                    {doc.documentName}
                  </option>
                );
              })}
          </select>
        </div>
        <div className=" flex justify-center mt-8 ">
          {saving ? (
            <CircularProgress />
          ) : (
            <div
              className="text-sm text-white bg-[#017C57] px-12 py-2 rounded-full cursor-pointer"
              onClick={handleSave}
            >
              Save Data
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MapDocumentToUrl;
