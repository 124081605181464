import { useState } from "react";
import CloseIcon from "../../assets/crossIcon.svg";
import Select from "react-select";
import { CaseServices } from "../../services/CaseServices";
import { CircularProgress } from "@mui/material";
const DeactivateOrDeleteCase = ({ setOpen, selectedCase }) => {
  const [selectedOperation, setSelectedOperation] = useState(null);
  const [reason, setReason] = useState("");
  const [isSubmitting, setSubmitting] = useState(false);
  const handleCaseDeactivation = async () => {
    setSubmitting(true);
    try {
      const response = await CaseServices.deactivateCase(
        selectedCase.id,
        selectedOperation.value,
        reason
      );
      window.alert(response.data.message);
      setOpen(false);
    } catch (error) {
      window.alert(error);
    }
    setSubmitting(false);
  };
  return (
    <div className="fixed top-0 left-0 h-screen w-screen bg-[#017C5780] flex justify-center items-center">
      <div className="w-[50%] h-fit bg-white rounded-[16px] px-4 pt-4 pb-10">
        <div className="flex justify-end">
          <div>
            <img
              src={CloseIcon}
              alt="close-icon"
              className="cursor-pointer"
              onClick={() => setOpen(false)}
            />
          </div>
        </div>
        <div className="flex justify-between items-center px-6 mt-2">
          <div>
            <div className="font-semibold text-[#191919]">
              Beneficiary Number
            </div>
            <div>{selectedCase?.patient?.beneficiaryNo || "----"}</div>
          </div>
          <div>
            <div className="font-semibold text-[#191919]">Patient Name</div>
            <div>{selectedCase?.patient?.patientName || "----"}</div>
          </div>
          <div>
            <div className="font-semibold text-[#191919]">Hospital Name</div>
            <div>{selectedCase?.hospital?.name || "----"}</div>
          </div>
        </div>
        <div className="flex justify-between mt-6 px-6">
          <div className="flex flex-col justify-between w-[48%] h-[100px] 2xl:h-[80px]">
            <div className="font-semibold mb-2">
              Please Select the operation you want to perform:
            </div>
            <Select
              options={[
                { value: "admission_not_initiated", label: "Deactivate" },
                { value: "delete_case", label: "Delete" },
              ]}
              getOptionLabel={(options) => options.label}
              getOptionValue={(options) => options.value}
              onChange={(item) => setSelectedOperation(item)}
            />
          </div>
          <div className="flex flex-col justify-between w-[48%] h-[100px] 2xl:h-[80px]">
            <div className="font-semibold mb-2">
              {" "}
              Please specify the reason.
            </div>
            <div
              style={{ border: "1px solid #E4E4E4" }}
              className="rounded-[4px] px-3 py-1.5"
            >
              <input
                type="text"
                placeholder="Rejection reason"
                className="w-full outline-none border-none "
                onChange={(e) => setReason(e.target.value)}
              />
            </div>
          </div>
        </div>
        {selectedOperation && reason && (
          <div className="mt-4 px-8">
            <div className="font-semibold">
              Selected case will get{" "}
              <span className="text-[#CF3636]">{selectedOperation.label}d</span>
              . Are you sure you want to do it.
            </div>
            {isSubmitting ? (
              <div className="flex justify-center mt-2">
                <CircularProgress />
              </div>
            ) : (
              <div className="flex justify-center items-center gap-4 mt-2">
                <div
                  className="w-[100px] text-sm text-white text-center font-semibold bg-[#017C57] rounded-full px-4 py-1.5 cursor-pointer"
                  onClick={() => setOpen(false)}
                >
                  NO
                </div>
                <div
                  className="w-[100px] text-sm text-white text-center font-semibold bg-[#017C57] rounded-full px-4 py-1.5 cursor-pointer"
                  onClick={handleCaseDeactivation}
                >
                  YES
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default DeactivateOrDeleteCase;
