import React, { useEffect, useState } from "react";
import WidgetsOutlinedIcon from "@mui/icons-material/WidgetsOutlined";
import { logOut } from "../../config/user";
import { NotificationServices } from "../../services/NotificationServices";
import { HospitalServices } from "../../services/HospitalServices";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import Select from "react-select";
import { CircularProgress } from "@mui/material";
import SentNotifications from "./SentNotifications";
import "./manualNotification.scss";
const ManualNotification = () => {
  const [hospitalList, setHospitalList] = useState([]);
  const [selectedHospitals, setSelectedHospitals] = useState([]);
  const [roleList, setRoleList] = useState([]);
  const [selectedReceiver, setSelectedReceiver] = useState(null);
  const [selectAllHospitals, setSelectAllHospitals] = useState(false);
  const [title, setTitle] = useState(null);
  const [body, setBody] = useState(null);
  const [isSending, setIsSending] = useState(false);
  const [searchKey, setSearchKey] = useState("");
  const [open, setOpen] = useState(false);
  const [sentNotificationList, setSentNotificationList] = useState(null);
  const [resendNotification, setResendNotification] = useState(null);

  const getValue = () => {
    let value = [];
    for (let hospitalId of selectedHospitals) {
      for (let hospital of hospitalList) {
        if (hospitalId === hospital.id) {
          value.push(hospital);
        }
      }
    }
    return value;
  };
  const getManualNotifications = async () => {
    try {
      const response = await NotificationServices.getManualNotifications(
        searchKey
      );
      setSentNotificationList(response?.data?.manualNotifications);
    } catch (error) {
      if (error?.response?.status === 401) {
        logOut();
      } else {
        window.alert(error);
      }
    }
  };
  const handleSubmit = async () => {
    setIsSending(true);
    const hospitalIds = selectedHospitals?.map((hospital) => hospital.id);
    const receivers = selectedReceiver?.map((receiver) => receiver.roleName);
    const data = {
      hospitalIds: selectAllHospitals ? null : hospitalIds,
      toAllHospitals: selectAllHospitals,
      to: receivers,
      title: title,
      body: body,
      notificationStatus: "neutral",
      color: "#676767",
    };
    if (selectedReceiver && selectedReceiver.length > 0) {
      try {
        await NotificationServices.manualNotificationSend(data);
        window.alert("Message Sent Successfully");
        getManualNotifications();
      } catch (error) {
        window.alert(error);
      }
    } else {
      window.alert("Please select atleast one receiver");
    }
    setIsSending(false);
  };
  const getHospitals = async () => {
    try {
      const response = await HospitalServices.gethospitals("", 1, 1500);
      setHospitalList(response?.data?.list);
    } catch (error) {
      window.alert(error);
    }
  };
  const getRoleList = async () => {
    try {
      const response = await NotificationServices.getRoleList();
      setRoleList(response?.data?.allRoles);
    } catch (error) {
      window.alert(error);
    }
  };

  useEffect(() => {
    getHospitals();
    getRoleList();
    getManualNotifications();
  }, []);
  useEffect(() => {
    getManualNotifications();
  }, [searchKey]);

  useEffect(() => {
    //This use effect is called to populate the form values when we select any sent notification from the sent notification list.
    if (resendNotification) {
      setTitle(resendNotification?.title);
      setBody(resendNotification?.body);

      let hospitals = [];
      for (let hospitalId of resendNotification.hospitalIds) {
        for (let hospital of hospitalList) {
          if (hospitalId === hospital.id) {
            hospitals.push(hospital);
          }
        }
      }
      setSelectedHospitals(hospitals);

      let receivers = [];
      for (let receiverName of resendNotification.to) {
        for (let receiver of roleList) {
          if (receiverName === receiver.roleName) {
            receivers.push(receiver);
          }
        }
      }
      setSelectedReceiver(receivers);

      setSelectAllHospitals(resendNotification.toAllHospitals);
    }
  }, [resendNotification]);
  return (
    <div className="manage-box">
      <span className="path_line">
        <WidgetsOutlinedIcon style={{ height: "24px" }} /> &nbsp; &nbsp;/ &nbsp;
        &nbsp; Profile &nbsp; &nbsp;/ &nbsp; &nbsp; Manual Notification
      </span>
      <div className="flex justify-between items-center ">
        <text className="heading"> MANUAL NOTIFICATION</text>
        <div
          className="w-fit h-fit text-sm text-white bg-[#017C57] font-semibold py-2 px-4 rounded-full cursor-pointer"
          onClick={() => setOpen(true)}
        >
          <SendOutlinedIcon style={{ height: "16px", rotate: "-45deg" }} />
          View Sent Notifications
        </div>
      </div>
      <hr
        style={{ width: "100%", border: "1px solid rgba(228, 228, 228, 1)" }}
      />
      <div
        style={{ border: "1px solid #e4e4e4", marginTop: "24px" }}
        className="rounded-[16px] p-5"
      >
        <div>
          <div className="flex justify-between">
            <div className="w-[48%]">
              <div className="mb-2">Select Hospitals</div>
              <Select
                options={hospitalList}
                isDisabled={selectAllHospitals}
                getOptionLabel={(options) => {
                  return options["name"];
                }}
                getOptionValue={(options) => {
                  return options["id"];
                }}
                onChange={(values) => setSelectedHospitals(values)}
                value={selectedHospitals}
                isMulti
              />
              <div className="flex items-center gap-2 text-sm text-[#191919] mt-2 ">
                <input
                  type="checkbox"
                  checked={selectAllHospitals}
                  className="w-[18px] h-[18px]"
                  onClick={() => setSelectAllHospitals(!selectAllHospitals)}
                />{" "}
                Select All Hospitals
              </div>
            </div>
            <div className="w-[48%]">
              <div className="mb-2">Select Receivers</div>
              <Select
                options={roleList}
                getOptionLabel={(options) => {
                  return options["roleName"];
                }}
                getOptionValue={(options) => {
                  return options["roleName"];
                }}
                onChange={(values) => setSelectedReceiver(values)}
                value={selectedReceiver}
                isMulti
              />
            </div>
          </div>
          <div className="w-[48%] mt-8">
            <div className="mb-2">Subject</div>
            <div
              style={{ border: "1px solid #E4E4E4" }}
              className="w-full py-2 px-4 rounded-[4px]"
            >
              <input
                name
                type="text"
                placeholder="Please Enter Your Subject"
                className="w-full border-none outline-none"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>
          </div>
          <div className="w-[48%] mt-8 ">
            <div className="w-full mb-2">Body</div>
            <div className="w-full">
              <textarea
                name="body"
                placeholder="Please Enter Your Message"
                style={{ border: "1px solid #E4E4E4" }}
                className="w-full py-2 px-4 rounded-[4px] outline-none"
                rows={10}
                value={body}
                onChange={(e) => setBody(e.target.value)}
              ></textarea>
            </div>
          </div>
        </div>
        <div className="flex justify-center items-center mt-12">
          {isSending ? (
            <CircularProgress />
          ) : (
            <div
              style={{ border: "1px solid #017C57" }}
              className="text-sm text-[#017C57] font-semibold px-10 py-2 rounded-full cursor-pointer"
              onClick={handleSubmit}
            >
              SUBMIT
            </div>
          )}
        </div>
      </div>
      {open && (
        <SentNotifications
          setOpen={setOpen}
          sentNotificationList={sentNotificationList}
          setResendNotification={setResendNotification}
        />
      )}
    </div>
  );
};

export default ManualNotification;
