import React from "react";
import WidgetsOutlinedIcon from "@mui/icons-material/WidgetsOutlined";
import editIconWhite from "../assets/editIconWhite.svg";
import LaunchOutlinedIcon from "@mui/icons-material/LaunchOutlined";
import plusIcon from "../assets/PlusIcon.png";
function InterCase() {
  return (
    <div className="initiateadmission-box bg-[#F7F7F7]">
      <span className="path_line">
        <WidgetsOutlinedIcon style={{ height: "24px" }} /> &nbsp;/ &nbsp;
        Internal case
      </span>
      <div className="flex justify-between items-center ">
        <text className="heading"> Internal Case</text>
        <div className="w-[230px] h-[34px] text-[14px] leading-[18px] text-white bg-[#017C57] font-[600] py-2 px-4 rounded-[32px] cursor-pointer uppercase tracking-[0.8px] flex justify-between items-center">
          <span>
            <img
              src={editIconWhite}
              alt="edit-icon"
              className="w-[14.75px] h-[14.75px] "
            />
          </span>
          <span>Change CASE Status</span>
        </div>
      </div>
      <hr
        style={{ width: "100%", border: "1px solid rgba(228, 228, 228, 1)" }}
      />
      <div className=" rounded-[16px] border border-gray-300 bg-white p-[16px] pb-[32px]">
        <div className="heading font-inter font-semibold text-[20px] leading-[24.2px] text-center">
          Hospital Details
        </div>
        <div className="grid grid-cols-3 gap-2">
          <label className="label1">
            <div className="text-content">
              Full Name <span className="required">*</span>
            </div>
            <input
              type="text"
              placeholder="Enter your full name"
              className="w-[100%] h-[40px] gap-[8px] border border-[#E4E4E4] rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </label>

          <label className="label1">
            <div className="text-content">
              Hospital City <span className="required">*</span>
            </div>
            <select className="w-[100%] h-[40px] gap-[8px] border border-[#E4E4E4] rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-500">
              <option className="text-[#797979]">Enter Details</option>
            </select>
          </label>

          <label className="label1">
            <div className="text-content">
              HUID <span className="required">*</span>
            </div>
            <select className="w-[100%] h-[40px] gap-[8px] border border-[#E4E4E4] rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-500">
              <option className="text-[#797979]">Enter Details</option>
            </select>
          </label>
        </div>
        <div className="flex justify-center items-center mt-4">
          <button
            type="submit"
            className="text-sm font-thin text-white bg-[#017C57] py-2 px-4 rounded-full border-none cursor-pointer"
          >
            Save Details
          </button>
        </div>
      </div>

      <div className="w-full  rounded-[16px] border border-gray-300 bg-white p-[16px] pb-[32px] mt-4">
        <div>
          <div className="heading font-inter font-semibold text-[20px] leading-[24.2px] text-center">
            Patient Details
          </div>
          <hr style={{ border: "1px solid #DEDEDE" }} />
        </div>

        <div className="grid grid-cols-3 gap-8">
          <label className="label1">
            <div className="text-content">
              Beneficiary No <span className="required">*</span>
            </div>
            <input
              type="text"
              placeholder="Enter Details"
              className="w-[100%] h-[40px] gap-[8px] border border-[#E4E4E4] rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </label>

          <label className="label1">
            <div className="text-content">
              Patient Name <span className="required">*</span>
            </div>
            <input
              type="text"
              placeholder="Enter Details"
              className="w-[100%] h-[40px] gap-[8px] border border-[#E4E4E4] rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </label>
          <label className="label1">
            <div className="text-content">
              Email Id <span className="required">*</span>
            </div>
            <input
              type="text"
              placeholder="Enter Email Address"
              className="w-[100%] h-[40px] gap-[8px] border border-[#E4E4E4] rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </label>
          <label className="label1">
            <div className="text-content">
              Phone <span className="required">*</span>
            </div>
            <input
              type="text"
              placeholder="Enter Phone Number "
              className="w-[100%] h-[40px] gap-[8px] border border-[#E4E4E4] rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </label>
          <label className="label1">
            <div className="text-content">
              Gender <span className="required">*</span>
            </div>
            <select className="w-[100%] h-[40px] gap-[8px] border border-[#E4E4E4] rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-500">
              <option value="" className="text-[#797979]">
                Enter Details
              </option>
            </select>
          </label>
          <label className="label1">
            <div className="text-content">
              Age <span className="required">*</span>
            </div>
            <input
              type="text"
              placeholder="Enter Details"
              className="w-[100%] h-[40px] gap-[8px] border border-[#E4E4E4] rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </label>
          <label className="label1">
            <div className="text-content">
              State <span className="required">*</span>
            </div>
            <select className="w-[100%] h-[40px] gap-[8px] border border-[#E4E4E4] rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-500">
              <option value="" className="text-[#797979]">
                Enter Details
              </option>
            </select>
          </label>
          <label className="label1">
            <div className="text-content">
              City <span className="required">*</span>
            </div>
            <select className="w-[100%] h-[40px] gap-[8px] border border-[#E4E4E4] rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-500">
              <option value="" className="text-[#797979]">
                Enter Details
              </option>
            </select>
          </label>
          <label className="label1">
            <div className="text-content">
              Registered Date <span className="required">*</span>
            </div>
            <input
              type="text"
              className="w-[100%] h-[40px] gap-[8px] border border-[#E4E4E4] rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </label>
        </div>
        <div className="flex justify-center items-center mt-4">
          <button
            type="submit"
            className="text-sm font-thin text-white bg-[#017C57] py-2 px-4 rounded-full border-none cursor-pointer"
          >
            Save and proceed
          </button>
        </div>
      </div>
      <div className="w-full rounded-[16px] border border-gray-300 bg-white p-[16px] pb-[32px] mt-4">
        <div>
          <div className="heading font-inter font-semibold text-[20px] leading-[24.2px] text-center">
            Case Details
          </div>
          <hr style={{ border: "1px solid #DEDEDE" }} />
        </div>

        <div
          style={{ border: "1px solid #DEDEDE" }}
          className="w-full h-[100%] rounded-[8px] bg-white p-[16px] mt-4"
        >
          <div className="w-full flex justify-start font-semibold text-[16px] mt-0">
            Procedure
          </div>
          <div className="grid grid-cols-3 gap-8">
            <label className="label1">
              <div className="text-content">
                Specialty <span className="required">*</span>
              </div>
              <select className="w-[100%] h-[40px] gap-[8px] border border-[#E4E4E4] rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-500">
                <option value="" className="text-[#797979]">
                  Enter Details
                </option>
              </select>
            </label>

            <label className="label1">
              <div className="text-content">
                Procedure ID & Name <span className="required">*</span>
              </div>
              <select className="w-[100%] h-[40px] gap-[8px] border border-[#E4E4E4] rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-500">
                <option value="" className="text-[#797979]">
                  Enter Details
                </option>
              </select>
            </label>
            <label className="label1">
              <div className="text-content">
                ICHI Code /Description <span className="required">*</span>
              </div>
              <select className="w-[100%] h-[40px] gap-[8px] border border-[#E4E4E4] rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-500">
                <option value="" className="text-[#797979]">
                  Enter Details
                </option>
              </select>
            </label>
            <label className="label1">
              <div className="text-content">
                Stratification <span className="required">*</span>
              </div>
              <select className="w-[100%] h-[40px] gap-[8px] border border-[#E4E4E4] rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-500">
                <option value="" className="text-[#797979]">
                  Enter Details
                </option>
              </select>
            </label>
            <label className="label1">
              <div className="text-content">
                Implants <span className="required">*</span>
              </div>
              <select className="w-[100%] h-[40px] gap-[8px] border border-[#E4E4E4] rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-500">
                <option value="" className="text-[#797979]">
                  Enter Details
                </option>
              </select>
            </label>
            <label className="label1">
              <div className="text-content">
                Doctor Name <span className="required">*</span>
              </div>
              <select className="w-[100%] h-[40px] gap-[8px] border border-[#E4E4E4] rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-500">
                <option value="" className="text-[#797979]">
                  Enter Details
                </option>
              </select>
            </label>
            <label className="label1">
              <div className="text-content">
                Package Cost<span className="required">*</span>
              </div>
              <select className="w-[100%] h-[40px] gap-[8px] border border-[#E4E4E4] rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-500">
                <option value="" className="text-[#797979]">
                  Enter Details
                </option>
              </select>
            </label>
          </div>
        </div>

        <hr style={{ border: "1px solid #DEDEDE" }} />
        <div class="block xl:flex mt-[40px] gap-[8px]">
          <div class="general-modal-container">
            <button
              className="w-[168px] h-[33px] rounded-[32px] border border-[#017C57] text-[#017C57] text-[14px] font-[400] bg-transparent flex items-center justify-center gap-[3px]"
              type="button"
            >
              General Findings
              <LaunchOutlinedIcon style={{ height: "14px" }} />
            </button>
          </div>
          <div class="personal-modal-container">
            <button
              className="w-[168px] h-[33px] rounded-[32px] border border-[#017C57] text-[#017C57] text-[14px] font-[400] bg-transparent flex items-center justify-center gap-[3px]"
              type="button"
            >
              Personal History
              <LaunchOutlinedIcon style={{ height: "14px" }} />
            </button>
          </div>
          <div class="Family-modal-container">
            <button
              className="w-[100%] h-[33px] rounded-[32px] border border-[#017C57] text-[#017C57] text-[14px] font-[400] bg-transparent flex items-center justify-center gap-[3px]"
              type="button"
            >
              Past & Family History
              <LaunchOutlinedIcon style={{ height: "14px" }} />
            </button>
          </div>
        </div>
        <div className="grid grid-cols-3 gap-8">
          <label className="label1">
            <div className="text-content">
              Primary Diganosis <span className="required">*</span>
            </div>
            <select className="w-[100%] h-[40px] gap-[8px] border border-[#E4E4E4] rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-500">
              <option value="" className="text-[#797979]">
                Enter Details
              </option>
            </select>
          </label>
          <label className="label1">
            <div className="text-content">
              Secondary Diganosis <span className="required">*</span>
            </div>
            <select className="w-[100%] h-[40px] gap-[8px] border border-[#E4E4E4] rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-500">
              <option value="" className="text-[#797979]">
                Enter Details
              </option>
            </select>
          </label>
          <label className="label1">
            <div className="text-content">
              Admission Type <span className="required">*</span>
            </div>
            <select className="w-[100%] h-[40px] gap-[8px] border border-[#E4E4E4] rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-500">
              <option value="" className="text-[#797979]">
                Enter Details
              </option>
            </select>
          </label>
          <label className="label1">
            <div className="text-content">
              Admissin Date <span className="required">*</span>
            </div>
            <input
              name="admissionDate"
              placeholder="Admission Date"
              type="date"
              max="2028-12-06"
              min="2024-11-16"
              class="input-field"
            />
          </label>
          <label className="label1">
            <div className="text-content">
              Medico Legal Case If Any <span className="required">*</span>
            </div>
            <div className="flex items-center gap-4 mt-2">
              <label className="flex items-center gap-2">
                <input type="radio" value="YES" />
                Yes
              </label>
              <label className="flex items-center gap-2">
                <input type="radio" value="NO" />
                No
              </label>
            </div>
          </label>

          <label className="label1">
            <div className="text-content">
              FIR/MLC <span className="required">*</span>
            </div>
            <input
              type="text"
              placeholder="Enter your full name"
              className="w-[100%] h-[40px] gap-[8px] border border-[#E4E4E4] rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </label>
          <label className="label1">
            <div className="text-content">
              Police Station Name <span className="required">*</span>
            </div>
            <select className="w-[100%] h-[40px] gap-[8px] border border-[#E4E4E4] rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-500">
              <option value="" className="text-[#797979]">
                Enter Details
              </option>
            </select>
          </label>
          <label className="label1">
            <div className="text-content">
              Package Cost <span className="required">*</span>
            </div>
            <input
              type="text"
              placeholder="Package Cost"
              className="w-[100%] h-[40px] gap-[8px] border border-[#E4E4E4] rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </label>
        </div>
      </div>
      <div className="w-full rounded-[16px] border border-gray-300 bg-white p-[16px] pb-[32px] mt-4">
        <div className="btn-container flex justify-start">
          <select
            name=""
            className="w-[167px] h-[34px] rounded-full border border-[#017c57] pl-4 text-[#017c57] text-sm font-semibold bg-transparent flex items-center justify-center gap-1"
          >
            <option value="">SPECIALITY ID</option>
          </select>
          <select
            name=""
            className="w-[167px] h-[34px] rounded-full border border-[#017c57] pl-4 text-[#017c57] text-sm font-semibold bg-transparent flex items-center justify-center gap-1"
          >
            <option value="">PROCEDUREID</option>
          </select>
          <select
            name=""
            className="w-[167px] h-[34px] rounded-full border border-[#017c57] pl-4 text-[#017c57] text-sm font-semibold bg-transparent flex items-center justify-center gap-1"
          >
            <option value="">PROCEDURENAME</option>
          </select>
          <select
            name=""
            className="w-[167px] h-[34px] rounded-full border border-[#017c57] pl-4 text-[#017c57] text-sm font-semibold bg-transparent flex items-center justify-center gap-1"
          >
            <option value="">IMPLANTS</option>
          </select>
          <select
            name=""
            className="w-[167px] h-[34px] rounded-full border border-[#017c57] pl-4 text-[#017c57] text-sm font-semibold bg-transparent flex items-center justify-center gap-1"
          >
            <option value="">STRATIFICATION</option>
          </select>
          <div className="parameter-modal-container">
            <button className="filled-btn" style={{ width: "172px" }}>
              Selected Documents
            </button>
          </div>
        </div>
        <hr style={{ border: "1px solid #DEDEDE", marginTop: "20px" }} />
        <div class="hidden xl:flex text-[#191919] font-semibold mb-8">
          <div class="w-[5%]  ">Sr.No.</div>
          <div class="w-[30%] "></div>
          <div class="w-[10%] ">Case Stage</div>
          <div class="w-[50%]  text-center">Document Name</div>
          <div class="w-[5%] ">Action</div>
        </div>
        <div class=" flex flex-col xl:flex-row gap-4 xl:gap-0 justify-between text-2xl font-semibold text-[#191919]">
          Admission Documents
          <div class="map-modal-container">
            <button class="outline-btn">
              <span style={{ marginRight: "10px" }}>
                <img src={plusIcon} alt="plus-icon" />
              </span>
              MAP ADMISSION DOCUMENT
            </button>
          </div>
        </div>
        <div>
          <div>
            <div className="font-semibold mb-2">MG033A</div>
            <div className="block text-[#797979] xl:flex xl:flex-wrap py-1 xl:py-0 px-4 mb-2">
              <div className="xl:w-[5%] text-xl flex items-center">1.</div>
              <div className="xl:w-[30%]">
                <div className="flex items-center gap-2">
                  <div></div>
                  <div
                    className="flex items-center gap-4"
                    style={{ fontSize: "16px", height: "56px" }}
                  >
                    <div style={{ fontSize: "12px" }}>Time Remaining :</div>
                    <span style={{ color: "rgb(207, 54, 54)" }}>
                      Time's up!
                    </span>
                    <img
                      src="/static/media/editIcon.32766747a98c51ddb8b0fe4af9e29d22.svg"
                      alt="edit-icon"
                    />
                  </div>
                </div>
              </div>
              <div className="xl:w-[10%]"></div>
              <div className="xl:w-[50%] xl:text-xl flex items-center justify-center text-center my-2 xl:my-0">
                USG Whole Abdomen With Report and Film
              </div>
              <div className="xl:w-[5%] flex items-center justify-center">
                <svg
                  className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-f755p0"
                  focusable="false"
                  aria-hidden="true"
                  viewBox="0 0 24 24"
                  data-testid="DeleteOutlinedIcon"
                >
                  <path d="M16 9v10H8V9zm-1.5-6h-5l-1 1H5v2h14V4h-3.5zM18 7H6v12c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2z"></path>
                </svg>
                <div className="text-sm xl:hidden text-[#FE6234] font-semibold tracking-[1.25px]">
                  DELETE
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InterCase;
