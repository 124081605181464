import { useState, useEffect } from "react";
import { CaseServices } from "../../../services/CaseServices";
import DocumentIcon from "../../../assets/documentIcon.svg";
import DownloadIcon from "../../../assets/downloadIcon.svg";
import DownloadIconGreen from "../../../assets/downloadIconGreen.svg";
import { AuthServices } from "../../../services/AuthServices";
import downloadPdf from "../../../utils/downloadPdfUtil";
import { logOut } from "../../../config/user";
import { CircularProgress } from "@mui/material";
import ReviewDocumentModel from "../updatedocuments/reviewDocumentModal";
const DownloadDocuments = ({ caseId }) => {
  const [mappedDocuments, setMappedDocuments] = useState(null);
  const [documentDownloading, setDocumentDownloading] = useState(null);
  const [documentSubDownloading, setDocumentSubDownloading] = useState(null);
  const [percentage, setPercentage] = useState(0);
  const [netSpeed, setNetSpeed] = useState(0);
  const generatePDF = async (urlData, documentName) => {
    const imagesUrl = urlData.map((url) => ({
      image: url.documentUrl,
      compressionPossible: true,
    }));

    if (imagesUrl.length > 0) {
      try {
        let response = await AuthServices.loadImagesBase64(
          {
            images: imagesUrl,
          },
          setPercentage,
          setNetSpeed
        );

        downloadPdf(response.data, documentName);
        // const report = new JsPDF("portrait", "pt", "a4");
        // const margin = 0;
        // const imgWidth = 595;
        // const imgHeight = 845;

        // let yPosition = 0;
        // if (response?.data) {
        //   response.data.forEach((img, idx) => {
        //     if (idx !== 0) {
        //       report.addPage();
        //     }
        //     report.addImage(
        //       img,
        //       "JPEG",
        //       yPosition,
        //       margin,
        //       imgWidth,
        //       imgHeight
        //     );

        //     // yPosition += imgHeight + margin; // Update Y position for the next image
        //   });
        // }
        // report.save(`${documentName}`);
        // setPdfCount(pdfCount + 1);
      } catch (error) {
        window.alert(error);
      }
    } else {
      window.alert("No Document Uploaded Yet");
    }

    setDocumentDownloading(null);
    setDocumentSubDownloading(null);
  };
  const handleMultipleDownloads = (documentsData) => {
    for (let doc of documentsData) {
      generatePDF(doc?.documenturlmasters, doc?.documentName);
    }
  };

  const handleSelection = (key, idx) => {
    const modifiedData = mappedDocuments?.map((doc) => {
      if (key === doc.key) {
        doc.value[idx].isSelected = !doc.value[idx].isSelected;
      }
      return doc;
    });
    setMappedDocuments(modifiedData);
  };

  const handleMergeClick = (value, key) => {
    let urlData = [];
    for (let doc of value) {
      if (doc.isSelected) {
        urlData = [...urlData, ...doc.documenturlmasters];
      }
    }
    if (urlData.length > 0) {
      generatePDF(urlData, `merged ${key}`);
    } else {
      window.alert("Please select atleat one document");
    }
  };
  const getMappedDocuments = async () => {
    try {
      const response = await CaseServices.getMappedDocuments(caseId);
      let data = [];
      for (let docType in response.data.mappedDocuments) {
        let keyData = response.data.mappedDocuments[docType];
        keyData = keyData.map((doc) => {
          return { ...doc, isSelected: false };
        });
        data.push({
          key: docType,
          value: keyData,
        });
      }
      setMappedDocuments(data);
    } catch (error) {
      if (error?.response?.status === 401) {
        logOut();
      } else {
        window.alert(error);
      }
    }
  };
  useEffect(() => {
    getMappedDocuments();
  }, []);

  return (
    <div>
      {mappedDocuments?.map((document, idx) => {
        return (
          <div key={idx} className="mt-8">
            <div className="flex justify-between mb-2">
              <div className="text-xl font-semibold">{document.key}</div>
              {document?.value.length > 0 &&
                (documentDownloading === idx ? (
                  <CircularProgress />
                ) : (
                  <div className="flex gap-2">
                    <div
                      className="flex gap-1 items-center text-white text-xs 2xl:text-sm bg-[#017C57] rounded-full py-2 px-4 cursor-pointer"
                      onClick={() => {
                        setDocumentDownloading(idx);
                        handleMultipleDownloads(document?.value);
                      }}
                    >
                      <img src={DownloadIcon} alt="download-icon" />
                      {`All ${document.key}`}
                    </div>
                    <div
                      className="text-xs 2xl:text-sm text-white bg-[#017C57] rounded-full py-2 px-4 cursor-pointer"
                      onClick={() => {
                        setDocumentDownloading(idx);
                        handleMergeClick(document?.value, document?.key);
                      }}
                    >{`Merge ${document?.key}`}</div>
                  </div>
                ))}
            </div>

            <div
              style={{ borderBottom: "1px solid #E4E4E4", marginBlock: "20px" }}
            />
            {document?.value.length > 0 ? (
              <div className="grid xl:grid-cols-3 2xl:grid-cols-4 gap-5">
                {document?.value?.map((doc, index) => {
                  return (
                    <div
                      key={index}
                      style={{ border: "1.5px solid #E4E4E4" }}
                      className="h-[170px] flex flex-col justify-between rounded-[16px] p-6 hovernow"
                    >
                      <div className="text-[#191919] font-semibold flex justify-between items-center">
                        <div className="overflow-hidden flex gap-2">
                          <img src={DocumentIcon} alt="document-icon" />
                          <div
                            className="overflow-hidden whitespace-nowrap text-ellipsis w-full"
                            title={doc.documentName}
                          >
                            {doc.documentName}
                          </div>
                        </div>
                        {doc.documenturlmasters.length > 0 && (
                          <div>
                            <input
                              type="checkbox"
                              className="w-5 h-5"
                              onClick={() =>
                                handleSelection(document.key, index)
                              }
                            />
                          </div>
                        )}
                      </div>

                      {documentDownloading === idx ||
                      documentSubDownloading === doc.id ? (
                        <CircularProgress />
                      ) : (
                        <div className="flex justify-between">
                          <div
                            style={{ border: "1px solid #017C57" }}
                            className="w-fit flex items-center gap-1 text-sm text-[#017C57] font-semibold bg-[white] rounded-full cursor-pointer px-4 py-2 border-[5px] z-10 "
                            onClick={() => {
                              setDocumentSubDownloading(doc?.id);
                              generatePDF(
                                doc?.documenturlmasters,
                                doc?.documentName
                              );
                            }}
                          >
                            <img
                              src={DownloadIconGreen}
                              alt="download-icon"
                              height={14}
                              width={14}
                            />
                            Download
                          </div>
                          {doc.documenturlmasters.length > 0 && (
                            <ReviewDocumentModel
                              documentDetails={doc}
                              type="view"
                            />
                          )}
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className="text-center text-xl font-semibold pb-6">
                No Documents Mapped
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default DownloadDocuments;
