import React, { useState, useEffect } from "react";

const IncreamentalTimer = ({ colorData, createdAt }) => {
  const [time, setTime] = useState(0); // Initial time in seconds
  const [currentIndex, setCurrentIndex] = useState(-1);
  useEffect(() => {
    let date1 = new Date(createdAt);
    let date2 = new Date(); // current date

    // Calculate the difference in milliseconds
    let differenceInMilliseconds = date2 - date1;
    // let minutes = Math.floor(differenceInMilliseconds / (1000 * 60));
    const index = colorData?.findIndex(
      (stage) =>
        stage.endTime === "" ||
        stage.endTime === null ||
        (differenceInMilliseconds >= Number(stage.startTime) * 60 * 1000 &&
          differenceInMilliseconds <= Number(stage.endTime) * 60 * 1000)
    );

    if (index !== -1 && colorData && colorData.length > 0) {
      setCurrentIndex(index);
      setTime(differenceInMilliseconds);
    }
  }, []);
  useEffect(() => {
    // Set up an interval to decrement the time every second
    const interval = setInterval(() => {
      setTime((prevTime) => prevTime + 100);
    }, 100);
    // If all time limits are completed, stop the countdown
    if (currentIndex >= colorData?.length - 1 || currentIndex === -1)
      return () => clearInterval(interval);

    // If the timeLeft is 0, move to the next time limit
    if (
      colorData &&
      colorData.length > 0 &&
      time > Number(colorData[currentIndex].endTime) * 60 * 1000 &&
      currentIndex < colorData?.length - 1
    ) {
      setCurrentIndex((prevIndex) => prevIndex + 1);
    }

    // Cleanup the interval when the component unmounts or timeLeft changes
    if (interval) {
      return () => clearInterval(interval);
    }
  }, [time, currentIndex]);

  const days = Math.floor(time / (24 * 60 * 60 * 1000)); // 1 day = 24 * 60 * 60 * 1000 milliseconds
  const hours = Math.floor((time % (24 * 60 * 60 * 1000)) / (60 * 60 * 1000)); // Remaining hours
  const minutes = Math.floor((time % (60 * 60 * 1000)) / (60 * 1000)); // Remaining minutes
  const seconds = Math.floor((time % (60 * 1000)) / 1000); // Remaining seconds

  return (
    <div>
      {currentIndex !== -1 && (
        <p
          style={{
            color: colorData[currentIndex].textColor.slice(0, 7),
            backgroundColor: colorData[currentIndex].bgColor.slice(0, 7),
            fontWeight: "600",
            fontSize: "14px",
            padding: "4px",
            borderRadius: "4px",
          }}
        >
          CST:
          {` ${days > 0 ? days + "D " : ""}${hours > 0 ? hours + "H " : ""}${
            minutes > 0 ? minutes + "M " : ""
          } ${!days && !hours && !minutes ? seconds + "S" : ""}`}
        </p>
      )}
    </div>
  );
};

export default IncreamentalTimer;
