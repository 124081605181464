import React, { useState, useEffect } from "react";

const SequentialCountdownTimer = ({ colorData, createdAt }) => {
  const [timeLeft, setTimeLeft] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(-1);

  useEffect(() => {
    // Set up an interval to decrement the time every second
    const interval = setInterval(() => {
      setTimeLeft((prevTime) => prevTime - 100);
    }, 100);
    // If all time limits are completed, stop the countdown
    if (currentIndex >= colorData?.length - 1 || currentIndex === -1)
      return () => clearInterval(interval);

    // If the timeLeft is 0, move to the next time limit
    if (
      timeLeft <= 0 &&
      colorData &&
      currentIndex < colorData?.length - 1 &&
      colorData.length > 0
    ) {
      setCurrentIndex((prevIndex) => prevIndex + 1);
      setTimeLeft(
        (Number(colorData[currentIndex + 1]?.endTime) -
          Number(colorData[currentIndex + 1]?.startTime)) *
          60 *
          1000
      );
    }

    // Cleanup the interval when the component unmounts or timeLeft changes
    if (interval) {
      return () => clearInterval(interval);
    }
  }, [timeLeft, currentIndex]);

  useEffect(() => {
    let date1 = new Date(createdAt);
    let date2 = new Date(); // current date

    // Calculate the difference in milliseconds
    let differenceInMilliseconds = date2 - date1;
    // let minutes = Math.floor(differenceInMilliseconds / (1000 * 60));
    const index = colorData?.findIndex(
      (stage) =>
        differenceInMilliseconds >= Number(stage.startTime) * 60 * 1000 &&
        differenceInMilliseconds <= Number(stage.endTime) * 60 * 1000
    );

    if (index !== -1 && colorData && colorData.length > 0) {
      setCurrentIndex(index);
      setTimeLeft(
        Number(colorData[index]?.endTime) * 60 * 1000 - differenceInMilliseconds
      );
    }
  }, []);
  const formatTime = (milliseconds) => {
    let seconds = milliseconds / 1000;
    // const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(seconds / (60 * 60));
    const remainingMinutes = Math.floor((seconds / 60) % 60);
    const remainingSeconds = Math.floor(seconds % 60);
    // const remainingMilliseconds = Math.floor((seconds % 1) * 1000);

    return `${hours < 10 ? "0" + hours : hours}:${
      remainingMinutes < 10 ? "0" + remainingMinutes : remainingMinutes
    }:${remainingSeconds < 10 ? "0" + remainingSeconds : remainingSeconds}`;
  };

  // console.log("currentINdex:", currentIndex);
  return (
    <div>
      {currentIndex !== -1 && currentIndex < 3 && (
        <p
          style={{
            color: colorData[currentIndex].textColor.slice(0, 7),
            backgroundColor: colorData[currentIndex].bgColor.slice(0, 7),
            paddingBlock: "4px",
            paddingInline: "16px",
            textAlign: "center",
            fontSize: "12px",
            borderRadius: "16px",
            width: "180px",
          }}
          title="Time in HH:MM:SS format"
        >
          Current Timer: {formatTime(timeLeft)}
        </p>
      )}
    </div>
  );
};

export default SequentialCountdownTimer;
