import { useState, useEffect } from "react";
import WidgetsOutlinedIcon from "@mui/icons-material/WidgetsOutlined";
import Select from "react-select";
import { CircularProgress } from "@mui/material";
import { NotificationServices } from "../services/NotificationServices";
import { InterUserMangementServices } from "../services/InternalUserMangementServices";
import { logOut } from "../config/user";
import "./InternalUserManagement.scss";

const InternalUserManagement = () => {
  const [isSubmitting, setSubmitting] = useState(false);
  const [roleList, setRoleList] = useState([]);
  const [roleName, setRoleName] = useState(null);
  const [userType, setUserType] = useState(null);
  const [selectedResponsibilities, setSelectedResponsibilities] =
    useState(null);
  const [email, setEmail] = useState(null);
  const [fullName, setFullName] = useState(null);
  const [password, setPassword] = useState(null);
  const [phone, setPhone] = useState(null);
  const [countryCode, setCountryCode] = useState(null);
  const [statusReponsibilityList, setStatusResponsibilityList] = useState(null);
  const userList = [
    { value: "DOCTOR", label: "Doctor" },
    { value: "COORDINATOR", label: "Coordinator" },
  ];
  const getRoleList = async () => {
    try {
      const response = await NotificationServices.getRoleList();
      setRoleList(response?.data?.allRoles || []);
    } catch (error) {
      window.alert(
        error?.response?.data?.message || "Failed to fetch role list."
      );
    }
  };
  const getResponsibilityList = async () => {
    try {
      const response =
        await InterUserMangementServices.getUserResponsibilityList(20, 1);
      setStatusResponsibilityList(response?.data?.responsibilities);
    } catch (error) {
      window.alert(
        error?.response?.data?.message || "Failed to fetch role list."
      );
    }
  };

  const validateForm = () => {
    if (!fullName) {
      window.alert("Please enter your full name.");
      return false;
    }
    if (!roleName) {
      window.alert("Please select a role.");
      return false;
    }
    if (!email || !/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email)) {
      window.alert("Please enter a valid email address.");
      return false;
    }
    if (!password) {
      window.alert("Please enter a password.");
      return false;
    }
    if (!phone || !/^\d+$/.test(phone)) {
      window.alert("Please enter a valid phone number.");
      return false;
    }
    // if (!countryCode || !/^\+\d{1,3}$/.test(countryCode)) {
    //   window.alert("Please enter a valid country code (e.g., +1).");
    //   return false;
    // }
    return true;
  };

  const handleSubmit = async () => {
    if (!validateForm()) return;

    const data = {
      fullName,
      roleName,
      email,
      password,
      phone,
      position: userType,
      countryCode: "+91",
    };
    try {
      setSubmitting(true);
      const res = await InterUserMangementServices.newInterUser(data);
      window.alert("User created successfully");
    } catch (error) {
      if (error?.response?.status === 401) {
        logOut();
      } else {
        console.error("Error from server:", error?.response?.data);
        window.alert(
          error?.response?.data?.message ||
            "An error occurred while creating the user."
        );
      }
    } finally {
      setSubmitting(false);
    }
  };

  useEffect(() => {
    getRoleList();
    getResponsibilityList();
  }, []);
  return (
    <div className="manage-box">
      <span className="path_line">
        <WidgetsOutlinedIcon style={{ height: "24px" }} /> &nbsp; &nbsp;/ &nbsp;
        &nbsp; Dashboard &nbsp; &nbsp;/ &nbsp; &nbsp; Internal User Management
      </span>
      <div className="heading">Internal User Management</div>
      <hr
        style={{ width: "100%", border: "1px solid rgba(228, 228, 228, 1)" }}
      />
      <div
        style={{ border: "1px solid #e4e4e4", marginTop: "24px" }}
        className="rounded-[16px] p-5"
      >
        <div>
          <div className="flex justify-between flex-wrap gap-y-8">
            <div className="w-[48%]">
              <div className="mb-2">Full Name</div>
              <div
                style={{ border: "1px solid #E4E4E4" }}
                className="w-full py-2 px-4 rounded-[4px]"
              >
                <input
                  type="text"
                  placeholder="Please Enter Your Full Name"
                  className="w-full border-none outline-none"
                  onChange={(e) => setFullName(e.target.value)}
                />
              </div>
            </div>
            <div className="w-[48%]">
              <div className="mb-2">Role Name</div>
              <Select
                options={roleList}
                getOptionLabel={(option) => option.roleName}
                getOptionValue={(option) => option.roleName}
                onChange={(item) => setRoleName(item?.roleName)}
                value={
                  roleList.find((option) => option.roleName === roleName) ||
                  null
                }
                placeholder="Select Role Name"
              />
            </div>
            <div className="w-[48%]">
              <div className="mb-2">User Type</div>
              <Select
                options={userList}
                getOptionLabel={(option) => option.label}
                getOptionValue={(option) => option.value}
                onChange={(item) => setUserType(item?.value)}
                value={
                  userList.find((option) => option.value === userType) || null
                }
                placeholder="Select User Type"
              />
            </div>
            <div className="w-[48%]">
              {" "}
              <div className="mb-2">Mobile number</div>
              <div
                style={{ border: "1px solid #E4E4E4" }}
                className="w-full py-2 px-4 rounded-[4px]"
              >
                <input
                  type="text"
                  maxLength={10}
                  placeholder="Please Enter Your mobile number"
                  className="w-full border-none outline-none"
                  onChange={(e) => setPhone(e.target.value)}
                />
              </div>
            </div>
            <div className="w-[48%]">
              <div className="mb-2">Status Responsibility</div>

              <Select
                options={statusReponsibilityList}
                getOptionLabel={(option) => option.commandCenterStageStatus}
                getOptionValue={(option) => option.commandCenterStageStatus}
                onChange={(items) => setSelectedResponsibilities(items)}
                // value={
                //   roleList.find((option) => option.roleName === roleName) ||
                //   null
                // }
                isMulti
                placeholder="Select User Responsibilities"
              />
            </div>
            <div className="w-[48%]">
              <div className="mb-2">Email</div>
              <div
                style={{ border: "1px solid #E4E4E4" }}
                className="w-full py-2 px-4 rounded-[4px]"
              >
                <input
                  type="email"
                  placeholder="Please Enter Your Email"
                  className="w-full border-none outline-none"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </div>
            <div className="w-[48%]">
              <div className="mb-2">Password</div>
              <div
                style={{ border: "1px solid #E4E4E4" }}
                className="w-full py-2 px-4 rounded-[4px]"
              >
                <input
                  type="password"
                  placeholder="Please Enter Password"
                  className="w-full border-none outline-none"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-center items-center mt-12">
          {isSubmitting ? (
            <CircularProgress />
          ) : (
            <div
              style={{ border: "1px solid #017C57" }}
              className="text-sm text-[#017C57] font-semibold px-10 py-2 rounded-full cursor-pointer"
              onClick={handleSubmit}
            >
              SUBMIT
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default InternalUserManagement;
