import React, { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DocumentDetailBox from "./documentDetailBox";

const EnhancementDocuments = ({ ENHANCEMENTDOCUMENTS, latestEnhancement }) => {
  const enhancementDocuments = ENHANCEMENTDOCUMENTS;
  const [openAccordion, setOpenAccordion] = useState(0);
  return (
    <div>
      {enhancementDocuments?.map((enhanceDoc, idx) => {
        return (
          <div key={idx} className="mt-3">
            <Accordion
              expanded={idx === openAccordion ? true : false}
              className="accordionbox"
              style={{
                borderRadius: "16px",
                background: idx === 0 ? "#CDFAF4" : "#f5f5f5",
              }}
              onClick={() => setOpenAccordion(idx)}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    className={`text-xl ${
                      idx === 0 ? "text-[#017C57]" : "text-[#191919]"
                    } font-semibold `}
                  >
                    {enhanceDoc.key}
                  </div>
                  {idx === 0 && (
                    <div className="flex gap-5 ml-8 text-[#191919]  font-bold ">
                      <div>
                        From Date:{" "}
                        <span className="font-medium ">
                          {new Date(latestEnhancement[0]?.details?.fromDate)
                            .toDateString()
                            .slice(3)}
                        </span>
                      </div>
                      <div>
                        To Date:{" "}
                        <span className="font-medium ">
                          {new Date(latestEnhancement[0]?.details?.toDate)
                            .toDateString()
                            .slice(3)}
                        </span>
                      </div>
                      <div>
                        Number of Days:{" "}
                        <span className="font-medium ">
                          {latestEnhancement[0]?.details?.enhancementDays}
                        </span>
                      </div>
                      <div>
                        Ward Type:{" "}
                        <span className="font-medium ">
                          {latestEnhancement[0]?.details?.wardType}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <div className="flex flex-wrap gap-[2%]">
                  {enhanceDoc?.value?.map((document, idx) => {
                    return (
                      <DocumentDetailBox key={idx} docDetails={document} />
                    );
                  })}
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
        );
      })}
    </div>
  );
};

export default EnhancementDocuments;
