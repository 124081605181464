import { useEffect, useState } from "react";
import { Route, BrowserRouter, Routes, Navigate } from "react-router-dom";
import { Outlet } from "react-router-dom";
import DashBoard from "./components/dashboard/dashboard";
import { getUser } from "./components/config/user";
import DrawerAppBar from "./components/appbar/drawerappbar";
import Homepage from "./components/homepage/homepage";
import AddCasesDashboard from "./components/dashboard/cases/addcases/AddCasesDashboard";
import InternalUserManagement from "./components/internalUserManagement/InternalUserManagement";
import InternalUsers from "./components/internalUserManagement/InternalUsers";
import Sidebarorg from "./components/appbar/Sidebar";
import Subscriptionplan from "./components/Subscription/Subscriptionplan";
import Forgotpassword from "./components/forgotpassword/Forgotpassword";
import Teanmemberdashboard from "./components/teammemberdashboard/Teammemberdashboard";
import EditCasesDashboard from "./components/dashboard/cases/editCases/EditCasesDashboard";
import InitiateAdmission from "./components/hospitaldashboard/InitiateAdmission/InitiateAdmission";
import MapDocumentCaseList from "./components/dashboard/cases/mapdocuments/MapDocumentCaseList";
import SubscriptionBox from "./components/Subscription/Subscriptionplan";
import AddSubadmin from "./components/dashboard/Admin/Subadmin/AddSubadmin";
import EditSubadmin from "./components/dashboard/Admin/Subadmin/EditSubadmin";
import Subadminlist from "./components/dashboard/Admin/Subadmin/Subadminlist";
import ManageRpa from "./components/managerpa/ManageRpa";
import Hospitaldashboard from "./components/hospitaldashboard/Hospitaldashboard";
import DirectUploadDocUi from "./components/dashboard/cases/casedetails/DirectUploadDocUi";
import Hospitallist from "./components/hospitallist/Hospitallist";
import AddHospital from "./components/addhospital/Addhospital";
import Managequery from "./components/managequery/Managequery";
import ApproveHospital from "./components/approvehospital/ApproveHospital";
import Report from "./components/report/Report";
import NotificationsStructure from "./components/notificationsStructure/NotificationsStructure";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AddDoctor from "./components/adddoctor/AddDoctor";
import InterCase from "./components/intercase/InterCase";
import ManageProcedure from "./components/dashboard/cases/manageProcedure/ManageProcedure";
import NotificationSound from "./components/assets/notificationSound.wav";
import { AuthServices } from "./components/services/AuthServices";
import { useSelector, useDispatch } from "react-redux";
import {
  requestForToken,
  onMessageListener,
} from "./components/services/Firebase";
import { updateCaseId } from "./components/redux/caseDetailsSlice";
import ManualNotification from "./components/dashboard/manualNotification/ManualNotification";
import EditDocuments from "./components/editDocuments/EditDocuments";
import ActiveCasesDashboardV2 from "./components/dashboard/cases/activeCasesDashboardV2";
import AllCaseRecording from "./components/AllCaseRecording/AllCaseRecording";
import ProcessedQueryCases from "./components/dashboard/cases/processedQueryCases/ProcessedQueryCases";
import ManageApplication from "./components/managerpa/ManageApplication";

const PublicRoute = ({ ...props }) => {
  let user = getUser();
  return user?.session?.token ? (
    <Navigate replace to="/dashboard/ActiveCases" />
  ) : (
    <>
      <ToastContainer />
      <DrawerAppBar />
      <Outlet />
      <footer />
    </>
  );
};
const PublicRoute1 = ({ ...props }) => {
  return (
    <>
      <Outlet />
    </>
  );
};

const PrivateRoute1 = ({ ...props }) => {
  let user = getUser();
  return !user?.session?.token ? (
    <Navigate replace to="/" />
  ) : (
    <>
      <ToastContainer />
      <DrawerAppBar
        sidebar={<Sidebarorg />}
        loggedIn={true}
        Component={<Outlet />}
      />
    </>
  );
};

function App() {
  const [token, setToken] = useState(null);
  const [notification, setNotification] = useState({ title: "", body: "" });
  const currentRole = useSelector((state) => state.currentRole.value);
  const caseId = useSelector((state) => state.caseDetails.caseId);
  const dispatch = useDispatch();
  const notificationSound = new Audio(NotificationSound);
  const updateFirebaseToken = async (fcm) => {
    const data = {
      notificationFirebaseId: fcm,
    };
    try {
      await AuthServices.updateFirebaseId(data);
    } catch (error) {}
  };
  const getRequestToken = async () => {
    try {
      const token = await requestForToken();
      setToken(token);
    } catch (error) {
      window.alert(error);
    }
  };

  const handleNotificationClick = (caseId) => {
    // navigate(`/Dashboard/EditCases/?caseId=${caseId}`);
  };
  onMessageListener()
    .then((payload) => {
      // console.log("payload:", payload);
      if (payload.data.notificationStatus.includes("negative")) {
        toast.error(payload.data.title, {
          style: {
            color: payload.data.color,
          },
          onClick: () => {
            handleNotificationClick(payload.data.caseId);
          },
        });
      } else if (payload.data.notificationStatus.includes("positive")) {
        toast.success(payload.data.title, {
          style: {
            color: payload.data.color,
          },
          onClick: () => {
            handleNotificationClick(payload.data.caseId);
          },
        });
      } else {
        toast.info(payload.data.title, {
          style: {
            color: payload.data.color,
          },
          onClick: () => {
            handleNotificationClick(payload.data.caseId);
          },
        });
      }
      notificationSound.play();
      if (payload?.data?.caseId) {
        dispatch(updateCaseId(payload.data.caseId));
      }
      setNotification({
        title: payload.data.title,
        body: payload.data.title,
      });
    })
    .catch((error) => {
      console.error(error);
    });
  useEffect(() => {
    getRequestToken();
  }, []);
  useEffect(() => {
    if (token) {
      updateFirebaseToken(token);
    }
  }, [token]);

  // useEffect(() => {
  //   if (messageNotification) {
  //
  //     toast.info(messageNotification.body);
  //   }
  // }, [messageNotification]);
  return (
    <div className="main_container">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<PublicRoute />}>
            <Route index element={<Homepage />} />
          </Route>

          <Route path="/dashboard" element={<PrivateRoute1 />}>
            {currentRole?.role?.roleName === "Hospital" ? (
              <Route index element={<Hospitaldashboard />} />
            ) : (
              <Route index element={<DashBoard />} />
            )}
            <Route path="/dashboard/addhospital" element={<AddHospital />} />
            <Route path="/dashboard/hospitallist" element={<Hospitallist />} />
            <Route
              path="/dashboard/approvehospital"
              element={<ApproveHospital />}
            />
          </Route>
          <Route path="/dashboard/ActiveCases" element={<PrivateRoute1 />}>
            <Route
              path="/dashboard/ActiveCases"
              Component={ActiveCasesDashboardV2}
            />
          </Route>
          <Route
            path="/dashboard/internal-user-management"
            element={<PrivateRoute1 />}
          >
            <Route
              path="/dashboard/internal-user-management"
              Component={InternalUserManagement}
            />
          </Route>
          <Route
            path="/dashboard/internal-user-list"
            element={<PrivateRoute1 />}
          >
            <Route
              path="/dashboard/internal-user-list"
              Component={InternalUsers}
            />
          </Route>
          {/* <Route path="/dashboard/ActiveCasesV2" element={<PrivateRoute1 />}>
  <Route path="/dashboard/ActiveCasesV2" element={<ActiveCasesDashboardV2 />} />
</Route> */}

          <Route path="/dashboard/manage-rpa" element={<PrivateRoute1 />}>
            <Route path="/dashboard/manage-rpa" element={<ManageRpa />} />
          </Route>

          <Route path="/dashboard/manage-query" element={<PrivateRoute1 />}>
            <Route path="/dashboard/manage-query" element={<Managequery />} />
          </Route>

          <Route
            path="/dashboard/manage-applicationid"
            element={<PrivateRoute1 />}
          >
            <Route
              path="/dashboard/manage-applicationid"
              element={<ManageApplication />}
            />
          </Route>
          <Route path="/dashboard/inter-case" element={<PrivateRoute1 />}>
            <Route path="/dashboard/inter-case" element={<InterCase />} />
          </Route>

          <Route path="/Dashboard/ManageCases" element={<PrivateRoute1 />}>
            <Route
              path="/Dashboard/ManageCases"
              Component={InitiateAdmission}
            />
          </Route>
          <Route
            path="/Dashboard/processed-query-cases"
            element={<PrivateRoute1 />}
          >
            <Route
              path="/Dashboard/processed-query-cases"
              Component={ProcessedQueryCases}
            />
          </Route>
          <Route path="/addcases" element={<PrivateRoute1 />}>
            <Route index element={<AddCasesDashboard />} />
          </Route>
          <Route path="/Subscriptionplan" element={<PrivateRoute1 />}>
            <Route index element={<Subscriptionplan />} />
          </Route>
          <Route path="/forgotpassword" element={<PublicRoute />}>
            <Route index element={<Forgotpassword />} />
          </Route>
          <Route path="/dashboard/adddoctor" element={<PrivateRoute1 />}>
            <Route index element={<AddDoctor />} />
          </Route>
          <Route path="/dashboard/EditDocuments" element={<PrivateRoute1 />}>
            <Route index element={<EditDocuments />} />
          </Route>
          <Route path="/inactivestaff/homepage" element={<PublicRoute />}>
            <Route index element={<Homepage />} />
          </Route>
          <Route path="/teammemberdashboard" element={<PublicRoute />}>
            <Route index element={<Teanmemberdashboard />} />
          </Route>
          <Route path="/hospitaldashboard" element={<PrivateRoute1 />}>
            <Route index element={<Hospitaldashboard />} />
          </Route>
          <Route path="/dashboard/mapdocuments" element={<PrivateRoute1 />}>
            <Route index element={<MapDocumentCaseList />} />
          </Route>
          <Route path="/dashboard/manageprocedure" element={<PrivateRoute1 />}>
            <Route index element={<ManageProcedure />} />
          </Route>
          <Route path="/dashboard/subscription" element={<PrivateRoute1 />}>
            <Route index element={<SubscriptionBox />} />
          </Route>
          <Route path="/addstaff" element={<PrivateRoute1 />}>
            <Route index element={<AddSubadmin />} />
          </Route>
          {/* <Route path="/dashboard/updated-features" element={<PrivateRoute1 />}>
            <Route index element={<UpdatedFeatures />} />
          </Route>

          </Route> */}

          <Route path="/dashboard/editsubadmin" element={<PrivateRoute1 />}>
            <Route index element={<EditSubadmin />} />
          </Route>
          <Route
            path="/dashboard/all-case-recording"
            element={<PrivateRoute1 />}
          >
            <Route index element={<AllCaseRecording />} />
          </Route>
          <Route path="/stafflist" element={<PrivateRoute1 />}>
            <Route index element={<Subadminlist />} />
          </Route>
          <Route path="/dashboard/upload-documents" element={<PrivateRoute1 />}>
            <Route index element={<DirectUploadDocUi />} />
          </Route>
          <Route path="/report/daily-case-tracking" element={<PrivateRoute1 />}>
            <Route index element={<Report />} />
          </Route>
          <Route path="/Dashboard/EditCases" element={<PrivateRoute1 />}>
            <Route index element={<EditCasesDashboard />} />
          </Route>
          <Route
            path="/dashboard/manualNotification"
            element={<PrivateRoute1 />}
          >
            <Route index element={<ManualNotification />} />
          </Route>
          <Route
            path="/Dashboard/notificationsStructure"
            element={<PrivateRoute1 />}
          >
            <Route index element={<NotificationsStructure />} />
          </Route>
          <Route path="/Dashboard/ActiveCases/v2" element={<PublicRoute1 />}>
            <Route index element={<ActiveCasesDashboardV2 />} />
          </Route>
          <Route path="/Dashboard/EditCases/v2" element={<PublicRoute1 />}>
            <Route index element={<EditCasesDashboard />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
