import React, { useState } from "react";
import CloseIcon from "../../../assets/crossIcon.svg";
import DownloadIcon from "../../../assets/downloadIconGreen.svg";

const ImagesGallery = ({ data, setOpen, selectedImage }) => {
  const [currentIndex, setCurrentIndex] = useState(
    selectedImage ? selectedImage : 0
  );
  const handleNextClick = () => {
    if (currentIndex < data.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };
  const handlePrevClick = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  return (
    <div className="fixed top-0 left-0 w-screen h-screen flex items-center justify-center bg-[#017C5780] pt-[94px] z-10">
      <div className="relative bg-white rounded-[16px] w-[90%] xl:w-[70%] h-[95%] p-4 xl:p-8">
        <div className="absolute flex items-center gap-2 top-4 right-4 xl:top-7 xl:right-8 cursor-pointer">
          <a
            href={data[currentIndex]?.documentUrl}
            download
            className="cursor-pointer"
          >
            <img src={DownloadIcon} alt="download-icon" />
          </a>
          <img
            src={CloseIcon}
            height={30}
            width={30}
            alt="close-icon"
            onClick={() => setOpen(false)}
          />
        </div>

        <div className="flex justify-center h-[90%]">
          <div className="h-[100%] w-[80%] flex items-center justify-cente">
            {" "}
            <img
              src={data[currentIndex]?.documentUrl}
              className="object-contain h-full w-full"
              alt="document-img"
            />
          </div>
        </div>
        <div className="flex items-center justify-center gap-2 mt-2">
          <div
            style={{
              border:
                currentIndex === 0 ? "1px solid #e5e5e5" : "1px solid #017C57",
            }}
            className={`text-sm ${
              currentIndex === 0 ? "text-[#e5e5e5]" : "text-[#017C57]"
            } px-4 py-1 rounded-full cursor-pointer`}
            onClick={handlePrevClick}
          >
            Prev
          </div>
          <div className="h-[32px] w-[32px] flex justify-center items-center text-white rounded-full bg-[#017C57]">
            {currentIndex + 1}
          </div>
          <div
            onClick={handleNextClick}
            style={{
              border:
                currentIndex === data.length - 1
                  ? "1px solid #e5e5e5"
                  : "1px solid #017C57",
            }}
            className={`text-sm ${
              currentIndex === data.length - 1
                ? "text-[#e5e5e5]"
                : "text-[#017C57]"
            } px-4 py-1 rounded-full cursor-pointer`}
          >
            Next
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImagesGallery;
