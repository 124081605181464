import React, { useEffect, useState } from "react";
import { Formik, Form, Field, FieldArray } from "formik";
import { number, object, string, date, array } from "yup";
import { CaseServices } from "../../../services/CaseServices";
import AddCaseProcedure from "./AddCaseProcedure";
import { useSelector } from "react-redux";
import "./AddCaseDetails.scss";
import PersonalHistoryModal from "../casedetails/PersonalHistoryModal";
import FamilyHistoryModal from "../casedetails/FamilyHistoryModal";
import GeneralFindingsModal from "../casedetails/GeneralFindingsModal";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import NewReleasesOutlinedIcon from "@mui/icons-material/NewReleasesOutlined";
import CloseIcon from "../../../assets/crossIcon.svg";
import { logOut } from "../../../config/user";
import CaseDetails from "../casedetails/caseDetails";
import { PatientServices } from "../../../services/PatientServices";
import { CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
const AddCaseDetails = ({
  setValue,
  setCaseId,
  patientId,
  functionalityType,
  caseDetails,
  refreshPage,
  hospitalid,
  patientDetails,
  setPatientDetails,
  setPatientId,
}) => {
  const [updated, setUpdated] = useState(false); // to refresh the page
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [referencePendingModal, setReferencePendingModal] = useState(false);
  const [reason, setReason] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const currentRole = useSelector((state) => state.currentRole.value);
  const location = useLocation();
  const subStatusList = location?.state?.subStatusList;
  const addProcedure = (push) => {
    push({
      speciality: "",
      procedureId: "",
      ichiCode: "",
      stratification: "",
      implants: "",
      doctorName: "",
      specialityId: "",
      compositId: "",
    });
  };
  let initialValues;

  {
    functionalityType === "EDIT"
      ? (initialValues = {
          primaryDiagnosis: caseDetails?.primaryDiagnosis,
          secondaryDiagnosis: caseDetails?.secondaryDiagnosis,
          admissionType: caseDetails?.admissionType,
          admissionDate:
            caseDetails?.admissionDate &&
            caseDetails?.admissionDate.split("T")[0],
          fir: caseDetails.fir,
          policeStationName: caseDetails.policeStationName,
          packageCost: caseDetails.packageCost,
          totalPackageCost: caseDetails.totalPackageCost,
          caseProcedures: caseDetails?.caseprocedures,
          pmjayId: caseDetails?.pmjayId,
        })
      : (initialValues = {
          primaryDiagnosis: "",
          secondaryDiagnosis: "",
          admissionType: "",
          admissionDate: "",
          fir: "",
          pmjayId: "",
          policeStationName: "",
          // packageCost: "",
          totalPackageCost: "",
          caseProcedures: [
            {
              speciality: "",
              specialityId: "",
              procedure: "",
              procedureId: "",
              CompositId: "",
              procedureType: "",
              compositId: "",
              ichiCode: "",
              tierCost: "",
              tier: "",
              stratification: "",
              implants: "",
              doctorName: "",
              doctorId: "",
              packageCost: "",
            },
          ],
        });
  }

  const errorValidation = object({
    primaryDiagnosis: string().required("Required Field"),
    // secondaryDiagnosis: string().required("Required Field"),
    admissionType: string().required("Required Field"),
    admissionDate: date().required("Required Field"),
    // fir: string().required("Required Field"),
    // policeStationName: string().required("Required Field"),
    // packageCost: number().required("Required Field"),
    totalPackageCost: number().required("Required Field"),
    caseProcedures: array().of(
      object({
        speciality: string().required("Required Field"),
        procedure: string().required("Required Field"),
        // ichiCode: string().required("Required Field"),
        // stratification: string().required("Required Field"),
        // implants: string().required("Required Field"),
        doctorName: string().required("Required Field"),
        packageCost: number().required("Required Field"),
      })
    ),
  });

  const handleSubmit = async (values) => {
    setIsSubmitting(true);
    try {
      let caseRes = await CaseServices.addNewCase({
        ...values,
        patientId: patientId,
        hospitalId: hospitalid,
        status: "CASEADDED",
      });
      setCaseId(caseRes?.data?.caseId);
      await CaseServices.addCaseProcedure(
        values.caseProcedures,
        caseRes?.data?.caseId
      );
      window.alert("Case Details saved");
      setValue("MAP DOCUMENTS");
    } catch (error) {
      if (error?.response?.status === 401) {
        logOut();
      } else {
        window.alert(error);
      }
    }
    setIsSubmitting(false);
  };

  const handleUpdate = async (values) => {
    setIsSubmitting(true);
    try {
      await CaseServices.updateCase(values, caseDetails?.id);
      await CaseServices.updateCaseProcedures(
        values.caseProcedures,
        caseDetails?.id
      );
      await refreshPage();
      window.alert("Case Details updated");
      setValue("MAP DOCUMENTS");
    } catch (error) {
      if (error?.response?.status === 401) {
        logOut();
      } else {
        window.alert(error);
      }
    }
    setIsSubmitting(false);
  };
  const handleStatusChange = async () => {
    setIsLoading(true);
    const filteredStage = subStatusList?.filter(
      (status, idx) => status.id === 176
    );
    if (reason) {
      try {
        const postStageData = {
          hsaStage: filteredStage[0]?.hsaWebStatus,
          commandCeneterStage: filteredStage[0]?.commandCenterStageStatus,
          nextStepUser: filteredStage[0]?.nextStepUser,
          caseDetails: [
            {
              query: "",
              queryResponse: reason,
            },
          ],
        };
        const stageResponse = await CaseServices.updateSubMasterStage(
          caseDetails.id,
          postStageData
        );
        window.alert(stageResponse.data.message);
        setReferencePendingModal(false);

        navigate("/dashboard/ActiveCases");
      } catch (error) {
        if (error?.response?.status === 401) {
          logOut();
        } else {
          window.alert(error);
        }
      }
    } else {
      window.alert("Reason is Required");
    }
    setIsLoading(false);
  };
  async function getPatientDetails(patientId) {
    try {
      let res = await PatientServices.getPatientDetails(patientId);
      if (res?.data?.patient) setPatientDetails(res?.data?.patient);
    } catch (error) {
      if (error?.response?.status === 401) {
        logOut();
      } else {
        window.alert(error);
      }
    }
  }
  useEffect(() => {
    if (functionalityType === "EDIT") getPatientDetails(patientId);
  }, [updated]);

  console.log("caseDetails:", caseDetails);
  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={errorValidation}
        onSubmit={functionalityType === "EDIT" ? handleUpdate : handleSubmit}
      >
        {({ errors, touched, setFieldValue, values }) => (
          <Form className="add-case-container">
            <div className="heading">
              {functionalityType === "EDIT" ? "Edit " : ""}Case Details
            </div>
            <FieldArray name="caseProcedures">
              {({ insert, remove, push }) => (
                <div>
                  {values &&
                    values.caseProcedures &&
                    values.caseProcedures.map((procdure, idx) => {
                      return (
                        <div key={idx}>
                          <hr
                            style={{
                              color: "#E4E4E4",
                              marginTop: "40px",
                              width: "100%",
                            }}
                          />
                          <AddCaseProcedure
                            errors={errors}
                            touched={touched}
                            index={idx}
                            procdure={procdure}
                            setFieldValue={setFieldValue}
                            patientDetails={patientDetails}
                            values={values}
                          />
                        </div>
                      );
                    })}
                  <div className="button-box">
                    <button
                      type="button"
                      className="fill-btn"
                      style={{
                        width: "max-content",
                        paddingInline: "32px",
                        cursor: "pointer",
                      }}
                      onClick={() => addProcedure(push)}
                    >
                      <AddOutlinedIcon style={{ height: "14px" }} />
                      ADD PROCEDURE {values?.caseProcedures.length + 1}
                    </button>
                  </div>
                </div>
              )}
            </FieldArray>

            <hr
              style={{ color: "#E4E4E4", marginTop: "40px", width: "100%" }}
            />
            <div className="block xl:flex mt-[40px] gap-[8px]">
              <GeneralFindingsModal
                patientId={patientId}
                patientDetails={patientDetails}
                setUpdated={setUpdated}
                updated={updated}
                functionalityType={functionalityType}
              />
              <PersonalHistoryModal
                patientId={patientId}
                patientDetails={patientDetails}
                setUpdated={setUpdated}
                updated={updated}
                functionalityType={functionalityType}
              />
              <FamilyHistoryModal
                patientId={patientId}
                patientDetails={patientDetails}
                setUpdated={setUpdated}
                updated={updated}
                functionalityType={functionalityType}
              />
            </div>
            <div className="case-form-container1">
              <label className="label1">
                <div className="text-content">Primary Diagnosis</div>
                <Field
                  className="input-field"
                  name="primaryDiagnosis"
                  placeholder="Primary Diagnosis"
                />
                {touched.primaryDiagnosis && errors.primaryDiagnosis && (
                  <div className="field-error">{errors.primaryDiagnosis}</div>
                )}
              </label>
              <label className="label1">
                <div className="text-content">Secondary Diagnosis</div>
                <Field
                  className="input-field"
                  name="secondaryDiagnosis"
                  placeholder="Secondary Diagnosis"
                />
                {touched.secondaryDiagnosis && errors.secondaryDiagnosis && (
                  <div className="field-error">{errors.secondaryDiagnosis}</div>
                )}
              </label>
              <label className="label1">
                <div className="text-content">Admission Type</div>
                <Field className="input-field" name="admissionType" as="select">
                  <option>Admission Type</option>
                  <option value="EMERGENCY">EMERGENCY</option>
                  <option value="PLAN">PLANNED</option>
                </Field>
                {touched.admissionType && errors.admissionType && (
                  <div className="field-error">{errors.admissionType}</div>
                )}
              </label>
              <label className="label1">
                <div className="text-content">Admission Date</div>
                <Field
                  className="input-field"
                  name="admissionDate"
                  placeholder="Admission Date"
                  type="date"
                  max={new Date().toISOString().split("T")[0]}
                  min={
                    new Date(new Date().setDate(new Date().getDate() - 20))
                      .toISOString()
                      .split("T")[0]
                  }
                />
                {touched.admissionDate && errors.admissionDate && (
                  <div className="field-error">{errors.admissionDate}</div>
                )}
              </label>
              <label className="label1">
                <div className="text-content">FIR/MLC</div>
                <Field
                  className="input-field"
                  name="fir"
                  placeholder="FIR/MLC"
                  type="text"
                />
                {touched.fir && errors.fir && (
                  <div className="field-error">{errors.fir}</div>
                )}
              </label>
              <label className="label1">
                <div className="text-content">Police Station Name</div>
                <Field
                  className="input-field"
                  name="policeStationName"
                  placeholder="Police Station Name"
                  type="text"
                />
                {touched.policeStationName && errors.policeStationName && (
                  <div className="field-error">{errors.policeStationName}</div>
                )}
              </label>
              {/* <label className="label1">
              <div className="text-content">Package Cost</div>
              <Field
                className="input-field"
                name="packageCost"
                placeholder="Package Cost"
                type="number"
              />
              {touched.packageCost && errors.packageCost && (
                <div className="field-error">{errors.packageCost}</div>
              )}
            </label> */}
              <label className="label1">
                <div className="text-content">Total Package Cost</div>
                <Field
                  className="input-field"
                  name="totalPackageCost"
                  disabled
                  placeholder="Total Package Cost"
                  type="text"
                  value={values.totalPackageCost}
                />
                {touched.totalPackageCost && errors.totalPackageCost && (
                  <div className="field-error">{errors.totalPackageCost}</div>
                )}
              </label>
              <label className="label1">
                <div className="text-content">Pmjay ID</div>
                <Field
                  className="input-field"
                  name="pmjayId"
                  placeholder="Enter pmjay ID"
                  type="text"
                  value={values.pmjayId}
                />
                {touched.pmjayId && errors.pmjayId && (
                  <div className="field-error">{errors.pmjayId}</div>
                )}
              </label>
            </div>
            <div className="flex justify-center items-center gap-2 mt-4">
              <div className="">
                {isSubmitting ? (
                  <div className="flex justify-center">
                    <CircularProgress />
                  </div>
                ) : (
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    style={{ border: "1px solid #017C57" }}
                    className="text-sm text-white font-semibold bg-[#017C57] px-8 py-2 rounded-full"
                  >
                    SAVE AND PROCEED
                  </button>
                )}
              </div>
              <div
                style={{ border: "1px solid #D32F2F" }}
                className="flex items-center gap-2 text-sm text-white bg-[#D32F2F] font-semibold uppercase px-8 py-2 rounded-full cursor-pointer"
                title="Change Case Stage to Reference Document Pending"
                onClick={() => setReferencePendingModal(true)}
              >
                <NewReleasesOutlinedIcon /> Reference Documents Pending
              </div>
            </div>
          </Form>
        )}
      </Formik>
      {referencePendingModal && (
        <div className="fixed w-screen h-screen top-0 left-0 bg-[#017C5780] flex justify-center items-center z-[1200]">
          <div className="relative w-[50%] h-[30%] bg-[white] rounded-[16px]">
            <div
              className="absolute top-4 right-4 cursor-pointer"
              onClick={() => setReferencePendingModal(false)}
            >
              <img src={CloseIcon} alt="cross-icon" />
            </div>
            <div className="text-xl text-[#191919] text-center font-medium mt-5">
              Change Case Status
            </div>
            <div className="flex justify-center gap-4 mt-5">
              <div className="text-[#191919] font-medium">
                Please Specify Reason *
              </div>
              <textarea
                className="w-[60%] h-[90px] rounded-[8px] p-2"
                onChange={(e) => setReason(e.target.value)}
              />
            </div>
            <div className="flex justify-center">
              <div
                className="text-sm text-white bg-[#017C57] font-semibold rounded-full px-8 py-2 mt-8 cursor-pointer"
                onClick={handleStatusChange}
              >
                Change Status
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AddCaseDetails;
