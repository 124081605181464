import React, { useState, useEffect } from "react";
import CloseIcon from "../../../assets/crossIcon.svg";
import { CaseServices } from "../../../services/CaseServices";
import { logOut } from "../../../config/user";
import { CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
const EditStatusClaimModal = ({ setEditStatus, caseId, caseDetails }) => {
  const [isSubmitting, setSubmitting] = useState(false);
  const [documents, setDocuments] = useState(null);
  const [caseStatus, setCaseStatus] = useState(null);
  const [claimAmount, setClaimAmount] = useState(null);
  const [costDetails, setCostDetails] = useState(null);
  const [rejectDocs, setRejectDocs] = useState(false);
  const [rejectionReason, setRejectionReason] = useState(null);
  const currentRole = useSelector((state) => state.currentRole.value);
  const navigate = useNavigate();

  const handleSubmit = async () => {
    if (!caseStatus) {
      window.alert("Please select case status");
    } else if (!claimAmount) {
      window.alert("Please enter claim amount");
    } else {
      setSubmitting(true);
      const subMasterStageData = {
        hsaStage: "Claim Received",
        commandCeneterStage: "Claim Received",
        nextStepUser: "NA",

        caseDetails: [
          {
            query: "",
            queryResponse: "",
          },
        ],
      };
      const caseMasterDetailsData = [
        {
          detailType: "CLAIM",
          caseId: documents?.currnetSubMasterStage[0]?.caseId,
          details: {
            status: caseStatus,
            claimApproved: claimAmount,
            expectedClaim: caseDetails?.totalPackageCost,
          },
        },
      ];
      try {
        const stageResponse = await CaseServices.updateSubMasterStage(
          documents?.documents[0]?.caseId,
          subMasterStageData
        );
        window.alert(stageResponse.data.message);
        await CaseServices.addEnhancement(caseMasterDetailsData);

        navigate("/dashboard/ActiveCases");
      } catch (error) {
        window.alert(error);
      }
      setSubmitting(false);
    }
  };
  const handleRejection = async () => {
    setSubmitting(true);
    let data = documents.documents.filter((doc) => doc.isSelected);
    data = data.map((document) => {
      return {
        id: document.id,
        status: "REJECTED",
        rejectionReason: rejectionReason,
      };
    });
    try {
      if (rejectionReason) {
        const response = await CaseServices.updateMultipleDocumentStatus(
          data,
          currentRole?.role?.roleName
        );
        console.log("documents to be rejected:", data);
        window.alert(response.data.message);
        navigate("/dashboard/ActiveCases");
      } else {
        window.alert("Rejection Reason is Required");
      }
    } catch (error) {
      window.alert(error);
    }
    setSubmitting(false);
  };
  const handleSelection = (idx) => {
    const modifiedData = documents?.documents?.map((document, index) => {
      if (idx === index) {
        return { ...document, isSelected: !document.isSelected };
      } else {
        return document;
      }
    });
    const filteredData = modifiedData.filter((document) => document.isSelected);
    if (filteredData.length > 0) {
      setRejectDocs(true);
    } else {
      setRejectDocs(false);
    }
    documents.documents = modifiedData;
    setDocuments(documents);
  };
  const getDocumentDetails = async () => {
    try {
      const response = await CaseServices.getLatestCaseStage(caseId);
      console.log("response data:", response);
      const modifiedData = response.data?.documents?.map((document, idx) => {
        return { ...document, isSelected: false };
      });
      response.data.documents = modifiedData;
      setDocuments(response.data);
    } catch (error) {
      if (error?.response?.status === 401) {
        logOut();
      } else {
        window.alert(error);
      }
    }
  };
  const getCostDetails = async () => {
    try {
      const response = await CaseServices.getCaseCostDetails(caseId);
      setCostDetails(response.data);
    } catch (error) {
      window.alert(error);
    }
  };
  useEffect(() => {
    getDocumentDetails();
    getCostDetails();
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);
  return (
    <div
      style={{
        maxHeight: "85%",
        background: "white",
        position: "relative",
        border: "1px solid #E4E4E4",
        borderRadius: "16px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      className="w-[80%] xl:w-[50%] xl:p-16"
    >
      <div
        style={{
          position: "absolute",
          top: "28px",
          right: "32px",
          cursor: "pointer",
        }}
        onClick={() => setEditStatus(false)}
      >
        <img src={CloseIcon} height={30} width={30} alt="close-icon" />
      </div>
      <div className="w-full">
        <div>
          <div className="text-[#191919] text-center text-2xl font-semibold">
            Change case status
          </div>
          <div className="text-[#797979] text-center">
            This is the current case status
          </div>
          <div className="text-[#191919] text-center font-semibold">
            {documents?.currnetSubMasterStage[0].commandCenterStage}
          </div>
        </div>
        <div className="mt-4 w-full flex justify-center pb-4 xl:pb-0">
          <div className="w-[100%] overflow-y-scroll h-[400px] pr-2">
            <div className="flex flex-col items-center my-5">
              <div>
                {costDetails?.procedures?.map((procedure, idx) => {
                  return (
                    <div key={idx}>
                      <div className="text-[#191919] font-medium flex justify-between">
                        Procedure Cost:{" "}
                        <span className="text-[#797979] font-normal">
                          ₹{procedure.packageCost}
                        </span>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="mt-1">
                {costDetails?.enhancements?.map((enhancement, idx) => {
                  return (
                    <div key={idx}>
                      <div className="text-[#191919] font-medium flex justify-between">
                        Enhancement {idx + 1} Cost
                        {": "}
                        <span className="text-[#797979] font-normal">
                          ₹{enhancement?.details?.cost}
                        </span>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="mt-1">
                <div>
                  <div className="text-[#191919] font-medium flex justify-between">
                    Total Package Cost
                    {": "}
                    <span className="text-[#797979] font-normal">
                      ₹{costDetails?.caseDetails?.totalPackageCost}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-wrap gap-2">
              {documents?.documents?.map((document, idx) => {
                return (
                  <div
                    key={idx}
                    style={{ border: "1px solid #E4E4E4" }}
                    className="w-[22%] flex items-start justify-between gap-2 p-2 rounded-[8px]"
                  >
                    {document?.documentName}
                    <input
                      type="checkbox"
                      onClick={() => handleSelection(idx)}
                    />
                  </div>
                );
              })}
            </div>
            {rejectDocs && (
              <div className="w-full">
                <div className="text-red-500 font-bold mt-2">
                  Selected Documents will be Rejected*
                </div>

                <textarea
                  rows="5"
                  placeholder="Rejection Reason"
                  style={{ border: "1px solid #E4E4E4" }}
                  className="w-full  rounded-[8px] p-3 mt-2"
                  onChange={(e) => setRejectionReason(e.target.value)}
                />
                <div className="flex justify-center mt-2">
                  {isSubmitting ? (
                    <CircularProgress />
                  ) : (
                    <div
                      style={{ border: "1px solid #017C57" }}
                      className="text-sm font-semibold text-[#017C57] bg-white px-8 py-2 uppercase rounded-full cursor-pointer"
                      onClick={handleRejection}
                    >
                      Reject
                    </div>
                  )}
                </div>
              </div>
            )}
            {!rejectDocs && (
              <div className="w-[100%] mt-5 space-y-1 flex flex-col xl:flex-row xl:flex-wrap xl:gap-x-2 items-center ">
                <select
                  name="caseStatus"
                  id=""
                  style={{ border: "1px solid #E4E4E4" }}
                  className=" w-[100%] xl:w-[380px] h-full rounded-[8px] py-[10px] px-3"
                  onChange={(e) => setCaseStatus(e.target.value)}
                >
                  <option value="">Select Case Status</option>
                  <option value="claim undersettled">
                    Claim Under Settled
                  </option>
                  <option value="claim settled">Claim Settled</option>
                </select>
                <div
                  style={{ border: "1px solid #E4E4E4" }}
                  className="relative rounded-[8px] w-[100%] xl:w-[380px]"
                >
                  <input
                    type="number"
                    name="amount"
                    placeholder="Enter settled amount"
                    value={claimAmount}
                    className="outline-none appearance-none border-0 w-[100%] xl:w-[378px] h-full rounded-[8px] py-[10px] px-3"
                    onChange={(e) => setClaimAmount(e.target.value)}
                  />
                  <div className="absolute h-full top-[0] right-[12px] flex items-center z-10 ">
                    <div className="underline-offset-1 text-xs font-semibold text-[#BCBCBC]">
                      In ₹
                    </div>
                  </div>
                </div>
                <textarea
                  name="textarea"
                  rows="5"
                  cols="15"
                  placeholder="Key In your reason"
                  style={{ border: "1px solid #E4E4E4" }}
                  className="w-[100%] xl:w-[380px] rounded-[8px] p-3"
                ></textarea>
              </div>
            )}
            {!rejectDocs && (
              <div className="flex justify-center mt-8">
                {isSubmitting ? (
                  <CircularProgress />
                ) : (
                  <div
                    style={{ border: "1px solid #017C57" }}
                    className="w-fit text-xs font-semibold text-[#017C57] rounded-full px-6 py-2 cursor-pointer"
                    onClick={handleSubmit}
                  >
                    CHANGE STATUS
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      {/* current stage documents */}
    </div>
  );
};

export default EditStatusClaimModal;
