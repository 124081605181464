import React, { useEffect, useState } from "react";
import MapDocumentModal from "../updatedocuments/mapDocumentsModal";
import { Paper } from "@mui/material";
import "./MapDocumentsNewPage.scss";
import Setparamtermodal from "./Setparamtermodal";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { logOut } from "../../../config/user";
import { CaseServices } from "../../../services/CaseServices";
import { CircularProgress } from "@mui/material";
import EditIcon from "../../../assets/editIcon.svg";
import { DateTimePicker } from "../../../utils/dateTimePicker";
import CountdownTimer from "../../../utils/countDownTimer";
import { useSelector } from "react-redux";

const MapDocumentsNewPage = ({
  refreshPage,
  setPaper,
  caseId,
  setValue,
  functionalityType,
  csProcedures,
}) => {
  const [caseProcedures, setCaseProcedures] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [documents, setDocuments] = useState({});
  const [admissionDocuments, setAdmissionDocuments] = useState(null);
  const [duringTreatmentDocuments, setDuringTreatmentDocuments] =
    useState(null);
  const [onDischargeDocuments, setOnDischargeDocuments] = useState(null);
  const [areDocumentsMapped, setDocumentsMapped] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [editAdmissionDocument, setEditAdmissionDocument] = useState(null);
  const currentRole = useSelector((state) => state.currentRole.value);

  const handleRemove = async (header, idx, caseId, documentId) => {
    if (caseId) {
      try {
        await CaseServices.deleteDocument(documentId);
        await getDefaultsDocument();
      } catch (error) {
        if (error?.response?.status === 401) {
          logOut();
        } else {
          window.alert(error?.response?.data?.error);
        }
      }
    } else {
      let stageDocuments = { ...documents };
      stageDocuments[`${header}`].splice(idx, 1);
      setDocuments(stageDocuments);
    }
  };

  const handleSubmit = async (fromMapped) => {
    setIsSubmitting(true);
    try {
      let destructuredDocs = [
        ...documents.ADMISSIONDOCUMENT,
        ...documents.DURINGTREATMENTDOCUMENT,
        ...documents.ONDISCHARGEDOCUMENT,
      ];
      if (destructuredDocs.length < 1) {
        window.alert("Please map at least one document");
        setIsSubmitting(false);
        return;
      }
      await CaseServices.addMapDocuments(destructuredDocs, caseId);
      if (!fromMapped) {
        window.alert("Documents mapped successfully");
        setValue("ADD DOCUMENTS");
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        logOut();
      } else {
        window.alert(error);
      }
    }
    setIsSubmitting(false);
  };

  const handleUpdate = async (fromMapped) => {
    setIsSubmitting(true);
    try {
      let destructuredDocs = [
        ...documents.ADMISSIONDOCUMENT,
        ...documents.DURINGTREATMENTDOCUMENT,
        ...documents.ONDISCHARGEDOCUMENT,
      ];

      if (destructuredDocs.length < 1) {
        window.alert("Please map at least one document");
        setIsSubmitting(false);
        return;
      }
      await CaseServices.updateMappedDocuments(destructuredDocs, caseId);
      refreshPage();
      if (!fromMapped) {
        window.alert("Documents updated successfully");
        setValue("UPDATE DOCUMENTS");
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        logOut();
      } else {
        window.alert(error);
      }
    }
    setIsSubmitting(false);
  };

  const handleSave = (fromMapped) => {
    functionalityType === "EDIT"
      ? handleUpdate(fromMapped)
      : handleSubmit(fromMapped);
  };

  const handleEdit = (docId) => {
    if (docId === editAdmissionDocument) {
      setEditAdmissionDocument(null);
    } else {
      setEditAdmissionDocument(docId);
    }
  };
  function updateDeadline(date, name, idx) {
    documents[`${name}`][`${idx}`]["deadline"] = date;
    setDocuments({ ...documents });
  }
  async function getDefaultsDocument() {
    try {
      setIsLoading(true);
      if (functionalityType === "EDIT") {
        let documents = await CaseServices.getMappedDocuments(caseId);
        if (documents?.data?.mappedDocuments && documents?.data?.count > 0) {
          setDocuments(documents?.data?.mappedDocuments);
          setDocumentsMapped(true);
        }
        if (documents?.data?.count == 0) {
          let procedureIds = await csProcedures.map((ids) => ids.procedureId);
          documents = await CaseServices.defaultDocuments(procedureIds);
          // Added deadline inside default documents
          let j = 1;
          const currentDate = new Date();
          const deadline = new Date(currentDate.getTime() + 4 * 60 * 60 * 1000);
          for (let i of documents?.data?.defaultCaseDocuments
            .ADMISSIONDOCUMENT) {
            i["deadline"] = deadline;
          }
          setDocuments(documents?.data?.defaultCaseDocuments);
        }
      } else {
        let procedures = await CaseServices.getCaseProcedures(caseId); // caseId
        if (procedures?.data?.caseProcedures) {
          setCaseProcedures(procedures?.data?.caseProcedures);
          let procIds = procedures.data.caseProcedures.map(
            (proc) => proc.procedureId
          );
          let res = await CaseServices.defaultDocuments(procIds);
          if (res?.data?.defaultCaseDocuments)
            setDocuments(res?.data?.defaultCaseDocuments);
        }
      }
      setIsLoading(false);
    } catch (error) {
      if (error?.response?.status === 401) {
        logOut();
      } else {
        window.alert(error);
      }
      setIsLoading(false);
    }
  }
  const formatData = (data, updateMethod) => {
    const formattedDocument = [];
    const ids = [];
    for (let i of data) {
      const values = [];
      for (let j of data) {
        if (
          i.procedureId === j.procedureId &&
          ids.findIndex((item) => item === i.procedureId) === -1
        ) {
          values.push(j);
        }
      }
      if (values.length > 0) {
        ids.push(i.procedureId);
        formattedDocument.push({
          procedureId: i.procedureId,
          values: values,
        });
      }
    }
    updateMethod(formattedDocument);
  };
  useEffect(() => {
    getDefaultsDocument();
  }, []);
  useEffect(() => {
    if (
      documents &&
      documents?.ADMISSIONDOCUMENT &&
      documents?.ADMISSIONDOCUMENT.length > 0
    ) {
      formatData(documents?.ADMISSIONDOCUMENT, setAdmissionDocuments);
    }
    if (
      documents &&
      documents?.DURINGTREATMENTDOCUMENT &&
      documents?.DURINGTREATMENTDOCUMENT.length > 0
    ) {
      formatData(
        documents?.DURINGTREATMENTDOCUMENT,
        setDuringTreatmentDocuments
      );
    }
    if (
      documents &&
      documents?.ONDISCHARGEDOCUMENT &&
      documents?.ONDISCHARGEDOCUMENT.length > 0
    ) {
      formatData(documents?.ONDISCHARGEDOCUMENT, setOnDischargeDocuments);
    }
  }, [documents]);

  return (
    <Paper className="map-documents-new-box">
      <div className="btn-container" style={{ justifyContent: "flex-start" }}>
        <select
          name=""
          className="outline-btn"
          style={{ width: "167px", display: "block" }}
        >
          <option value="">SPECIALITY ID</option>
        </select>
        <select
          name=""
          className="outline-btn"
          style={{ width: "171px", display: "block" }}
        >
          <option value="">PROCEDURE ID</option>
        </select>
        <select
          name=""
          className="outline-btn"
          style={{ width: "200px", display: "block" }}
        >
          <option value="">PROCEDURE NAME</option>
        </select>
        <select
          name=""
          className="outline-btn"
          style={{ width: "137px", display: "block" }}
        >
          <option value="">IMPLANTS</option>
        </select>
        <select
          name=""
          className="outline-btn"
          style={{ width: "184px", display: "block" }}
        >
          <option value="">STRATIFICATION</option>
        </select>

        <Setparamtermodal
          caseId={caseId}
          setDocuments={setDocuments}
          documents={documents}
        />

        <ErrorOutlineIcon style={{ height: "32px", color: "#017C57" }} />
      </div>
      <hr
        style={{
          color: "#E7E7E7",
          marginBottom: "32px",
        }}
      />

      <div className="hidden xl:flex text-[#191919] font-semibold mb-8">
        <div className="w-[5%]  ">Sr.No.</div>
        <div className="w-[30%] "></div>
        <div className="w-[10%] ">Case Stage</div>
        <div className="w-[50%]  text-center">Document Name</div>
        <div className="w-[5%] ">Action</div>
      </div>
      {!isLoading ? (
        <>
          <div>
            <div className=" flex flex-col xl:flex-row gap-4 xl:gap-0 justify-between text-2xl font-semibold text-[#191919]">
              Admission Documents{" "}
              <MapDocumentModal
                documentType="map admission document"
                caseProcedures={csProcedures}
                caseId={caseId}
                getDefaultsDocument={getDefaultsDocument}
                handleSave={handleSave}
                areDocumentsMapped={areDocumentsMapped}
                // submitMappedDocuments={handleSubmit}
              />
            </div>
            <div className="mt-5">
              {admissionDocuments ? (
                admissionDocuments.map((document, idx) => {
                  return (
                    <div key={idx}>
                      <div className="font-semibold mb-2">
                        {document.procedureId}
                      </div>
                      {document.values.map((doc, idx) => {
                        return (
                          <div
                            key={idx}
                            style={
                              idx % 2 !== 0
                                ? {
                                    backgroundColor: "#F9F9F9",
                                    borderRadius: "8px",
                                  }
                                : {}
                            }
                            className="block text-[#797979] xl:flex xl:flex-wrap py-1 xl:py-0 px-4 mb-2"
                          >
                            <div className="xl:w-[5%] text-xl flex items-center ">
                              {idx + 1}.
                            </div>
                            <div className="xl:w-[30%]">
                              <div className="flex items-center gap-2">
                                <div>
                                  {doc.required ? (
                                    <button
                                      style={{ border: "none" }}
                                      className="flex items-center gap-2 text-xs font-semibold uppercase text-[#BCA03C] bg-[#FFF3C6] px-4 py-2 rounded-full"
                                    >
                                      <ErrorOutlineIcon
                                        sx={{
                                          height: "11px",
                                          width: "11px",
                                        }}
                                      />
                                      Required
                                    </button>
                                  ) : (
                                    ""
                                  )}
                                </div>
                                <div
                                  style={{
                                    fontSize: "16px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: "56px",
                                    gap: "4px",
                                  }}
                                >
                                  {doc.id === editAdmissionDocument ? (
                                    <DateTimePicker
                                      handleChange={updateDeadline}
                                      deadline={doc?.deadline}
                                      name="ADMISSIONDOCUMENT"
                                      idx={idx}
                                      disabled={
                                        doc.id !== editAdmissionDocument
                                      }
                                    />
                                  ) : (
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <div style={{ fontSize: "12px" }}>
                                        Time Remaining :
                                      </div>
                                      <CountdownTimer
                                        deadline={doc.deadline}
                                        color="#000000"
                                      />
                                    </div>
                                  )}
                                  <img
                                    src={EditIcon}
                                    alt="edit-icon"
                                    onClick={() => handleEdit(doc.id)}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="xl:w-[10%]">{doc.caseStage}</div>
                            <div className=" xl:w-[50%] xl:text-xl flex items-center justify-center text-center my-2 xl:my-0">
                              {doc.documentName}
                            </div>
                            <div className="xl:w-[5%] flex items-center justify-center">
                              <DeleteOutlinedIcon
                                sx={{ color: "#FE6234" }}
                                onClick={() => {
                                  handleRemove(
                                    "ADMISSIONDOCUMENT",
                                    idx,
                                    doc.caseId,
                                    doc.id
                                  );
                                }}
                              />
                              <div className="text-sm xl:hidden text-[#FE6234] font-semibold tracking-[1.25px]">
                                DELETE
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  );
                })
              ) : (
                <div className="text-[#191919] text-center py-4">
                  No Documents Found
                </div>
              )}
            </div>
          </div>
          <div className="mt-8">
            <div className=" flex flex-col xl:flex-row gap-4 xl:gap-0 justify-between text-2xl font-semibold text-[#191919]">
              Enhancement Documents{" "}
              <MapDocumentModal
                documentType="map during treatment document"
                caseProcedures={csProcedures}
                caseId={caseId}
                refreshPage={refreshPage}
                getDefaultsDocument={getDefaultsDocument}
                handleSave={handleSave}
                areDocumentsMapped={areDocumentsMapped}
              />
            </div>
            <div className="mt-5">
              {duringTreatmentDocuments ? (
                duringTreatmentDocuments.map((document, idx) => {
                  return (
                    <div key={idx}>
                      <div className="font-semibold mb-2">
                        {document.procedureId}
                      </div>
                      {document.values.map((doc, idx) => {
                        return (
                          <div
                            key={idx}
                            style={
                              idx % 2 !== 0
                                ? {
                                    backgroundColor: "#F9F9F9",
                                    borderRadius: "8px",
                                  }
                                : {}
                            }
                            className="block text-[#797979] xl:flex xl:flex-wrap py-1 xl:py-0 px-4 mb-2"
                          >
                            <div className="xl:w-[5%] text-xl flex items-center ">
                              {idx + 1}.
                            </div>
                            <div className="xl:w-[30%]">
                              <div className="flex items-center gap-2">
                                <div>
                                  {doc.required ? (
                                    <button
                                      style={{ border: "none" }}
                                      className="flex items-center gap-2 text-xs font-semibold uppercase text-[#BCA03C] bg-[#FFF3C6] px-4 py-2 rounded-full"
                                    >
                                      <ErrorOutlineIcon
                                        sx={{
                                          height: "11px",
                                          width: "11px",
                                        }}
                                      />
                                      Required
                                    </button>
                                  ) : (
                                    ""
                                  )}
                                </div>
                                <div
                                  style={{
                                    fontSize: "16px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: "56px",
                                    gap: "4px",
                                  }}
                                >
                                  {doc.id === editAdmissionDocument ? (
                                    <DateTimePicker
                                      handleChange={updateDeadline}
                                      deadline={doc?.deadline}
                                      name="DURINGTREATMENTDOCUMENT"
                                      idx={idx}
                                      disabled={
                                        doc.id !== editAdmissionDocument
                                      }
                                    />
                                  ) : (
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <div style={{ fontSize: "12px" }}>
                                        Time Remaining :
                                      </div>
                                      <CountdownTimer
                                        deadline={doc.deadline}
                                        color="#000000"
                                      />
                                    </div>
                                  )}
                                  <img
                                    src={EditIcon}
                                    alt="edit-icon"
                                    onClick={() => handleEdit(doc.id)}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="xl:w-[10%]">{doc.caseStage}</div>
                            <div className=" xl:w-[50%] xl:text-xl flex items-center justify-center text-center my-2 xl:my-0">
                              {doc.documentName}
                            </div>
                            <div className="xl:w-[5%] flex items-center justify-center">
                              <DeleteOutlinedIcon
                                sx={{ color: "#FE6234" }}
                                onClick={() => {
                                  handleRemove(
                                    "DURINGTREATMENTDOCUMENT",
                                    idx,
                                    doc.caseId,
                                    doc.id
                                  );
                                }}
                              />
                              <div className="text-sm xl:hidden text-[#FE6234] font-semibold tracking-[1.25px]">
                                DELETE
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  );
                })
              ) : (
                <div className="text-[#191919] text-center py-4">
                  No Documents Found
                </div>
              )}
            </div>
          </div>
          <div className="mt-8">
            <div className=" flex flex-col xl:flex-row gap-4 xl:gap-0 justify-between text-2xl font-semibold text-[#191919]">
              On Discharge Documents{" "}
              <MapDocumentModal
                documentType="map discharge document"
                caseProcedures={csProcedures}
                caseId={caseId}
                refreshPage={refreshPage}
                getDefaultsDocument={getDefaultsDocument}
                handleSave={handleSave}
                areDocumentsMapped={areDocumentsMapped}
              />
            </div>
            <div className="mt-5">
              {onDischargeDocuments ? (
                onDischargeDocuments.map((document, idx) => {
                  return (
                    <div key={idx}>
                      <div className="font-semibold mb-2">
                        {document.procedureId}
                      </div>
                      {document.values.map((doc, idx) => {
                        return (
                          <div
                            key={idx}
                            style={
                              idx % 2 !== 0
                                ? {
                                    backgroundColor: "#F9F9F9",
                                    borderRadius: "8px",
                                  }
                                : {}
                            }
                            className="block text-[#797979] xl:flex xl:flex-wrap py-1 xl:py-0 px-4 mb-2"
                          >
                            <div className="xl:w-[5%] text-xl flex items-center ">
                              {idx + 1}.
                            </div>
                            <div className="xl:w-[30%]">
                              <div className="flex items-center gap-2">
                                <div>
                                  {doc.required ? (
                                    <button
                                      style={{ border: "none" }}
                                      className="flex items-center gap-2 text-xs font-semibold uppercase text-[#BCA03C] bg-[#FFF3C6] px-4 py-2 rounded-full"
                                    >
                                      <ErrorOutlineIcon
                                        sx={{
                                          height: "11px",
                                          width: "11px",
                                        }}
                                      />
                                      Required
                                    </button>
                                  ) : (
                                    ""
                                  )}
                                </div>
                                <div
                                  style={{
                                    fontSize: "16px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: "56px",
                                    gap: "4px",
                                  }}
                                >
                                  {doc.id === editAdmissionDocument ? (
                                    <DateTimePicker
                                      handleChange={updateDeadline}
                                      deadline={doc?.deadline}
                                      name="ONDISCHARGEDOCUMENT"
                                      idx={idx}
                                      disabled={
                                        doc.id !== editAdmissionDocument
                                      }
                                    />
                                  ) : (
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <div style={{ fontSize: "12px" }}>
                                        Time Remaining :
                                      </div>
                                      <CountdownTimer
                                        deadline={doc.deadline}
                                        color="#000000"
                                      />
                                    </div>
                                  )}
                                  <img
                                    src={EditIcon}
                                    alt="edit-icon"
                                    onClick={() => handleEdit(doc.id)}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="xl:w-[10%]">{doc.caseStage}</div>
                            <div className=" xl:w-[50%] xl:text-xl flex items-center justify-center text-center my-2 xl:my-0">
                              {doc.documentName}
                            </div>
                            <div className="xl:w-[5%] flex items-center justify-center">
                              <DeleteOutlinedIcon
                                sx={{ color: "#FE6234" }}
                                onClick={() => {
                                  handleRemove(
                                    "ONDISCHARGEDOCUMENT",
                                    idx,
                                    doc.caseId,
                                    doc.id
                                  );
                                }}
                              />
                              <div className="text-sm xl:hidden text-[#FE6234] font-semibold tracking-[1.25px]">
                                DELETE
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  );
                })
              ) : (
                <div className="text-[#191919] text-center py-4">
                  No Documents Found
                </div>
              )}
            </div>
          </div>
          <div className="btn-container">
            {isSubmitting ? (
              <div className="flex justify-center">
                <CircularProgress />
              </div>
            ) : (
              <button
                onClick={() => handleSave(false)}
                disabled={isSubmitting}
                className="filled-btn"
              >
                SAVE DETAILS
              </button>
            )}
          </div>
        </>
      ) : (
        <div style={{ width: "100%", textAlign: "center", marginTop: "40px" }}>
          <CircularProgress />
        </div>
      )}
    </Paper>
  );
};

export default MapDocumentsNewPage;
