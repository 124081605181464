const downloadPdf = (blob, documentName) => {
  const blobUrl = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = blobUrl;
  link.download = documentName;
  link.click();
  URL.revokeObjectURL(blobUrl);
};

export default downloadPdf;
