import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export const DateTimePicker = ({
  handleChange,
  deadline,
  name,
  idx,
  disabled = true,
}) => {
  if (!deadline || new Date(deadline) == "Invalid Date") {
    let currentDate = new Date();
    deadline = new Date(currentDate.getTime() + 4 * 60 * 60 * 1000);
  }
  return (
    <DatePicker
      style={{
        height: "40px",
        borderRadius: "8px",
        border: "1px solid #e4e4e4",
      }}
      selected={new Date(deadline)}
      onChange={(date) => handleChange(date, name, idx)}
      showTimeSelect
      timeFormat="HH:mm"
      timeIntervals={15}
      timeCaption="time"
      dateFormat="MMMM d, yyyy h:mm aa"
      disabled={disabled}
    />
  );
};
