import { useEffect, useState, useRef } from "react";
import PreviewPdfModal from "./PreviewPdfModal";
import CloseIcon from "../../../assets/crossIcon.svg";
import EyeIcon from "../../../assets/eyeIcon.svg";
import EditIcon from "../../../assets/editIcon.svg";
import DownloadIcon from "../../../assets/downloadIconGreen.svg";
import DownloadedIcon from "../../../assets/downloadedIcon.svg";
import { AuthServices } from "../../../services/AuthServices";
import downloadPdf from "../../../utils/downloadPdfUtil";
import axios from "axios";
import JsPDF from "jspdf";
import { CircularProgress } from "@mui/material";
const DownloadMultipleDocuments = ({ setOpen, images, documentName }) => {
  const [documents, setDocuments] = useState([]);
  const [selectAll, setSelectAll] = useState(true);
  const [percentage, setPercentage] = useState(0);
  const [netSpeed, setNetSpeed] = useState(0);
  const [nonDownloadedSelect, setNonDownloadedSelect] = useState(false);
  const [selectedCount, setSelectedCount] = useState(0);
  const [isPreviewOpen, setPreviewOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedDocEdit, setSelectedDocEdit] = useState(null);
  const [pdfCount, setPdfCount] = useState(0);
  const dragDocument = useRef();
  const draggedOverDocument = useRef();
  const handleSelection = (index) => {
    const modifiedData = documents?.map((document, idx) => {
      setSelectAll(false); // This is done to uncheck selectAll if any document is unchecked
      if (index === idx) {
        return { ...document, isSelected: !document.isSelected };
      } else {
        return document;
      }
    });
    setDocuments(modifiedData);
    //counting the selected documents
    const selectedDoc = modifiedData?.filter((data) => data.isSelected);
    setSelectedCount(selectedDoc.length);
  };
  const handleSort = () => {
    // const documentsClone = [...documents];
    // const temp = documentsClone[dragDocument.current];
    // documentsClone[dragDocument.current] =
    //   documentsClone[draggedOverDocument.current];
    // documentsClone[draggedOverDocument.current] = temp;
    let documentsClone = [...documents];

    if (dragDocument.current < draggedOverDocument.current) {
      documentsClone.splice(
        draggedOverDocument.current,
        0,
        documentsClone[dragDocument.current]
      );
      documentsClone.splice(dragDocument.current, 1);
    } else {
      documentsClone.splice(
        draggedOverDocument.current,
        0,
        documentsClone[dragDocument.current]
      );
      documentsClone.splice(dragDocument.current + 1, 1);
    }
    setDocuments(documentsClone);
  };
  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    setNonDownloadedSelect(false);
    if (selectAll) {
      const modifiedData = documents?.map((document) => ({
        ...document,
        isSelected: false,
      }));
      setDocuments(modifiedData);
      setSelectedCount(0);
    } else {
      const modifiedData = documents?.map((document) => ({
        ...document,
        isSelected: true,
      }));
      setDocuments(modifiedData);
      setSelectedCount(modifiedData.length);
    }
  };

  const handleNonDownloadedSelection = () => {
    setNonDownloadedSelect(!nonDownloadedSelect);
    setSelectAll(false);
    if (nonDownloadedSelect) {
      const modifiedData = documents?.map((document) => ({
        ...document,
        isSelected: false,
      }));
      setDocuments(modifiedData);
      setSelectedCount(0);
    } else {
      let selectCount = 0;
      const modifiedData = documents?.map((document) => {
        if (document.isDownloaded) {
          return { ...document, isSelected: false };
        } else {
          selectCount++;
          return { ...document, isSelected: true };
        }
      });
      setDocuments(modifiedData);
      setSelectedCount(selectCount);
    }
  };
  const getArrayBuffer = async () => {
    let bufferData = [];
    console.log("images:", documents);
    const selectedImages = documents.filter((image) => image.isSelected);
    console.log("selectedImages:", selectedImages);
    try {
      for (let image of selectedImages) {
        console.log("image:", image);
        const response = await axios.get(image.documentUrl, {
          responseType: "arraybuffer", // This tells axios to return the data as an ArrayBuffer
        });

        // Check if the response is valid
        if (response.status === 200) {
          const arrayBuffer = response.data;
          bufferData.push(arrayBuffer);

          // Convert the ArrayBuffer into a Blob and create a URL to display the image
          const blob = new Blob([arrayBuffer]);
          const imageUrl = URL.createObjectURL(blob);
          // setImageSrc(imageUrl);
        } else {
          console.error("Failed to fetch image");
        }
      }

      console.log("Array buffer:", bufferData);
    } catch (error) {
      console.error("Error fetching the image", error);
    }
  };
  const generatePDF = async (images, compression) => {
    setIsSubmitting(true);
    const selectedImages = images.filter((image) => image.isSelected);
    const imagesUrl = selectedImages.map((image) => ({
      image: image.documentUrl,
      compressionPossible: compression,
    }));

    try {
      let response = await AuthServices.loadImagesBase64(
        { images: imagesUrl },
        setPercentage,
        setNetSpeed
      );
      downloadPdf(response.data, `${documentName}_${pdfCount + 1}`);

      setPdfCount(pdfCount + 1);
      //setting isDownloaded true for downloaded images
      const modifiedData = documents?.map((document) => {
        if (document.isSelected) {
          return { ...document, isDownloaded: true, isSelected: false };
        } else {
          return document;
        }
      });
      setDocuments(modifiedData);
      setSelectAll(false);
      setSelectedCount(0);
    } catch (error) {
      window.alert(error);
    }
    setIsSubmitting(false);
  };

  const handleDownload = (compression) => {
    if (selectedCount > 0) {
      generatePDF(documents, compression);
    } else {
      window.alert("Plese select atleast one image");
    }
  };
  const handlePreview = () => {
    if (selectedCount > 0) {
      setPreviewOpen(true);
    } else {
      window.alert("Please select atleast one image");
    }
  };
  useEffect(() => {
    const modifiedData = images.map((image) => ({
      ...image,
      isSelected: true,
      isDownloaded: false,
    }));
    setDocuments(modifiedData);
    setSelectedCount(modifiedData.length);
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);
  return (
    <div className="fixed top-0 left-0 w-screen h-screen bg-[#017c5780] flex items-center justify-center z-50">
      <div
        style={{ border: "1px solid #E4E4E4" }}
        className="relative w-[90%] xl:w-[90%] h-[70%] 2xl:w-[70%] bg-white  py-8 px-12 rounded-[16px] overflow-hidden"
      >
        <div
          className="absolute top-4 right-4 flex justify-end cursor-pointer"
          onClick={() => setOpen(false)}
        >
          <img
            src={CloseIcon}
            alt="close-icon"
            onClick={() => setOpen(false)}
          />
        </div>
        <div className="text-2xl font-semibold text-center text-[#191919] ">
          Select multiple documents & download them as a PDF
        </div>
        <div className="text-[#797979] mt-4 text-center">
          PDFs Downloaded {pdfCount}/5
        </div>
        <div className="flex justify-center flex-wrap items-center gap-4 mt-5 2xl:mt-10">
          <div
            className="flex gap-1 items-center text-sm text-[#797979] font-semibold cursor-pointer"
            onClick={handleSelectAll}
          >
            <input
              type="checkbox"
              checked={selectAll}
              className="cursor-pointer"
            />{" "}
            Select All
          </div>
          <div
            className="flex gap-1 text-sm text-[#797979] font-semibold cursor-pointer"
            onClick={handleNonDownloadedSelection}
          >
            <input
              type="checkbox"
              checked={nonDownloadedSelect}
              className="cursor-pointer"
            />{" "}
            Select Non-downloaded
          </div>
          <div
            style={{ border: "1px solid #017C57" }}
            className="flex items-center gap-2 text-xs 2xl:text-sm text-[#017C57] font-bold px-4 py-2 rounded-full cursor-pointer"
            onClick={handlePreview}
          >
            <img src={EyeIcon} alt="eye-icon" /> PREVIEW
          </div>
          {isSubmitting ? (
            <div>
              <CircularProgress />
              {(netSpeed / 1024).toFixed(2)}KB/s
            </div>
          ) : (
            <>
              <div
                style={{ border: "1px solid #017C57" }}
                className="flex items-center gap-2 text-xs 2xl:text-sm text-[#017C57] font-bold px-4 py-2 rounded-full cursor-pointer"
                onClick={() => handleDownload(true)}
              >
                <img
                  src={DownloadIcon}
                  alt="download-icon"
                  className=" w-[18px] h-[18px] 2xl:w-[22px] 2xl:h-[22px]"
                />
                <div className="flex items-end gap-[2px]">
                  DOWNLOAD COMPRESSED{" "}
                  <div className="text-[8px] text-[#464646] align-bottom">
                    {selectedCount} Selected
                  </div>
                </div>
              </div>
              <div
                style={{ border: "1px solid #017C57" }}
                className="flex items-center gap-2 text-xs 2xl:text-sm text-[#017C57] font-bold px-4 py-2 rounded-full cursor-pointer"
                onClick={() => handleDownload(false)}
              >
                <img
                  src={DownloadIcon}
                  alt="download-icon"
                  className=" w-[18px] h-[18px] 2xl:w-[22px] 2xl:h-[22px]"
                />
                <div className="flex items-end gap-[2px]">
                  DOWNLOAD ORIGINAL{" "}
                  <div className="text-[8px] text-[#464646] align-bottom">
                    {selectedCount} Selected
                  </div>
                </div>
              </div>{" "}
              {/* <div>
                <div className="cursor-pointer" onClick={getArrayBuffer}>
                  GET ARRAY BUFFER
                </div>
              </div> */}
            </>
          )}
        </div>

        <div className="grid grid-cols-2 xl:grid-cols-5 gap-2 gap-y-4 mt-4 max-h-[52%] 2xl:max-h-[52%] overflow-y-scroll">
          {documents?.map((image, idx) => {
            return (
              <div
                key={idx}
                style={{ border: "1px solid #F9F9F9" }}
                className="group relative flex justify-center items-center w-[180px] h-[200px] bg-[#F9F9F9] rounded-[8px] overflow-hidden p-2"
                // onClick={() => handleSelection(idx)}
                draggable
                onDragStart={() => (dragDocument.current = idx)}
                onDragEnter={() => (draggedOverDocument.current = idx)}
                onDragEnd={handleSort}
              >
                {" "}
                <div className="relative">
                  <div className="flex justify-between items-center  mb-1 pt-2">
                    <div className="absolute flex items-center gap-2 left-0 top-2">
                      {selectedDocEdit === idx ? (
                        <input
                          type="text"
                          className=" w-[25px] text-xs"
                          onChange={(e) => {
                            dragDocument.current = idx;
                            draggedOverDocument.current =
                              Number(e.target.value) - 1;
                            handleSort();
                            setSelectedDocEdit(null);
                          }}
                        />
                      ) : (
                        <div className="w-[25px] h-[25px] text-xs flex items-center justify-center bg-[white] rounded-full ">
                          {idx + 1}
                        </div>
                      )}
                      <img
                        src={EditIcon}
                        alt="edit-icon"
                        onClick={() => setSelectedDocEdit(idx)}
                        className="cursor-pointer"
                      />
                      {image?.isDownloaded && (
                        <div>
                          <img
                            src={DownloadedIcon}
                            width={18}
                            height={18}
                            alt="downloaded-icon"
                          />
                        </div>
                      )}
                    </div>

                    <div
                      onClick={() => handleSelection(idx)}
                      className="flex justify-end w-full"
                    >
                      <input
                        type="checkbox"
                        checked={image?.isSelected}
                        className="cursor-pointer"
                      />
                    </div>
                  </div>
                  <img
                    src={image?.documentUrl}
                    alt="document-img"
                    // className="object-contain h-[170px] w-[140px] group-hover:h-[190px] goup-hover:w-[140px] transition-all duration-500"
                    className="object-contain h-[170px] w-[140px]"
                  />
                </div>
                {/* <div className="absolute -bottom-[100%] group-hover:bottom-0 left-0  w-full text-[#191919] text-[10px] text-center bg-[#E7FFFC] transition-all duration-300">
                  {" "}
                  Attachment Name
                </div> */}
              </div>
            );
          })}
        </div>
      </div>
      {isPreviewOpen && (
        <PreviewPdfModal
          documents={documents}
          setDocuments={setDocuments}
          setOpen={setPreviewOpen}
          isSubmitting={isSubmitting}
          generatePDF={generatePDF}
        />
      )}
    </div>
  );
};

export default DownloadMultipleDocuments;
