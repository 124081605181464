import { useState } from "react";
import Select from "react-select";
import RemoveIcon from "../../../assets/removeIcon.svg";
import { Formik, Form, Field } from "formik";
import { number, object, string, date } from "yup";
import { CaseServices } from "../../../services/CaseServices";
import { useDispatch } from "react-redux";
import { selectedTab } from "../../../redux/roleSlice";
import { logOut } from "../../../config/user";
import { CircularProgress } from "@mui/material";
const errorValidation = object({
  days: number().required("Required Field"),
  fromDate: date().required("Required Field"),
  toDate: date().required("Required Field"),
  ward: string().required("Required Field"),
  procedureId: string().required("Please select procedure"),
});
const AddEnhancementForm = ({
  daysOptions,
  wardOptions,
  caseProcedures,
  totalPackageCost,
  setAddEnhancement,
  caseId,
  enhancementNumber,
  setValue,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dispatch = useDispatch();
  const initialValues = {
    days: "",
    fromDate: "",
    toDate: "",
    ward: "",
    procedureId: "",
  };

  const handleSubmit = async (values) => {
    setIsSubmitting(true);
    const data = [
      {
        detailType: "ENHANCEMENT",
        caseId: caseId,
        details: {
          procedureId: values.procedureId,
          enhancementDate: new Date(),
          fromDate: values.fromDate,
          toDate: values.toDate,
          wardType: values.ward,
          enhancementDays: values.days,
          cost: totalPackageCost * Number(values.days),
          enhancementPerDayCost: totalPackageCost,
        },
        status: 1,
        version: 0,
      },
    ];

    try {
      const response = await CaseServices.addEnhancement(data);
      dispatch(selectedTab(null));
      setValue("MAP DOCUMENTS");
    } catch (error) {
      if (error?.response?.status === 401) {
        logOut();
      } else if (error?.response?.status === 420) {
        window.alert(
          "Enhancement documents are not yet mapped. Please perform Initiate Enhancement process when documents are mapped."
        );
      } else if (error?.response?.data?.message) {
        window.alert(error?.response?.data?.message);
      } else {
        window.alert(error);
      }
    }
    setIsSubmitting(false);
  };
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={errorValidation}
      onSubmit={handleSubmit}
    >
      {({ errors, touched, setFieldValue, values }) => (
        <Form>
          <div className="flex justify-between">
            <div className="font-semibold text-[#191919]">
              Enhancement {enhancementNumber}
            </div>
            <div
              className="cursor-pointer"
              onClick={() => setAddEnhancement(false)}
            >
              <img src={RemoveIcon} alt="remove-icon" />
            </div>
          </div>
          <div className="flex justify-between flex-wrap gap-y-8  mt-8">
            <div className="w-full xl:w-[48%]">
              <div className="text-sm text-[#191919] font-medium mb-4">
                1. In which ward would you like to take the enhancement?
              </div>
              <Select
                name="ward"
                placeholder="Select Ward"
                options={wardOptions}
                onChange={(item) => setFieldValue("ward", item.value)}
              />
              {touched.ward && errors.ward && (
                <div className="field-error">{errors.ward}</div>
              )}
            </div>
            <div className="w-full xl:w-[48%]">
              <div className="text-sm text-[#191919] font-medium mb-4">
                2. For how many days would you like to initiate the enhancement?
              </div>

              <Select
                name="days"
                placeholder="Select Days"
                options={daysOptions}
                onChange={(item) => setFieldValue("days", item.value)}
              />
              {touched.days && errors.days && (
                <div className="field-error">{errors.days}</div>
              )}
            </div>
            <div className="w-full xl:w-[48%]">
              <div className="text-sm text-[#191919] font-medium mb-4">
                3. From which date to which date should the enhancement last?
              </div>
              <div className="grid grid-cols-2 gap-2">
                <div>
                  <Field
                    type="date"
                    name="fromDate"
                    placeholder="--/--/---"
                    min={new Date().toISOString().split("T")[0]}
                    max={
                      new Date(
                        new Date().setDate(
                          new Date().getDate() + Number(values.days)
                        )
                      )
                        .toISOString()
                        .split("T")[0]
                    }
                    style={{ border: "1px solid #E4E4E4", width: "100%" }}
                    className="rounded-[8px] py-[10px] px-2"
                  />
                  {touched.fromDate && errors.fromDate && (
                    <div className="field-error">{errors.fromDate}</div>
                  )}
                </div>
                <div>
                  <Field
                    type="date"
                    name="toDate"
                    placeholder="--/--/---"
                    style={{ border: "1px solid #E4E4E4", width: "100%" }}
                    className="rounded-[8px] py-[10px] px-2"
                  />
                  {touched.toDate && errors.toDate && (
                    <div className="field-error">{errors.toDate}</div>
                  )}
                </div>
              </div>
            </div>

            <div className="w-full xl:w-[48%]">
              <div className="text-sm text-[#191919] font-medium mb-4">
                4. Please Select Procedure
              </div>
              <Select
                name="procedureId"
                placeholder="Select Procedure"
                options={caseProcedures}
                getOptionLabel={(options) => {
                  return options["procedure"];
                }}
                getOptionValue={(options) => {
                  return options["id"];
                }}
                onChange={(item) => setFieldValue("procedureId", item.id)}
              />
              {touched.procedureId && errors.procedureId && (
                <div className="field-error">{errors.procedureId}</div>
              )}
            </div>
          </div>
          <div className="flex justify-center">
            {isSubmitting ? (
              <CircularProgress />
            ) : (
              <button
                type="submit"
                style={{ border: "1px solid #017C57" }}
                className="w-fit text-[#017C57] bg-white rounded-full mt-12 px-4 py-2 border-0 cursor-pointer uppercase"
              >
                SAVE DETAILS
              </button>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default AddEnhancementForm;
