import { useState, useEffect } from "react";
import { CaseServices } from "../../../services/CaseServices";
import CloseIcon from "../../../assets/crossIcon.svg";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";
import ReviewDocumentModel from "../updatedocuments/reviewDocumentModal";
const SecondStageApproval = ({ setSecondStageApproval, caseId }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [latestStage, setLatestStage] = useState(null);
  const [reason, setReason] = useState("");
  const [isUploadingStatus, setUploadingStatus] = useState(false);
  const currentRole = useSelector((state) => state.currentRole.value);
  const navigate = useNavigate();
  const getLatestCaseStage = async () => {
    setIsLoading(true);
    try {
      const response = await CaseServices.getLatestCaseStage(caseId);
      response.data.documents = response.data.documents.map((doc) => ({
        ...doc,
        isSelected: false,
      }));
      setLatestStage(response.data);
      console.log("latest response :", response.data);
    } catch (error) {
      window.alert(error);
    }
    setIsLoading(false);
  };
  const handleSelection = (id) => {
    latestStage.documents = latestStage.documents.map((doc) => {
      if (id === doc.id) {
        return { ...doc, isSelected: !doc.isSelected };
      }
      return doc;
    });
    setLatestStage({ ...latestStage });
  };

  const handleUpdateStatus = async () => {
    setUploadingStatus(true);

    if (latestStage?.documents.length > 0) {
      try {
        const postStageData = {
          hsaStage: latestStage?.documents[0]?.documentType
            .toLowerCase()
            .includes("admission")
            ? "Admission in Progress"
            : latestStage?.documents[0]?.documentType
                .toLowerCase()
                .includes("enhancement")
            ? "Enhancement in Progress"
            : latestStage?.documents[0]?.documentType
                .toLowerCase()
                .includes("discharge")
            ? "Claim in Progress"
            : "",
          commandCeneterStage: latestStage?.documents[0]?.documentType
            .toLowerCase()
            .includes("admission")
            ? "Admission Documents Approved by Team C"
            : latestStage?.documents[0]?.documentType
                .toLowerCase()
                .includes("enhancement")
            ? "Enhancement  Documents  Approved by Team E"
            : latestStage?.documents[0]?.documentType
                .toLowerCase()
                .includes("discharge")
            ? "Claim Documents  Approved by Team E"
            : "",
          nextStepUser: latestStage?.documents[0]?.documentType
            .toLowerCase()
            .includes("admission")
            ? "Internal Team C"
            : "Internal Team E",
          caseDetails: [
            {
              query: "",
              queryResponse: "",
            },
          ],
        };
        const stageResponse = await CaseServices.updateSubMasterStage(
          caseId,
          postStageData
        );
        window.alert(stageResponse.data.message);
        navigate("/dashboard/ActiveCases");
      } catch (error) {
        window.alert(Error);
      }
    } else {
      window.alert("Documents are not uploaded yet.");
      setSecondStageApproval(false);
    }

    setUploadingStatus(false);
  };
  const handleRejection = async () => {
    const postData = latestStage?.documents
      .filter((doc) => doc.isSelected)
      .map((doc) => {
        return {
          id: doc.id,
          status: "REJECTED",
          rejectionReason: reason,
        };
      });
    if (postData.length > 0) {
      if (reason) {
        try {
          const response = await CaseServices.updateMultipleDocumentStatus(
            postData,
            currentRole?.role?.roleName
          );
          navigate("/dashboard/ActiveCases");
          window.alert(response.data.message);
        } catch (error) {
          window.alert(error);
        }
      } else {
        window.alert("Rejection reason is required");
      }
    } else {
      window.alert("Please select atleast one document to reject");
    }
  };

  useEffect(() => {
    getLatestCaseStage();
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);
  useEffect(() => {
    console.log("latest stage documents:", latestStage?.documents);
  }, [latestStage]);
  console.log("latest stage:", latestStage);
  return (
    <div className="fixed top-0 left-0 bg-[#017C5780] h-screen w-screen  flex justify-center items-center z-[1200]">
      <div className="w-[40%] h-fit bg-white rounded-[24px] p-4 pb-8">
        <div
          className="flex justify-end cursor-pointer"
          onClick={() => setSecondStageApproval(false)}
        >
          <img src={CloseIcon} height={30} width={30} alt="close-icon" />
        </div>

        <div className="mt-5">
          <div className="text-xl text-[#191919] font-semibold text-center">
            Current Stage
          </div>
          <div className="text-medium text-center">
            {latestStage?.currnetSubMasterStage[0]?.commandCenterStage}
          </div>
          {isLoading ? (
            <div className="flex justify-center mt-5">
              {" "}
              <CircularProgress />
            </div>
          ) : (
            <div className="max-h-[80%] overflow-y-scroll grid grid-cols-3 gap-4 px-2 mt-5">
              {latestStage?.documents
                ?.filter((doc) => doc.status === "APPROVED")
                .map((doc, idx) => {
                  return (
                    <div
                      key={idx}
                      style={{ border: "1px solid #E5E5E5" }}
                      className=" p-3 rounded-[12px]"
                    >
                      <div className="flex justify-between">
                        <div
                          className="text-sm font-semibold text-[#191919] overflow-hidden text-ellipsis text-nowrap"
                          title={doc.documentName}
                        >
                          {doc.documentName}
                        </div>{" "}
                        <input
                          type="checkbox"
                          className="w-[18px] h-[18px]"
                          onChange={() => handleSelection(doc.id)}
                        />{" "}
                      </div>
                      <div className="flex justify-center mt-4">
                        <ReviewDocumentModel
                          documentDetails={doc}
                          type="view"
                        />
                      </div>
                    </div>
                  );
                })}
            </div>
          )}
          <div className="flex justify-center mt-5 ">
            <textarea
              name="rejectionReason"
              className="w-[95%] h-[80px] p-2 rounded-[8px]"
              placeholder="Rejection Reason"
              onChange={(e) => setReason(e.target.value)}
            ></textarea>
          </div>
          <div className="flex justify-center mt-5">
            {isUploadingStatus ? (
              <CircularProgress />
            ) : (
              <div className="flex  gap-2 uppercase">
                <div
                  style={{ border: "1px solid #017C57" }}
                  className="text-sm text-[#017C57] font-semibold px-8 py-2 rounded-full cursor-pointer"
                  onClick={handleRejection}
                >
                  Reject
                </div>
                <div
                  className="text-sm font-semibold text-white bg-[#017C57] px-8 py-2 rounded-full cursor-pointer"
                  onClick={() => {
                    handleUpdateStatus();
                  }}
                >
                  Approve
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SecondStageApproval;
