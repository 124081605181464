import React, { useEffect, useState } from "react";
import "./Subscription.scss"; // Import the CSS file for styling
import WidgetsOutlinedIcon from "@mui/icons-material/WidgetsOutlined";
import { CaseServices } from "../services/CaseServices";
import { useNavigate } from "react-router-dom";
import { logOut } from "../config/user";
import Slider from "react-slick";
import SubscriptionCard from "../addhospital/SubscriptionCard";
import { mapSubscriptionPlan } from "../services/SubscriptionServices";
import AddIcon from "../assets/addMoreIcon.svg";
import CrossIcon from "../assets/crossIcon.svg";
import RazorpayPayment from "../payment/RazorpayPayment";
const SubscriptionBox = () => {
  const [selectedDuration, setSelectedDuration] = useState("quarterly");
  const [filteredPlan, setFilteredPlan] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const x = 80;
  const y = 20;
  const leftWidth = y;
  const [loading, setLoading] = useState(true);
  const [subscriptionPlans, setSubscriptionPlans] = useState([]);
  const [subscriptionIds, setSubscriptionIds] = useState([]);
  const [hospitalId, setHospitalId] = useState("");
  const [orderId, setOrderId] = useState(null);
  const [amountToPay, setAmountToPay] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [paymentDetails, setPaymentDetails] = useState({
    name: "",
    phone: "",
    email: "",
  });
  const [payment, setPayment] = useState({
    subtotal: "",
    discount: "",
    total: "",
  });
  const [showSubscriptionForm, setShowSubscriptionForm] = useState(false);
  const [currentPlan, setCurrentPlan] = useState(null);
  const navigate = useNavigate();

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: true,
    // responsive: [
    //   {
    //     breakpoint: 1024,
    //     settings: {
    //       slidesToShow: 3,
    //       slidesToScroll: 1,
    //       infinite: true,
    //       dots: true,
    //       autoplay: true,
    //       autoplaySpeed: 2000,
    //     },
    //   },
    //   {
    //     breakpoint: 600,
    //     settings: {
    //       slidesToShow: 2,
    //       slidesToScroll: 1,
    //       initialSlide: 2,
    //       autoplay: true,
    //       autoplaySpeed: 5000,
    //     },
    //   },
    //   {
    //     breakpoint: 480,
    //     settings: {
    //       slidesToShow: 1,
    //       slidesToScroll: 1,
    //       autoplay: true,
    //       autoplaySpeed: 5000,
    //     },
    //   },
    // ],
  };

  const getNumberOfDays = () => {
    const date1 = new Date(currentPlan?.endingOn);
    const date2 = new Date();
    const timeDifference = date1.getTime() - date2.getTime();
    const numberOfDays = Math.round(timeDifference / (1000 * 3600 * 24));
    return numberOfDays > 0 ? numberOfDays : 0;
  };
  const getDate = (date) => {
    const day =
      new Date(date).getDate() > 9
        ? new Date(date).getDate()
        : `0${new Date(date).getDate()}`;
    const month =
      new Date(date).getMonth() + 1 > 9
        ? new Date(date).getMonth() + 1
        : `0${new Date(date).getMonth() + 1}`;
    const year = new Date(date).getFullYear();
    return `${day}-${month}-${year}`;
  };
  const handleSubscriptionMapping = async () => {
    setIsLoading(true);
    const data = {
      subscriptionIds: subscriptionIds,
      payment: payment,
      global: {
        couponCode: "",
        hospitalId: currentPlan.hospitalId,
      },
    };
    try {
      const response = await mapSubscriptionPlan(data);
      setOrderId(response.data.id);
      setAmountToPay(response.data.amount / 100);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      window.alert(error);
    }
  };
  const handlePaymentDetails = (name, value) => {
    setPaymentDetails((prev) => ({ ...prev, [name]: value }));
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response1 = await CaseServices.subscriptionall();
        setSubscriptionPlans(response1.data);
        setLoading(false);
      } catch (error) {
        if (error?.response?.status === 401) {
          logOut();
        } else {
          window.alert(error);
        }
      }
    };

    fetchData();
  }, []);

  // Fetch active subscription data on component mount
  useEffect(() => {
    const fetchActiveSubscription = async () => {
      try {
        const response = await CaseServices.currentPlan();
        if (response.status === 200) {
          setCurrentPlan(response.data);
        }
      } catch (error) {
        if (error?.response?.status === 401) {
          logOut();
        } else if (error.response && error.response.status === 404) {
          setCurrentPlan("No active plan found");
        } else {
          window.alert(error);
        }
      }
    };

    fetchActiveSubscription();
  }, []);
  useEffect(() => {
    const filteredData = subscriptionPlans?.filter(
      (plan) => plan.planType.toLowerCase() === selectedDuration
    );
    setFilteredPlan(filteredData);
  }, [subscriptionPlans, selectedDuration]);
  useEffect(() => {
    if (selectedPlan) {
      setSubscriptionIds([selectedPlan.id]);
      setPayment({
        ...payment,
        subtotal: selectedPlan.totalWithGst,
        total: selectedPlan.totalWithGst,
      });
    }
  }, [selectedPlan]);
  return (
    <div
      style={{
        marginTop: 93,
        width: "100%",
        padding: 16,
      }}
      className="subscription-container"
    >
      <span className="path_line">
        {" "}
        <WidgetsOutlinedIcon style={{ height: "24px" }} /> &nbsp; &nbsp; /
        &nbsp; Profile &nbsp; &nbsp;/ &nbsp; &nbsp; Subscription
      </span>
      <div className="heading">
        Subscription
        <button
          style={{ cursor: "pointer" }}
          className="filled-btn"
          onClick={() => navigate("/dashboard/renewsubscription")}
        >
          RENEW PLAN
        </button>
      </div>
      <hr
        style={{
          color: "#E7E7E7",
          margin: "0px 16px 24px 16px",
        }}
      />

      <div className="box3">
        <h1 className="hello">
          Your Subscription :{" "}
          <span className="text-[#797979]">
            {`${currentPlan?.subscriptionplan?.name}   `}&#8377;
            {currentPlan?.subscriptionplan?.subTotal}
          </span>
        </h1>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            paddingRight: 20,
            marginBottom: 20,
          }}
        >
          <h1 className="current-plan-text">
            Current plan
            <span className="active-till">
              Active till {getDate(currentPlan?.endingOn)}
            </span>
          </h1>
          <div className="highlight-line">
            {getNumberOfDays()} DAYS REMAINING
          </div>
        </div>
        <span
          style={{ fontSize: "24px", fontWeight: 400, fontFamily: "Inter" }}
        >
          <span>&#8377;</span>
          {currentPlan?.subscriptionplan?.monthlyFees}
          <span
            style={{ fontSize: "16px", fontWeight: 400, fontFamily: "Inter" }}
          >
            /month
          </span>
        </span>
        <div style={{ display: "flex", flexDirection: "row", marginTop: 16 }}>
          <div
            style={{
              backgroundColor: "#CDFAF4",
              height: 28,
              borderWidth: 1,
              borderColor: "#FFFFFF",
              borderTopLeftRadius: 43,
              borderBottomLeftRadius: 43,
              width: `${leftWidth}%`,
              borderTopRightRadius: 43,
              borderBottomRightRadius: 43,
            }}
          >
            <text
              style={{
                paddingTop: 6,
                paddingLeft: 10,
                color: "#797979",
              }}
            >
              {currentPlan?.cases - currentPlan?.subscriptionmanager?.cases}{" "}
              cases used
            </text>
          </div>
          <div
            style={{
              height: 28,
              borderWidth: 1,
              borderColor: "#FFFFFF",
              width: `calc(100% - ${leftWidth}%)`,
              backgroundColor: "#FFF0B4",
              borderTopLeftRadius: 43,
              borderBottomLeftRadius: 43,
              borderTopRightRadius: 43,
              borderBottomRightRadius: 43,
            }}
          >
            <text
              style={{
                paddingTop: 6,
                paddingLeft: 10,
                color: "#797979",
              }}
            >
              Available case Limit {currentPlan?.subscriptionmanager?.cases}
            </text>
          </div>
        </div>
      </div>

      <div
        style={{
          border: "1px solid #E4E4E4",
        }}
        className="bg-white rounded-[16px] p-4"
      >
        <div className="flex justify-between">
          <div className="text-[#191919] font-semibold">Upgrade your plan</div>
          <div className="flex gap-2 text-sm text-white font-semibold bg-[#017C57] rounded-full py-2 px-4 cursor-pointer">
            <img src={AddIcon} alt="add-icon" /> ADD NEW
          </div>
        </div>
        <div className="flex justify-center cursor-pointer">
          <div
            className="grid grid-cols-2 w-[220px]  text-sm font-semibold text-[#797979] rounded-full relative overflow-hidden"
            style={{
              border: "1px solid #E4E4E4",
            }}
          >
            <div
              className={`
                 ${selectedDuration === "quarterly" ? "text-white" : ""} 
              rounded-full py-2 px-4 transition-all  duration-700 ease-in-out bg-transparent z-10 text-center `}
              onClick={() => setSelectedDuration("quarterly")}
            >
              QUARTERLY
            </div>
            <div
              className={`${
                selectedDuration === "yearly" ? "text-white" : ""
              }  rounded-full py-2 px-4 transition-all   duration-700 ease-in-out bg-transparent z-10 text-center `}
              onClick={() => setSelectedDuration("yearly")}
            >
              YEARLY
            </div>
            <div
              className={`absolute top-0 -left-[50%] h-full w-full bg-[#017C57] rounded-full ${
                selectedDuration === "yearly"
                  ? "translate-x-[100%]"
                  : "translate-x-[0%]"
              } transition-all duration-700`}
            ></div>
          </div>
        </div>
        <div className=" max-w-[1500px] p-4">
          <section className="block max-w-[100%] slider-container mt-8">
            <Slider {...settings}>
              {filteredPlan?.map((plan, idx) => {
                return (
                  <div
                    key={idx}
                    className="block"
                    onClick={() => setSelectedPlan(plan)}
                  >
                    <SubscriptionCard
                      subscriptionPrice={plan.monthlyFees}
                      subscriptionType={plan.name}
                      planFeatures={plan.features}
                      isSelected={selectedPlan?.id === plan?.id}
                      isActive={plan?.id === currentPlan?.subscriptionplan?.id}
                    />
                  </div>
                );
              })}
            </Slider>
          </section>
        </div>
        {selectedPlan && (
          <div className="flex justify-center mt-10">
            <div
              className="w-fit rounded-full uppercase text-white text-sm font-semibold bg-[#017C57] px-4 py-2 cursor-pointer"
              onClick={() => {
                setShowSubscriptionForm(true);
                handleSubscriptionMapping();
              }}
            >
              SAVE DETAILS
            </div>
          </div>
        )}
        {showSubscriptionForm && orderId && (
          <div className="fixed top-0 left-0 w-screen h-screen bg-[#F9F9F9B3] flex justify-center items-center z-30">
            <div
              className="relative rounded-16px  w-[50%] bg-white rounded-[16px] p-5"
              style={{
                border: "1px solid #E4E4E4",
              }}
            >
              <div
                className="absolute top-[10px] right-[10px] cursor-pointer"
                onClick={() => setShowSubscriptionForm(false)}
              >
                <img src={CrossIcon} alt="cross-icon" />
              </div>
              <div>
                <div className="flex flex-wrap gap-y-4 mt-4">
                  <label htmlFor="amountToPay" className="w-[33%]">
                    <div className="font-semibold mb-2">Amount</div>
                    <input
                      name="amountToPay"
                      type="text"
                      value={`₹${amountToPay}`}
                      disabled
                      style={{ border: "1px solid #e4e4e4" }}
                      className="h-[40px] text-[18px] text-[#797979] rounded-[8px]
            pl-[12px] w-[90%]"
                    />
                  </label>
                  <label htmlFor="name" className="w-[33%]">
                    <div className="font-semibold mb-2">Enter Name</div>
                    <input
                      name="name"
                      type="text"
                      style={{ border: "1px solid #e4e4e4" }}
                      className="h-[40px] text-[18px] text-[#797979] rounded-[8px]
            pl-[12px] w-[90%]"
                      onChange={(e) =>
                        handlePaymentDetails("name", e.target.value)
                      }
                    />
                  </label>
                  <label htmlFor="phone" className="w-[33%]">
                    <div className="font-semibold mb-2">Enter Phone Number</div>
                    <input
                      name="phone"
                      type="text"
                      style={{ border: "1px solid #e4e4e4" }}
                      className="h-[40px] text-[18px] text-[#797979] rounded-[8px]
            pl-[12px] w-[90%]"
                      onChange={(e) =>
                        handlePaymentDetails("phone", e.target.value)
                      }
                    />
                  </label>
                  <label htmlFor="email" className="w-[33%]">
                    <div className="font-semibold mb-2">Enter Email</div>
                    <input
                      name="email"
                      type="email"
                      style={{ border: "1px solid #e4e4e4" }}
                      className="h-[40px] text-[18px] text-[#797979] rounded-[8px]
            pl-[12px] w-[90%]"
                      onChange={(e) =>
                        handlePaymentDetails("email", e.target.value)
                      }
                    />
                  </label>
                </div>
                {paymentDetails.name &&
                  paymentDetails.phone &&
                  paymentDetails.email && (
                    <div className="flex justify-center mt-4">
                      <RazorpayPayment
                        amount={amountToPay}
                        orderId={orderId}
                        paymentDetails={paymentDetails}
                      />
                    </div>
                  )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SubscriptionBox;
