import { useState } from "react";
import CloseIcon from "../../assets/crossIcon.svg";
import { CircularProgress } from "@mui/material";
import { RpaServices } from "../../services/RpaServices";

const UpdateTmsQueries = ({ queryId, isOpen, getAllQueries }) => {
  const [queryStatus, setQueryStatus] = useState(""); // For storing selected status
  const [loading, setLoading] = useState(false); // For loading state
  const [error, setError] = useState(""); // For error messages

  console.log(queryId);

  const updateStatus = async () => {
    if (!queryStatus) {
      setError("Please select a status before submitting.");
      return;
    }

    setLoading(true);
    setError("");

    try {
      const response = await RpaServices.updateTmsQueriesStatus(
        queryId,
        queryStatus
      );
      window.alert(response.data.message);
      isOpen(false);
      getAllQueries();
    } catch (err) {
      setError("An error occurred. Please try again.", err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="fixed w-screen h-screen top-0 left-0 flex justify-center items-center bg-[#017C5780] z-10">
      <div className="relative w-[50%] h-fit bg-white rounded-[16px] px-4 py-8">
        {/* Close Icon */}
        <div className="absolute right-4 top-4">
          <img
            src={CloseIcon}
            alt="close-icon"
            className="cursor-pointer"
            onClick={() => isOpen(null)}
          />
        </div>

        <div className="flex flex-col gap-y-4 mt-4 px-4">
          <label htmlFor="queryStatus" className="font-semibold text-[#191919]">
            Update Status
          </label>
          <select
            id="queryStatus"
            value={queryStatus}
            onChange={(e) => setQueryStatus(e.target.value)}
            className="w-full border border-gray-300 rounded-[8px] px-4 py-2 focus:outline-none focus:ring-2 focus:ring-[#017C57]"
          >
            <option value="" disabled>
              Select Status
            </option>
            <option value="Active">Active</option>
            <option value="Processed">Processed</option>
            <option value="Complete">Complete</option>
          </select>
        </div>

        {/* Error Message */}
        {error && <div className="text-red-500 mt-2 px-4">{error}</div>}

        {/* Submit Button */}
        <div className="flex justify-center items-center mt-8">
          {loading ? (
            <CircularProgress />
          ) : (
            <div
              className="text-white bg-[#017C57] px-8 py-2 rounded-full cursor-pointer"
              onClick={updateStatus}
            >
              Submit
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default UpdateTmsQueries;
