import { useState, useEffect, useRef } from "react";
import WidgetsOutlinedIcon from "@mui/icons-material/WidgetsOutlined";
import SearchIcon from "@mui/icons-material/Search";
import { CaseQueriesServices } from "../../../services/CaseQueriesServices";
import "./ProcessedQueryCases.scss";
const ProcessedQueryCases = () => {
  const [queries, setQueries] = useState();
  const [width, setWidth] = useState(window.innerWidth);
  const [searchKey, setSearchKey] = useState("");
  const searchTimeRef = useRef();
  const handleSearch = (e) => {
    //debouncing structure
    if (searchTimeRef.current) {
      clearInterval(searchTimeRef.current);
    }
    searchTimeRef.current = setTimeout(() => {
      setSearchKey(e.target.value);
    }, 500);
  };
  const getAllQueries = async () => {
    try {
      const response = await CaseQueriesServices.getCaseQueries();
      console.log("response queries:", response);
    } catch (error) {
      window.alert(error);
    }
  };
  useEffect(() => {
    getAllQueries();
  }, []);
  return (
    <div className="manage-box">
      <span className="path_line">
        <WidgetsOutlinedIcon style={{ height: "24px" }} /> &nbsp; &nbsp;/ &nbsp;
        &nbsp; Dashboard &nbsp; &nbsp;/ &nbsp; &nbsp; Processed Query Cases
      </span>

      <div
        style={{
          display: width > 640 ? "flex" : "block",
          marginTop: "16px",
        }}
        className="heading"
      >
        Manage Cases
        <div
          style={{ border: "1px solid #E4E4E4" }}
          className="flex gap-2 rounded-[8px] px-4 py-2 "
        >
          <SearchIcon />
          <input
            type="text"
            placeholder="Search Anything"
            className="outline-none border-0"
            onChange={handleSearch}
          />
        </div>
      </div>

      <hr
        style={{ width: "100%", border: "1px solid rgba(228, 228, 228, 1)" }}
      />
    </div>
  );
};

export default ProcessedQueryCases;
