import { useState, useEffect } from "react";
import WidgetsOutlinedIcon from "@mui/icons-material/WidgetsOutlined";
import { InterUserMangementServices } from "../services/InternalUserMangementServices";
import { CircularProgress } from "@mui/material";
import EditIcon from "../assets/editIcon.svg";
import { Switch } from "@mui/material";
import AddMore from "../assets/addMoreIcon.svg";
import { useNavigate } from "react-router-dom";
import { updateUser } from "../services/AdminServices";
import UpdateUserDetails from "./UpdateUserDetails";
import CustomPagination from "../utils/paginationUtils";
import "./InternalUsers.scss";
const InternalUsers = () => {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const navigate = useNavigate();
  const limit = 10;
  const getUserData = async () => {
    setLoading(true);
    try {
      const response = await InterUserMangementServices.getTeamUser(
        limit,
        page
      );
      setUsers(response?.data?.teamUsers);
      setTotalPages(response?.data?.totalPages);
    } catch (error) {
      window.alert(error);
    }
    setLoading(false);
  };
  const handleStatusChange = async (userStatus, userId) => {
    const data = {
      userid: userId,
      status: userStatus === "ACTIVE" ? "DISABLE" : "ACTIVE",
    };
    try {
      const response = await updateUser(data);
      getUserData();
    } catch (error) {
      window.alert(error);
    }
  };
  useEffect(() => {
    getUserData();
  }, [page]);
  return (
    <div className="manage-box">
      <span className="path_line">
        <WidgetsOutlinedIcon style={{ height: "24px" }} /> &nbsp; &nbsp;/ &nbsp;
        &nbsp; Dashboard &nbsp; &nbsp;/ &nbsp; &nbsp; Internal Uses
      </span>
      <div className="flex justify-between items-center">
        <div className="heading">Internal Users</div>
        <div
          className="flex items-center gap-2 text-sm text-white bg-[#017C57] font-semibold uppercase px-5 py-2 rounded-full cursor-pointer"
          onClick={() => navigate("/dashboard/internal-user-management")}
        >
          <img src={AddMore} alt="add-more-icon" />
          Add User
        </div>
      </div>
      <hr
        style={{ width: "100%", border: "1px solid rgba(228, 228, 228, 1)" }}
      />
      <div
        style={{ border: "1px solid #DEDEDE" }}
        className="mt-4 p-4 rounded-[16px]"
      >
        <div className="grid grid-cols-8 items-center p-4">
          <div className="font-medium text-[#191919]">Name</div>
          <div className="font-medium text-[#191919] col-span-2">Email</div>
          <div className="font-medium text-[#191919]">Phone Number</div>
          <div className="font-medium text-[#191919]">Role</div>
          <div className="font-medium text-[#191919]">
            Status Responsibility
          </div>
          <div className="font-medium text-[#191919]">Status</div>
          <div className="font-medium text-[#191919] text-center">Action</div>
        </div>
        {loading ? (
          <div className="flex justify-center mt-4">
            <CircularProgress />
          </div>
        ) : (
          <div>
            {users?.map((user, idx) => {
              return (
                <div
                  key={idx}
                  className={`grid grid-cols-8  gap-1 justify-around ${
                    idx % 2 === 0 ? "bg-white" : "bg-[#F7F7F7]"
                  } p-4 rounded-[8px]`}
                >
                  <div>{user.fullName}</div>
                  <div className="col-span-2">{user.email}</div>
                  <div>{user.phone}</div>
                  <div>{user?.userrolemappings[0]?.role?.roleName} </div>
                  <div>--- </div>
                  <div>
                    {user.status === "ACTIVE" ? "Active" : "Inactive"}{" "}
                    <Switch
                      checked={user.status === "ACTIVE"}
                      onChange={() => {
                        handleStatusChange(user.status, user.id);
                        setSelectedUser(user);
                      }}
                      inputProps={{ "aria-label": "toggle status" }}
                      style={{ color: "#017C57" }}
                    />
                  </div>
                  <div className="flex justify-center items-center ">
                    <img
                      src={EditIcon}
                      alt="edit-icon"
                      className="cursor-pointer"
                      onClick={() => setSelectedUser(user)}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
      <div className="flex justify-center mt-5">
        <CustomPagination
          page={page}
          setPage={setPage}
          totalPages={totalPages}
        />
      </div>
      {selectedUser && (
        <UpdateUserDetails
          userDetails={selectedUser}
          getUserData={getUserData}
          isOpen={setSelectedUser}
        />
      )}
    </div>
  );
};

export default InternalUsers;
