import { useState } from "react";
import CloseIcon from "../../../assets/crossIcon.svg";
import DeleteIcon from "../../../assets/deleteIcon.svg";
import { PreinitiateServices } from "../../../services/PreinitiateServices";
import { CircularProgress } from "@mui/material";
const UnmapDocument = ({ refDocId, mappedDocs, isOpen, getDocumentsData }) => {
  const [isDeleting, setDeleting] = useState(false);
  const [deleteDocId, setDeleteDoc] = useState(null);
  const handleDelete = async (docId) => {
    setDeleting(true);
    try {
      await PreinitiateServices.unmapReferenceDoc(docId, refDocId);

      window.alert("Document mapping removed successfully");
      getDocumentsData();
      isOpen(null);
    } catch (error) {
      window.alert(error);
    }
    setDeleteDoc(null);
    setDeleting(false);
  };
  return (
    <div className="fixed top-0 left-0 h-full w-full flex justify-center items-center bg-[#017C5780] z-20">
      <div className=" bg-white rounded-[16px] w-[90%] h-[30%] xl:w-[30%] p-4 xl:p-8">
        <div className=" flex justify-between  mb-2">
          {" "}
          <div className="text-xl">Mapped With</div>
          <img
            src={CloseIcon}
            height={30}
            width={30}
            alt="close-icon"
            className="cursor-pointer"
            onClick={() => isOpen(null)}
          />
        </div>
        <div className="overflow-y-scroll h-[90%] pr-2">
          {mappedDocs?.map((doc, idx) => {
            return (
              <div
                style={{ border: "1px solid #F5F5F5" }}
                key={idx}
                className="p-2 rounded-[8px] mb-2"
              >
                {" "}
                <div className="flex justify-between items-center font-semibold text-sm">
                  {doc.documentName}
                  {deleteDocId === doc.id && isDeleting ? (
                    <div>
                      <CircularProgress />
                    </div>
                  ) : (
                    <img
                      src={DeleteIcon}
                      alt="delete-icon"
                      className="cursor-pointer"
                      onClick={() => {
                        setDeleteDoc(doc.id);
                      }}
                    />
                  )}
                </div>
                <div
                  className={`flex justify-between items-center mt-1 overflow-hidden ${
                    deleteDocId === doc.id
                      ? "h-fit translate-y-0"
                      : "h-0 -translate-y-[100%]"
                  } transition-all duration-300 ease-in-out`}
                >
                  Are You sure you want to remove mapping
                  <div className="flex items-center gap-2">
                    <div
                      className="text-xs text-white bg-[#017C57] px-4 py-1 rounded-full cursor-pointer"
                      onClick={() => setDeleteDoc(null)}
                    >
                      No
                    </div>{" "}
                    <div
                      className="text-xs text-white bg-[#017C57] px-4 py-1 rounded-full cursor-pointer"
                      onClick={() => {
                        setDeleteDoc(null);
                        handleDelete(doc.id);
                      }}
                    >
                      Yes
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default UnmapDocument;
