import { useState, } from "react";
import CloseIcon from "../assets/crossIcon.svg";
import EditIcon from "../assets/editIcon.svg"; // Assuming an edit icon is available
import { CircularProgress } from "@mui/material";
import { RpaServices } from "../services/RpaServices";

const UpdateApplication = ({ application, isOpen,getApplicationIdList }) => {
//   if (!application) {
//     return null; // If application data is null, return nothing
//   }

  const [userName, setUserName] = useState(application.userName || "");
  const [password, setPassword] = useState("");
  const [isEditingPassword, setIsEditingPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    if (!userName) {
      alert("User name cannot be empty");
      return;
    }

    const updatedData = {
      applicationId: application.applicationId,
      userName: userName,
    };

    if (isEditingPassword) {
      if (!password) {
        alert("Password cannot be empty if edited");
        return;
      }
      updatedData.password = password;
    }

    setLoading(true);

    try {
      const response = await RpaServices.updateRpaDeviceDetails(updatedData);
      console.log(response.status, response.data);
      if (response?.status === 201) {
        console.log("Status updated", response.data);
        alert("Application updated successfully!");
        // setReloadPage((prev) => !prev);
        getApplicationIdList()
        isOpen(false)
      }
    } catch (error) {
      console.error("Error while updating application:", error);
      alert("Error while updating application. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="fixed w-screen h-screen top-0 left-0 flex justify-center items-center bg-[#017C5780] z-10">
      <div className="relative w-[50%] h-fit bg-white rounded-[16px] px-4 py-8">
        <div className="absolute right-4 top-4">
          <img
            src={CloseIcon}
            alt="close-icon"
            className="cursor-pointer"
            onClick={() => isOpen(null)}
          />
        </div>
        <div className="text-2xl text-[#191919] font-bold">Application Detail</div>
        <div className="flex justify-between items-center flex-wrap gap-y-5 mt-4 px-4">
          <div className="w-[48%]">
            <div>Application UserName</div>
            <input
              type="text"
              style={{ border: "1px solid #E4E4E4" }}
              className="w-full outline-none p-2 rounded-[8px] mt-2"
              placeholder="Enter Application User Name"
              onChange={(e) => setUserName(e.target.value)}
              value={userName}
            />
          </div>
          <div className="w-[48%]">
            <div className="flex justify-between items-center">
              <span>Password</span>
              {!isEditingPassword && (
                <img
                  src={EditIcon}
                  alt="edit-icon"
                  className="cursor-pointer w-5 h-5"
                  onClick={() => setIsEditingPassword(true)}
                />
              )}
            </div>
            <input
              type="text"
              style={{ border: "1px solid #E4E4E4" }}
              className="w-full outline-none p-2 rounded-[8px] mt-2"
              placeholder="Enter Password"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              disabled={!isEditingPassword}
            />
          </div>
        </div>
        <div className="flex justify-center items-center mt-8">
          {loading ? (
            <CircularProgress />
          ) : (
            <div
              className="text-white bg-[#017C57] px-8 py-2 rounded-full cursor-pointer"
              onClick={handleSubmit}
            >
              Submit
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default UpdateApplication;



