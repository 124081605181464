import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { Paper } from "@mui/material";

function TabPanel(props) {
  const { children, value, index, componentWithoutPage, ...other } = props;

  return (
    <Paper
      style={{
        borderRadius: "16px",
        background: "red",
      }}
      sx={
        [...componentWithoutPage].includes(value)
          ? {
              boxShadow: "none",
              backgroundColor: "transparent",
            }
          : { p: 3 }
      }
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </Paper>
  );
}

export default function TabStepperUtil(props) {
  const {
    children,
    labels,
    index,
    value,
    setValue,
    componentWithoutPage,
    handleChageDisabled,
    ...other
  } = props;

  const handleChange = (event, newValue) => {
    if (handleChageDisabled === "false") {
      event.preventDefault();
      setValue(newValue);
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="secondary tabs example"
        variant="scrollable"
        sx={{
          "& .MuiTabs-indicator": {
            backgroundColor: "#017B57",
          },
          "& .MuiTab-root": {
            color: "#797979",
            fontFamily: "inherit",
            fontSize: "12px",
            fontWeight: "600",
            "&.Mui-selected": {
              color: "#017B57",
            },
          },
        }}
      >
        {labels.map((val, idx) => {
          return (
            <Tab
              value={val}
              label={val}
              key={val}
              // style={{ overflow: "scroll" }}
            />
          );
        })}
      </Tabs>

      {labels.map((label, idx) => {
        return (
          <TabPanel
            value={value}
            index={label}
            componentWithoutPage={componentWithoutPage}
            style={{ marginTop: "32px" }}
            key={idx}
          >
            {children[idx]}
          </TabPanel>
        );
      })}
    </Box>
  );
}
