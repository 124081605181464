import { useState, useEffect } from "react";
import CloseIcon from "../assets/crossIcon.svg";
import { updateUser } from "../services/AdminServices";
import Select from "react-select";
import { NotificationServices } from "../services/NotificationServices";
import { InterUserMangementServices } from "../services/InternalUserMangementServices";
import { CircularProgress } from "@mui/material";
const UpdateUserDetails = ({ userDetails, getUserData, isOpen }) => {
  const [roleList, setRoleList] = useState([]);
  const [selectedResponsibilities, setSelectedResponsibilities] = useState([]);
  const [loading, setLoading] = useState(false);
  const [roleName, setRoleName] = useState(
    userDetails?.userrolemappings[0]?.role?.roleName
  );
  const [email, setEmail] = useState(userDetails.email);
  const [fullName, setFullName] = useState(userDetails.fullName);
  const [phone, setPhone] = useState(userDetails.phone);
  const [statusReponsibilityList, setStatusResponsibilityList] = useState([]);
  const getRoleList = async () => {
    try {
      const response = await NotificationServices.getRoleList();
      setRoleList(response?.data?.allRoles || []);
    } catch (error) {
      window.alert(
        error?.response?.data?.message || "Failed to fetch role list."
      );
    }
  };
  const getResponsibilityList = async () => {
    try {
      const response =
        await InterUserMangementServices.getUserResponsibilityList(25, 1);
      setStatusResponsibilityList(response?.data?.responsibilities);
      let filteredSelectedResponsibilities = [];
      for (let userResponsibility of userDetails.userresponsibilitymasters) {
        for (let responsibility of response?.data?.responsibilities) {
          if (responsibility.id === userResponsibility.stageStatusId) {
            filteredSelectedResponsibilities.push(responsibility);
          }
        }
      }
      console.log(
        "filteredSelectedResponsibilities:",
        filteredSelectedResponsibilities
      );
      setSelectedResponsibilities(filteredSelectedResponsibilities);
    } catch (error) {
      window.alert(
        error?.response?.data?.message || "Failed to fetch role list."
      );
    }
  };
  const handleUpdateMapUser = async () => {
    const data = selectedResponsibilities?.map((responsibility) => ({
      stageStatusId: responsibility.id,
    }));
    try {
      const response = InterUserMangementServices.mapUserResponsibilities({
        userId: userDetails.id,
        responsibilities: data,
      });
    } catch (error) {
      window.alert(error);
    }
  };
  const handleSubmit = async () => {
    setLoading(true);
    const data = {
      userid: userDetails.id,
      fullName,
      roleName,
      email,
      phone,
    };

    try {
      const response = await updateUser(data);
      handleUpdateMapUser();
      window.alert(response?.data?.message);
      getUserData();
      isOpen(false);
    } catch (error) {
      window.alert(error);
    }
    setLoading(false);
  };
  useEffect(() => {
    getRoleList();
    getResponsibilityList();
  }, []);
  return (
    <div className="fixed w-screen h-screen top-0 left-0 flex justify-center items-center bg-[#017C5780] z-10">
      <div className="relative w-[50%] h-fit bg-white  rounded-[16px] px-4 py-8">
        <div className="absolute right-4 top-4">
          <img
            src={CloseIcon}
            alt="close-icon"
            className="cursor-pointer"
            onClick={() => isOpen(null)}
          />
        </div>
        <div className="text-2xl text-[#191919] font-bold">User Detail</div>
        <div className=" flex justify-between items-center flex-wrap gap-y-5 mt-4 px-4 ">
          <div className="w-[48%]">
            <div>Fullname</div>
            <input
              type="text"
              style={{ border: "1px solid #E4E4E4" }}
              className="w-full outline-none p-2 rounded-[8px] mt-2"
              placeholder="Enter User Name"
              onChange={(e) => setFullName(e.target.value)}
              defaultValue={fullName}
            />
          </div>
          <div className="w-[48%]">
            <div>Email</div>
            <input
              type="text"
              style={{ border: "1px solid #E4E4E4" }}
              className="w-full outline-none p-2 rounded-[8px] mt-2"
              placeholder="Enter Email"
              onChange={(e) => setEmail(e.target.value)}
              defaultValue={email}
            />
          </div>
          <div className="w-[48%]">
            <div>Phone Number</div>
            <input
              type="text"
              style={{ border: "1px solid #E4E4E4" }}
              className="w-full outline-none p-2 rounded-[8px] mt-2"
              placeholder="Enter Phone Number"
              onChange={(e) => setPhone(e.target.value)}
              defaultValue={phone}
            />
          </div>{" "}
          <div className="w-[48%]">
            <div className="mb-2">Status Responsibility</div>

            <Select
              options={statusReponsibilityList}
              getOptionLabel={(option) => option.commandCenterStageStatus}
              getOptionValue={(option) => option.commandCenterStageStatus}
              onChange={(items) => setSelectedResponsibilities(items)}
              defaultValue={selectedResponsibilities}
              isMulti
            />
          </div>
          <div className="w-[48%] space-y-2">
            <div>Role</div>
            <Select
              options={roleList}
              getOptionLabel={(option) => option.roleName}
              getOptionValue={(option) => option.roleName}
              onChange={(item) => setRoleName(item?.roleName)}
              value={
                roleList.find((option) => option.roleName === roleName) || null
              }
            />
          </div>
        </div>
        <div className="flex justify-center items-center mt-8">
          {loading ? (
            <CircularProgress />
          ) : (
            <div
              className="text-white bg-[#017C57] px-8 py-2 rounded-full cursor-pointer"
              onClick={handleSubmit}
            >
              Submit
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default UpdateUserDetails;
