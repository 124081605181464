import React, { useState, useEffect } from "react";
import Select from "react-select";
import { Country, State, City } from "country-state-city";
import { Formik, Form, Field } from "formik";
import { HospitalServices } from "../services/HospitalServices";
import { emailRegex } from "../utils/formUtils";
import { logOut } from "../config/user";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import * as Yup from "yup";
import "./Adddoctor.scss";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import WidgetsOutlinedIcon from "@mui/icons-material/WidgetsOutlined";
import { AuthServices } from "../services/AuthServices";

const doctorDetailsSchema = Yup.object({
  email: Yup.string().matches(emailRegex),
  phone: Yup.string()
    .nullable()
    .max(10, "Phone number must be 10 characters long"),
  birthDate: Yup.string().nullable(),
  address: Yup.string(),
  country: Yup.string(),
  state: Yup.string(),
  city: Yup.string(),
  pincode: Yup.string().nullable(),
  highestQualification: Yup.string(),
  gender: Yup.string().required("Required"),
  hospitalId: Yup.string().required("Required"),
  fromDate: Yup.string().nullable(),
  doctorType: Yup.string().required("Required"),
  fullName: Yup.string()
    .matches(/^[a-zA-Z\s-]*$/, "Full name accept letters only")
    .min(3, "Name must be greater than 3 characters long")
    .max(30, "FullName should not contain more than 30 characters")
    .required("Full Name is required"),

  registrationNumber: Yup.string().matches(/^\d+$/, "Only digits are allowed"),
});
const AddDoctor = () => {
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [hospitalList, setHospitalList] = useState([]);
  const [specialityList, setSpecialityList] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [documentCertificate, setDocumentCertificate] = useState(null);
  const navigate = useNavigate();
  const initialValues = {
    fullName: "",
    email: "",
    countryCode: "91",
    phone: null,
    birthDate: null,
    address: "",
    country: "",
    state: "",
    city: "",
    pincode: null,
    qualifications: "",
    highestQualification: "",
    specialityIds: [],
    gender: "",
    hospitalId: "",
    iCardNo: "",
    fromDate: null,
    doctorType: "",
    certificateUrl: null,
  };
  const doctorTypeOption = [
    { value: "Duty Doctor", label: "Duty Doctor" },
    { value: "Specialist", label: "Specialist" },
    { value: "Paramedic", label: "Paramedic" },
    { value: "Anaesthist", label: "Anaesthist" },
    { value: "Consultant", label: "Consultant" },
    { value: "Visiting Consultant", label: "Visiting Consultant" },
    { value: "Others", label: "Others" },
  ];
  const handleSubmit = async (values) => {
    if (documentCertificate) {
      values.certificateUrl = documentCertificate;
    }
    setIsSubmitting(true);
    try {
      const response = await HospitalServices.addDoctor(values);
      window.alert(response.data.message);
      navigate("/dashboard/ActiveCases");
    } catch (error) {
      if (error?.response?.status === 401) {
        logOut();
      } else if (error?.response?.message) {
        window.alert(error.response.message);
      } else {
        window.alert(error);
      }
    }
    setIsSubmitting(false);
  };
  const fileInputRef = React.useRef(null);
  const handleButtonClick = () => {
    fileInputRef.current.click();
  };
  const uploadImage = async (e) => {
    if (e.target.files[0].size > 5242880) {
      window.alert("Please upload a file smaller than 2 MB");
    } else {
      try {
        let data = new FormData();
        data.append("image", e.target.files[0]);
        let res = await AuthServices.uploadImage(data);
        // setDocUrl(res.data.url);
        setDocumentCertificate(res.data.url);
        // setModified(modified + 1);
      } catch (error) {
        if (error?.response?.status === 401) {
          logOut();
        } else {
          window.alert("Error occurred while uploading image");
        }
      }
    }
  };

  const getHospitalList = async () => {
    try {
      const response = await HospitalServices.gethospitals("", 1, 1500);
      setHospitalList(response.data.list);
    } catch (error) {
      if (error?.response?.status === 401) {
        logOut();
      } else {
        window.alert(error);
      }
    }
  };

  const getUniqueSpecialityList = async () => {
    try {
      const response = await HospitalServices.getUniqueSpecialityList(
        "",
        1,
        40
      );

      setSpecialityList(response.data.list);
    } catch (error) {
      if (error?.response?.status === 401) {
        logOut();
      } else {
        window.alert(error);
      }
    }
  };

  useEffect(() => {
    getHospitalList();
    getUniqueSpecialityList();
  }, []);
  useEffect(() => {
    setStateList(State?.getStatesOfCountry(selectedCountry?.isoCode));
  }, [selectedCountry]);

  useEffect(() => {
    setCityList(
      City.getCitiesOfState(selectedState?.countryCode, selectedState?.isoCode)
    );
  }, [selectedState]);
  return (
    <div className="p-[32px] mt-[92px] w-full">
      <span className="path_line">
        <WidgetsOutlinedIcon style={{ height: "24px" }} /> &nbsp; &nbsp;/ &nbsp;
        &nbsp; Profile &nbsp; &nbsp;/ &nbsp; &nbsp; Add Doctor
      </span>
      <text className="heading"> ADD DOCTOR</text>
      <hr
        style={{ width: "100%", border: "1px solid rgba(228, 228, 228, 1)" }}
      />
      <div
        style={{ border: "1px solid #e4e4e4", marginTop: "24px" }}
        className="rounded-[16px] p-5"
      >
        <Formik
          initialValues={initialValues}
          validationSchema={doctorDetailsSchema}
          onSubmit={handleSubmit}
          validateOnChange={true}
          validateOnBlur={false}
        >
          {({ values, errors, touched, setFieldValue }) => (
            <Form>
              <div className="w-full grid grid-cols-3 gap-4">
                <label className="label1">
                  <div className="text-content">
                    Full Name <span className="required">*</span>
                  </div>
                  <Field
                    type="text"
                    className="input-field"
                    name="fullName"
                    placeholder="Enter Full Name"
                  />
                  {touched.fullName && errors.fullName && (
                    <div className="field-error">{errors.fullName}</div>
                  )}
                </label>
                <label className="label1">
                  <div className="text-content">
                    Registration Number <span className="required">*</span>
                  </div>
                  <Field
                    type="text"
                    className="input-field"
                    name="registrationNumber"
                    placeholder="Enter Registration Number"
                    maxLength="10"
                  />
                  {touched.registrationNumber && errors.registrationNumber && (
                    <div className="field-error">
                      {errors.registrationNumber}
                    </div>
                  )}
                </label>
                <label htmlFor="city" className="label1">
                  <div className="text-content">
                    Doctor Type <span className="required">*</span>
                  </div>
                  <Select
                    name="doctorType"
                    options={doctorTypeOption}
                    getOptionLabel={(options) => {
                      return options["label"];
                    }}
                    getOptionValue={(options) => {
                      return options["value"];
                    }}
                    onChange={(item) => {
                      setFieldValue("doctorType", item.value);
                    }}
                    placeholder="Select Doctor type"
                  />
                  {touched.doctorType && errors.doctorType && (
                    <div className="field-error">{errors.doctorType}</div>
                  )}
                </label>
                <label className="label1">
                  <div className="text-content">Email ID</div>
                  <Field
                    className="input-field"
                    name="email"
                    placeholder="Enter email address"
                  />
                  {touched.email && errors.email && (
                    <div className="field-error">{errors.email}</div>
                  )}
                </label>
                <label className="label1">
                  <div className="text-content">Phone</div>
                  <Field
                    type="number"
                    className="input-field"
                    name="phone"
                    maxLength="10"
                    placeholder="Enter phone number"
                  />
                  {touched.phone && errors.phone && (
                    <div className="field-error">{errors.phone}</div>
                  )}
                </label>
                <label className="label1">
                  <div className="text-content">
                    Gender <span className="required">*</span>
                  </div>
                  <Field
                    className="input-field"
                    name="gender"
                    as="select"
                    placeholder="Gender"
                  >
                    <option value="">Select Gender</option>
                    <option value="MALE">MALE</option>
                    <option value="FEMALE">FEMALE</option>
                    <option value="OTHER">OTHER</option>
                  </Field>
                  {touched.gender && errors.gender && (
                    <div className="field-error">{errors.gender}</div>
                  )}
                </label>
                <label className="label1">
                  <div className="text-content">Date Of Birth</div>
                  <Field
                    className="input-field"
                    name="birthDate"
                    placeholder="Age"
                    type="date"
                    max={new Date().toISOString().split("T")[0]}
                  />
                  {touched.birthDate && errors.birthDate && (
                    <div className="field-error">{errors.birthDate}</div>
                  )}
                </label>

                <label htmlFor="country" className="label1">
                  <div className="text-content">Country</div>
                  <Select
                    name="country"
                    options={Country.getAllCountries()}
                    getOptionLabel={(options) => {
                      return options["name"];
                    }}
                    getOptionValue={(options) => {
                      return options["name"];
                    }}
                    value={selectedCountry}
                    onChange={(item) => {
                      setSelectedCountry(item);
                      setFieldValue("country", item.name);
                    }}
                    placeholder="Select Country"
                  />
                  {touched.state && errors.state && (
                    <div className="field-error">{errors.state}</div>
                  )}
                </label>
                <label htmlFor="state" className="label1">
                  <div className="text-content">State</div>
                  <Select
                    name="state"
                    options={stateList}
                    getOptionLabel={(options) => {
                      return options["name"];
                    }}
                    getOptionValue={(options) => {
                      return options["name"];
                    }}
                    value={selectedState}
                    onChange={(item) => {
                      setSelectedState(item);
                      setFieldValue("state", item.name);
                    }}
                    placeholder="Select State"
                  />
                  {touched.state && errors.state && (
                    <div className="field-error">{errors.state}</div>
                  )}
                </label>
                <label htmlFor="city" className="label1">
                  <div className="text-content">City</div>
                  <Select
                    name="city"
                    options={cityList}
                    getOptionLabel={(options) => {
                      return options["name"];
                    }}
                    getOptionValue={(options) => {
                      return options["name"];
                    }}
                    value={selectedCity}
                    onChange={(item) => {
                      setSelectedCity(item);
                      setFieldValue("city", item.name);
                    }}
                    placeholder="Select City"
                  />
                  {touched.city && errors.city && (
                    <div className="field-error">{errors.city}</div>
                  )}
                </label>
                <label className="label1">
                  <div className="text-content">Address Line 1</div>
                  <Field
                    type="text"
                    className="input-field"
                    name="address"
                    placeholder="Address Line 1"
                  />
                  {touched.address && errors.address && (
                    <div className="field-error">{errors.address}</div>
                  )}
                </label>
                <label className="label1">
                  <div className="text-content">Pincode</div>
                  <Field
                    type="number"
                    className="input-field"
                    name="pincode"
                    placeholder="Enter Pincode"
                    onInput={(e) => {
                      if (e.target.value.length > 6) {
                        e.target.value = e.target.value.slice(0, 6);
                      }
                    }}
                  />
                  {touched.pincode && errors.pincode && (
                    <div className="field-error">{errors.pincode}</div>
                  )}
                </label>

                <label className="label1">
                  <div className="text-content">Highest Qualification</div>
                  <Field
                    type="text"
                    className="input-field"
                    name="highestQualification"
                    placeholder="Highest Qualification"
                  />
                  {touched.highestQualification &&
                    errors.highestQualification && (
                      <div className="field-error">
                        {errors.highestQualification}
                      </div>
                    )}
                </label>
                <label htmlFor="hospitalId" className="label1">
                  <div className="text-content">
                    Hospital name <span className="required">*</span>
                  </div>
                  <Select
                    name="hospitalId"
                    options={hospitalList}
                    getOptionLabel={(options) => {
                      return options["name"];
                    }}
                    getOptionValue={(options) => {
                      return options["id"];
                    }}
                    onChange={(item) => {
                      setFieldValue("hospitalId", item.id);
                    }}
                    placeholder="Select Hospital"
                  />
                  {touched.hospitalId && errors.hospitalId && (
                    <div className="field-error">{errors.hospitalId}</div>
                  )}
                </label>
                <label htmlFor="spe" className="label1">
                  <div className="text-content">Speciality name</div>
                  <Select
                    name="specialityIds"
                    isMulti
                    options={specialityList}
                    getOptionLabel={(options) => {
                      return options["speciality"];
                    }}
                    getOptionValue={(options) => {
                      return options["id"];
                    }}
                    onChange={(items) => {
                      const selectedIds = items.map(
                        (item) => item.specialityId
                      );

                      setFieldValue("specialityIds", selectedIds);
                    }}
                    placeholder="Select Speciality"
                  />
                  {touched.specialityIds && errors.specialityIds && (
                    <div className="field-error">{errors.specialityIds}</div>
                  )}
                </label>
                <label className="label1">
                  <div className="text-content">From Date</div>
                  <Field
                    className="input-field"
                    name="fromDate"
                    placeholder="From Date"
                    type="date"
                    max={new Date().toISOString().split("T")[0]}
                  />
                  {touched.fromDate && errors.fromDate && (
                    <div className="field-error">{errors.fromDate}</div>
                  )}
                </label>
                <label className="label1">
                  <div className="text-content">Registration Certificate</div>
                  <input
                    type="file"
                    onChange={(e) => uploadImage(e)}
                    style={{ display: "none" }}
                    ref={fileInputRef}
                  />
                  <div
                    style={{ border: "1px solid #017C57" }}
                    className="flex items-center gap-1 px-5 py-3 bg-white  font-semibold rounded-full text-[#017C57] cursor-pointer w-fit"
                    onClick={() => {
                      // setDocumentCertificate(document)
                      handleButtonClick();
                    }}
                  >
                    <FileUploadOutlinedIcon fontSize="small" color="#FFFFFF" />
                    Upload Now
                  </div>
                </label>
              </div>
              <div className="flex justify-center items-center mt-12">
                {isSubmitting ? (
                  <CircularProgress />
                ) : (
                  <button
                    type="submit"
                    className="text-sm font-thin text-white bg-[#017C57] py-2 px-4  rounded-full border-none cursor-pointer"
                  >
                    Save Details
                  </button>
                )}
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default AddDoctor;
