import React, { useState } from "react";
import WidgetsOutlinedIcon from "@mui/icons-material/WidgetsOutlined";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { logOut } from "../../../config/user";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import "./AddSubadmin.scss";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { AddUserApi, Edituserapi } from "../../../services/AdminServices";
const validationSchema = Yup.object().shape({
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  phoneNumber: Yup.string()
    .matches(/^\d+$/, "Invalid phone number")
    .required("Phone number is required"),
  designation: Yup.string().required("Designation is required"),
  username: Yup.string().required("Username is required"),
  password: Yup.string().required("Password is required"),
});

function EditSubadmin() {
  const handleSubmit = async (values) => {
    try {
      const response = await Edituserapi(values);
      if (response.status === 200) {
        window.alert("Subadmin Created Successfully");
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        logOut();
      } else if (error.response && error.response.status === 404) {
        window.alert("Phone Number or email already exists");
      }
    }
  };

  return (
    <div
      style={{
        fontFamily: "Inter",
        padding: 32,
        marginTop: 93,
        width: "100%",
      }}
    >
      <span className="path_line">
        <WidgetsOutlinedIcon style={{ height: "24px" }} /> &nbsp; &nbsp;/ &nbsp;
        &nbsp; Profile &nbsp; &nbsp;/ &nbsp; &nbsp; Add Sub Admin
      </span>
      <div className="heading">Edit Sub Admin</div>
      <hr
        style={{
          color: "#E7E7E7",
        }}
      />
      <div>
        <Formik
          initialValues={{
            firstName: "",
            lastName: "",
            email: "",
            phoneNumber: "",
            designation: "",
            username: "",
            password: "",
          }}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting }) => {
            handleSubmit(values);
            setSubmitting(false);
          }}
          validateOnChange={false}
          validateOnBlur={false}
        >
          {({ isSubmitting }) => (
            <Form>
              <Accordion className="accordionbox">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <text className="personal-details">
                      Personal Details&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;{" "}
                    </text>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="case-form-container1">
                    <label htmlFor="firstName" className="label1">
                      <div className="text-content">First Name</div>
                      <Field
                        type="text"
                        name="firstName"
                        className="input-field"
                        placeholder="John"
                      />
                      <ErrorMessage
                        name="firstName"
                        component="div"
                        className="field-error"
                      />
                    </label>
                    <label htmlFor="lastName" className="label1">
                      <div className="text-content">Last Name</div>
                      <Field
                        type="text"
                        name="lastName"
                        className="input-field"
                        placeholder="Doe"
                      />
                      <ErrorMessage
                        name="lastName"
                        component="div"
                        className="field-error"
                      />
                    </label>
                  </div>
                  <div className="case-form-container1">
                    <label htmlFor="email" className="label1">
                      <div className="text-content">Official Email</div>
                      <Field
                        type="text"
                        name="email"
                        className="input-field"
                        placeholder="Enter email address"
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="field-error"
                      />
                    </label>
                    <label htmlFor="phoneNumber" className="label1">
                      <div className="text-content">Phone Number</div>
                      <Field
                        type="text"
                        name="phoneNumber"
                        className="input-field"
                        placeholder="+91"
                      />
                      <ErrorMessage
                        name="phoneNumber"
                        component="div"
                        className="field-error"
                      />
                    </label>
                  </div>
                </AccordionDetails>
              </Accordion>

              <Accordion className="accordionbox">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <text className="personal-details">
                      Professional Details{" "}
                    </text>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="case-form-container1">
                    <label htmlFor="designation" className="label1">
                      <div className="text-content">Designation </div>
                      <Field
                        type="text"
                        name="designation"
                        className="input-field"
                        placeholder="Enter details"
                      />
                      <ErrorMessage
                        name="designation"
                        component="div"
                        className="field-error"
                      />
                    </label>
                  </div>
                </AccordionDetails>
              </Accordion>

              <Accordion className="accordionbox">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel3-content"
                  id="panel3-header"
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <text className="personal-details">
                      Credentials&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
                      &nbsp;&nbsp; &nbsp;&nbsp;{" "}
                    </text>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="case-form-container1">
                    <label htmlFor="username" className="label1">
                      <div className="text-content">Username</div>
                      <Field
                        type="text"
                        name="username"
                        className="input-field"
                        placeholder="Enter details here"
                      />
                      <ErrorMessage
                        name="username"
                        component="div"
                        className="field-error"
                      />
                    </label>
                    <label htmlFor="password" className="label1">
                      <div className="text-content">Password</div>
                      <Field
                        type="password"
                        name="password"
                        className="input-field"
                        placeholder="*****"
                      />
                      <ErrorMessage
                        name="password"
                        component="div"
                        className="field-error"
                      />
                    </label>
                  </div>
                  <div className="button-box">
                    <button
                      type="submit"
                      className="fill-btn"
                      disabled={isSubmitting}
                    >
                      SAVE DETAILS
                    </button>
                  </div>
                </AccordionDetails>
              </Accordion>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default EditSubadmin;
