import CheckCircleIcon from "../../components/assets/check-circle-icon.svg";
import CrossCircleIcon from "../../components/assets/cross-circle-icon.svg";
const SubscriptionCard = ({
  subscriptionPrice,
  subscriptionType,
  planFeatures,
  isSelected,
  isActive,
}) => {
  return (
    <div
      className={`group rounded-[16px] p-4 hover:bg-[#E7FFFC]  transition-all delay-150 duration-300  mr-2 w-[230px] ${
        isSelected || isActive
          ? "bg-[#017C57] text-white"
          : "bg-white text-[#797979]"
      }`}
      style={{ border: "1px solid #E4E4E4" }}
    >
      <p className=" font-medium">
        <span
          className={`text-2xl font-semibold  transition-all delay-150 duration-300 ${
            isSelected || isActive ? "text-white" : "text-[#191919]"
          }`}
        >
          {subscriptionPrice}{" "}
        </span>
        /month
      </p>
      <p
        className={`text-lg  font-semibold mt-6  transition-all delay-150 duration-300 ease-in-out ${
          isSelected || isActive ? "text-white" : "text-[#191919]"
        }`}
      >
        {subscriptionType}
      </p>
      <p className="text-sm ">
        For most businesses that want to otpimize web queries
      </p>
      <div className="space-y-2">
        {planFeatures?.map((feature, idx) => {
          return (
            <div key={idx} className="flex gap-2  my-0">
              {" "}
              <div>
                <img src={CheckCircleIcon} alt="check-icon" />
              </div>
              <div className="text-[15px] font-medium capitalize">
                {feature}
              </div>
            </div>
          );
        })}
        {/* <div className="flex gap-2 items-center my-0">
          <div>
            <img src={CheckCircleIcon} alt="check-icon" />
          </div>
          <div className="text-[15px] font-medium leading-[21px]">
            Plan Feature
          </div>
        </div>
        <div className="flex gap-2 items-center">
          
        <div>
            <img src={CheckCircleIcon} alt="check-icon" />
          </div>
          <div className="text-[15px] font-medium">Plan Feature</div>
        </div>
        <div className="flex gap-2 items-center">
          <div>
            <img src={CheckCircleIcon} alt="check-icon" />
          </div>
          <div className="text-[15px] font-medium">Plan Feature</div>
        </div>
        <div className="flex gap-2 items-center">
          <div>
            <img src={CrossCircleIcon} alt="cross-icon" />
          </div>
          <div className="text-[15px]  font-medium">Plan Feature</div>
        </div>
        <div className="flex gap-2 items-center">
          <div>
            <img src={CrossCircleIcon} alt="cross-icon" />
          </div>
          <div className="text-[15px]  font-medium">Plan Feature</div>
        </div>  */}
      </div>

      <button
        className={`text-sm ${
          isActive ? "text-white bg-[#49A88F]" : "text-[#017C57] bg-[#CDFAF4]"
        } font-extrabold text-center w-full rounded-full  uppercase border-0 py-2 cursor-pointer mt-7`}
      >
        {isActive ? "Active Plan" : "Choose plan"}
      </button>
    </div>
  );
};

export default SubscriptionCard;
