import { useEffect, useState, useRef } from "react";
import WidgetsOutlinedIcon from "@mui/icons-material/WidgetsOutlined";
import SearchIcon from "../assets/searchIcon.svg";
import { ReportServices } from "../services/ReportServices";
import { HospitalServices } from "../services/HospitalServices";
import { NotificationServices } from "../services/NotificationServices";
import { logOut } from "../config/user";
import Select from "react-select";
import CustomPagination from "../utils/paginationUtils";
import { CircularProgress } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import "./Report.scss";
const Report = () => {
  const [loading, setLoading] = useState(false);
  const [caseStageData, setCaseStageData] = useState(null);
  const [hospitalList, setHospitalList] = useState([]);
  const [hospitalId, setHospitalId] = useState("");
  const [searchKey, setSearchKey] = useState("");
  const [pageNum, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [subStatusList, setSubStatusList] = useState([]);
  const [status, setStatus] = useState("");
  const [stageTimeColor, setStageTimeColor] = useState(null);
  const [allCasesData, setAllCasesData] = useState({
    totalCases: "",
    totalCompleted: "",
    totalPending: "",
  });
  const [filteredCasesData, setFilteredCasesData] = useState({
    filteredCases: "",
    filteredCompleted: "",
    filteredPending: "",
  });
  const searchTimeRef = useRef();
  let prevDate = new Date();
  prevDate.setDate(prevDate.getDate() - 1);
  const [startDate, setStartDate] = useState(
    prevDate // one day before date
  );
  const [endDate, setEndDate] = useState(new Date()); //current date
  const limit = 10;
  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  };
  const handleSearch = (e) => {
    if (searchTimeRef.current) {
      clearTimeout(searchTimeRef.current);
    }
    searchTimeRef.current = setTimeout(() => {
      setSearchKey(e.target.value);
    }, 500);
  };
  const getTime = (diffInS) => {
    const diffInMs = diffInS * 1000;
    const days = Math.floor(diffInMs / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (diffInMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((diffInMs % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((diffInMs % (1000 * 60)) / 1000);

    return `${days > 0 ? days + "D " : ""}${hours > 0 ? hours + "H " : ""} ${
      minutes > 0 ? minutes + "M" : ""
    } ${!days && !hours && !minutes ? seconds + " S" : ""}`;
  };

  const extractDateTime = (timestamp) => {
    const dateObj = new Date(timestamp);
    const time = dateObj.toLocaleTimeString();
    const [year, month, day] = timestamp.slice(0, 10).split("-");
    return `${day}/${month}/${year} ${time}`;
  };

  const formatDate = (date) => {
    date = new Date(date);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are 0-indexed, so we add 1
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  const getOverAllStatusCount = (caseData, color) => {
    let count = 0;
    for (let status of subStatusList) {
      if (
        caseData?.submasterstagestatuses[
          status?.commandCenterStageText || status?.commandCenterStageStatus
        ]?.colorData?.bgColor.includes(color)
      ) {
        count++;
      }
    }
    return `${count} `;
  };
  const getPendingStatusCount = (caseData) => {
    let count = 0;
    const updatedSubstatusList = subStatusList?.filter(
      (status) => status.id !== 176 && status.id !== 999
    );
    for (let status of updatedSubstatusList) {
      if (
        !caseData?.submasterstagestatuses[
          status?.commandCenterStageText || status?.commandCenterStageStatus
        ]?.processTime
      ) {
        count++;
      }
    }
    return count;
  };
  const getHospitalList = async () => {
    try {
      const response = await HospitalServices.gethospitals("", 1, 1500);
      setHospitalList(response.data.list);
    } catch (error) {
      if (error?.response?.status === 401) {
        logOut();
      } else {
        window.alert(error);
      }
    }
  };
  const getSubStatusList = async () => {
    try {
      const response = await NotificationServices.getSubMasterStages(
        200,
        "main"
      );
      setSubStatusList(response?.data?.subMasterStages);
    } catch (error) {
      window.alert(error);
    }
  };
  const getDefaultStageTimeColor = async () => {
    try {
      const response = await ReportServices.getDefaultStageTimeColor();
      console.log("time color response:", response.data);
      setStageTimeColor(response.data);
    } catch (error) {
      window.alert(error);
    }
  };
  const getCaseStageData = async () => {
    setLoading(true);
    try {
      const response = await ReportServices.getCaseStageReport(
        startDate,
        endDate,
        hospitalId,
        pageNum,
        status,
        searchKey
      );
      setAllCasesData({
        totalCases: response?.data?.totalCases,
        totalCompleted: response?.data?.totalCompleted,
      });
      setFilteredCasesData({
        filteredCases: response?.data?.totalFiltered,
        filteredCompleted: response?.data?.filteredCompleted,
      });
      setCaseStageData(response?.data?.report);
      setTotalPages(response?.data?.totalPages);
    } catch (error) {
      window.alert(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    getCaseStageData();
  }, [startDate, endDate, hospitalId, pageNum, status, searchKey]);
  useEffect(() => {
    getHospitalList();
    getSubStatusList();
    getDefaultStageTimeColor();
  }, []);

  return (
    <div className="p-[32px] mt-[92px] w-full overflow-hidden">
      <span className="path_line">
        <WidgetsOutlinedIcon style={{ height: "24px" }} /> &nbsp; &nbsp;/ &nbsp;
        &nbsp; Reports
      </span>
      <div className="flex gap-4  items-center">
        <text className="heading"> Total Report :</text>
        <div style={{ borderRight: "2px solid #191919", paddingRight: "12px" }}>
          Cases -{" "}
          <span className="font-semibold">{allCasesData.totalCases}</span>
        </div>
        <div style={{ borderRight: "2px solid #191919", paddingRight: "12px" }}>
          L1 Complete -{" "}
          <span className="font-semibold">
            {allCasesData?.totalCompleted?.["L1 COMPLETE"] || 0}
          </span>
        </div>
        <div style={{ borderRight: "2px solid #191919", paddingRight: "12px" }}>
          L2 Complete -{" "}
          <span className="font-semibold">
            {(allCasesData?.totalCompleted["COMPLETE"] || 0) +
              (allCasesData?.totalCompleted["L2 COMPLETE"] || 0)}
          </span>
        </div>
        <div style={{ borderRight: "2px solid #191919", paddingRight: "12px" }}>
          L3 Complete -{" "}
          <span className="font-semibold">
            {allCasesData?.totalCompleted["L3 COMPLETE"] || 0}
          </span>
        </div>
        <div style={{ borderRight: "2px solid #191919", paddingRight: "12px" }}>
          L4 Complete -{" "}
          <span className="font-semibold">
            {allCasesData.totalCompleted["L4 COMPLETE"] || 0}
          </span>
        </div>
        <div style={{ borderRight: "2px solid #191919", paddingRight: "12px" }}>
          Total Completed -{" "}
          <span className="font-semibold">
            {allCasesData?.totalCompleted?.totalCompleted || 0}
          </span>
        </div>
        <div>
          Pending -{" "}
          <span className="font-semibold">
            {allCasesData.totalCases -
              (allCasesData?.totalCompleted?.totalCompleted || 0)}
          </span>
        </div>
      </div>
      <div className="flex justify-between items-center">
        <div className="flex gap-3">
          <div>
            <label htmlFor="start-date">Start Date:</label>
            <input
              type="date"
              id="start-date"
              value={formatDate(startDate)}
              onChange={handleStartDateChange}
              max={formatDate(new Date())}
              style={{ border: "1px solid #E5E5E5" }}
              className="text-[#191919] appearance-none p-2 rounded-[8px] "
            />
          </div>

          <div>
            <label htmlFor="end-date">End Date:</label>
            <input
              type="date"
              id="end-date"
              value={formatDate(endDate)}
              onChange={handleEndDateChange}
              max={formatDate(new Date())}
              style={{ border: "1px solid #E5E5E5" }}
              className="text-[#191919] appearance-none p-2 rounded-[8px] "
            />
          </div>
          <Select
            name="hospitalId"
            isClearable
            options={hospitalList}
            getOptionLabel={(options) => {
              return options["name"];
            }}
            getOptionValue={(options) => {
              return options["id"];
            }}
            onChange={(item) => {
              // setPage(1);
              item ? setHospitalId(item.id) : setHospitalId("");
            }}
            value={hospitalList.filter((item) => item.id === hospitalId)}
            placeholder="FILTER HOSPITAL WISE"
          />
          <Select
            name="status"
            isClearable
            options={[
              { label: "Pending", value: "PENDING" },
              { label: "Completed", value: "COMPLETE" },
            ]}
            getOptionLabel={(options) => {
              return options["label"];
            }}
            getOptionValue={(options) => {
              return options["value"];
            }}
            onChange={(item) => {
              // setPage(1);
              item ? setStatus(item.value) : setStatus("");
            }}
            value={[
              { label: "Pending", value: "PENDING" },
              { label: "Completed", value: "COMPLETE" },
            ].filter((item) => item.value === status)}
            placeholder="FILTER STATUS WISE"
          />
        </div>
        <div
          style={{ border: "1px solid #E4E4E4" }}
          className="w-[270px] flex gap-1 items-center py-1 px-2 rounded-[4px]"
        >
          <img src={SearchIcon} alt="search-icon" />
          <input
            type="text"
            onChange={handleSearch}
            className="w-full border-none outline-none"
            placeholder="Patient Name / Beneficiary Number"
          />
        </div>
      </div>
      <hr
        style={{ width: "100%", border: "1px solid rgba(228, 228, 228, 1)" }}
      />
      <div className="flex gap-4 justify-start items-center mt-4">
        <div className="text-xl font-semibold">Filtered :</div>
        <div style={{ borderRight: "2px solid #191919", paddingRight: "12px" }}>
          Cases -{" "}
          <span className="font-semibold">
            {filteredCasesData?.filteredCases || 0}
          </span>
        </div>
        <div style={{ borderRight: "2px solid #191919", paddingRight: "12px" }}>
          L1 Complete -{" "}
          <span className="font-semibold">
            {filteredCasesData?.filteredCompleted["L1 COMPLETE"] || 0}
          </span>
        </div>
        <div style={{ borderRight: "2px solid #191919", paddingRight: "12px" }}>
          L2 Complete -{" "}
          <span className="font-semibold">
            {(filteredCasesData?.filteredCompleted["L2 COMPLETE"] || 0) +
              (filteredCasesData?.filteredCompleted["COMPLETE"] || 0)}
          </span>
        </div>
        <div style={{ borderRight: "2px solid #191919", paddingRight: "12px" }}>
          L3 Complete -{" "}
          <span className="font-semibold">
            {filteredCasesData?.filteredCompleted["L3 COMPLETE"] || 0}
          </span>
        </div>
        <div style={{ borderRight: "2px solid #191919", paddingRight: "12px" }}>
          L4 Complete -{" "}
          <span className="font-semibold">
            {filteredCasesData?.filteredCompleted["L4 COMPLETE"] || 0}
          </span>
        </div>
        <div style={{ borderRight: "2px solid #191919", paddingRight: "12px" }}>
          Total Completed -{" "}
          <span className="font-semibold">
            {filteredCasesData?.filteredCompleted?.totalCompleted || 0}
          </span>
        </div>
        <div>
          Pending -{" "}
          <span className="font-semibold">
            {filteredCasesData.filteredCases -
              (filteredCasesData?.filteredCompleted?.totalCompleted || 0)}
          </span>
        </div>
      </div>
      <div
        style={{ border: "1px solid #e4e4e4", marginTop: "24px" }}
        className="rounded-[16px] p-5 overflow-x-scroll"
      >
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(32, 1fr)",
          }}
          className="gap-0.5 px-1 "
        >
          <div className="text-sm font-medium text-center bg-[#F5F5F5] w-[100px] p-1">
            <div>Created Date</div>
          </div>
          <div className="text-sm font-medium text-center bg-[#F5F5F5] w-[100px] p-1">
            {" "}
            <div>Register Date</div>
          </div>
          <div className="text-sm font-medium text-center bg-[#F5F5F5] w-[200px] p-1">
            {" "}
            <div>Status</div>
          </div>
          <div className="text-sm font-medium text-center bg-[#F5F5F5] w-[150px] p-1">
            {" "}
            <div>Hospital Name</div>
          </div>
          <div className="text-sm font-medium text-center bg-[#F5F5F5] w-[150px] p-1">
            {" "}
            <div>Patient Name</div>
          </div>
          <div className="text-sm font-medium text-center bg-[#F5F5F5] w-[150px] p-1">
            {" "}
            <div>Processed Time</div>
          </div>
          <div className="text-sm font-medium text-center bg-[#F5F5F5] w-[150px] p-1">
            {" "}
            <div>Overall Status</div>
          </div>

          {subStatusList
            ?.filter((status) => status.id !== 999)
            ?.map((status, idx) => {
              return (
                <div
                  key={idx}
                  className="text-sm font-medium text-start bg-[#F5F5F5] w-[150px] p-1"
                >
                  {" "}
                  <div className="flex justify-between items-start">
                    <span
                      title={
                        status?.commandCenterStageText ||
                        status?.commandCenterStageStatus
                      }
                    >
                      {status?.stageShortName}
                    </span>

                    <div className="group relative">
                      <InfoOutlinedIcon
                        style={{
                          color: "#017C57",
                          fontSize: "16px",
                          cursor: "pointer",
                        }}
                      />
                      <div
                        style={{ border: "1.5px solid #D4D4D4" }}
                        className="text-sm hidden group-hover:block absolute top-5 -left-[130px] z-10 w-[160px] rounded-[8px]"
                      >
                        {stageTimeColor?.colorData[
                          stageTimeColor?.subMainStageIdPair[
                            status?.commandCenterStageStatus?.toLowerCase()
                          ]
                        ]?.map((data, idx) => {
                          return (
                            <div
                              key={idx}
                              style={{
                                color: data.textColor,
                                backgroundColor: data.bgColor,
                                padding: "8px",
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                              className="first:rounded-t-[8px] last:rounded-b-[8px] text-sm"
                            >
                              <div>{data.type}</div>
                              <div>
                                {getTime(Number(data.startTime) * 60)} -
                                {idx < 3 && getTime(Number(data.endTime) * 60)}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>

        {loading ? (
          <div className="flex justify-center mt-5">
            <CircularProgress />
          </div>
        ) : (
          <div>
            {caseStageData?.length > 0 ? (
              caseStageData?.map((caseData, idx) => {
                return (
                  <div
                    key={idx}
                    style={{
                      width: "fit-content",
                      display: "grid",
                      gridTemplateColumns: "repeat(32, 1fr)",
                      border: "1px solid #E4E4E4",
                    }}
                    className="text-sm gap-0.5 py-3 px-1  mt-2 rounded-[8px]"
                  >
                    <div className="w-[100px] flex justify-center items-center  text-center">
                      {caseData?.createdAt
                        ? extractDateTime(caseData?.createdAt)
                        : "--"}
                    </div>
                    <div className="w-[100px] flex justify-center items-center bg-[#F5F5F5] text-center">
                      {caseData?.patient?.registeredDate
                        ? extractDateTime(caseData?.patient?.registeredDate)
                        : "--"}
                    </div>
                    <div className=" w-[200px] flex justify-center items-center text-center ">
                      {caseData?.status.toLowerCase().includes("complete") ? (
                        <span className="text-[#017C57] bg-[#CDFAF4] w-full h-full p-2">
                          {caseData?.status}
                        </span>
                      ) : (
                        <span
                          className=" bg-[#FFE227] h-full p-2 text-ellipsis whitespace-nowrap overflow-hidden"
                          title={
                            caseData?.submasterstagestatuses?.currentStageText
                          }
                        >
                          Pending /{" "}
                          <span>
                            {caseData?.submasterstagestatuses?.currentStageText}
                          </span>
                        </span>
                      )}
                    </div>
                    <div
                      title={caseData?.hospital?.name}
                      className="w-[150px] flex justify-center items-center text-center bg-[#F5F5F5] "
                    >
                      {caseData.hospital?.name || "--"}
                    </div>
                    <div className="w-[150px] flex justify-center items-center text-center ">
                      {caseData.patient?.patientName || "--"}
                    </div>
                    <div className="w-[150px] flex justify-center items-center text-center bg-[#F5F5F5]">
                      {caseData.totalProcessTime
                        ? getTime(caseData.totalProcessTime)
                        : "--"}
                    </div>
                    <div className="w-[150px] flex justify-center gap-1 items-center text-center text-[#B4B4B4]">
                      <span
                        className="bg-[#FF2828] text-white px-1 rounded-[4px]"
                        title="Critical"
                      >
                        {getOverAllStatusCount(caseData, "#FF8686")}
                      </span>
                      /
                      <span className="text-[#F76E0B]" title="Challenges">
                        {getOverAllStatusCount(caseData, "#FFE7D6")}
                      </span>
                      /
                      <span className="text-[#1B86EB]" title="Delay">
                        {getOverAllStatusCount(caseData, "#DAECF8")}
                      </span>
                      /
                      <span
                        className="bg-[#017C57] text-white px-1 rounded-[4px]"
                        title="On Track"
                      >
                        {getOverAllStatusCount(caseData, "#CDFAF4")}
                      </span>
                      /
                      <span className="text-[#FFCC00]" title="Pending">
                        {getPendingStatusCount(caseData)}
                      </span>
                    </div>

                    {subStatusList
                      ?.filter((status) => status.id !== 999)
                      ?.map((status, idx) => {
                        return (
                          <div
                            style={{
                              color:
                                caseData?.submasterstagestatuses[
                                  status?.commandCenterStageText ||
                                    status?.commandCenterStageStatus
                                ]?.colorData?.textColor ||
                                (status.id === 176 ? "#017C57" : "#191919"),
                              backgroundColor:
                                caseData?.submasterstagestatuses[
                                  status?.commandCenterStageText ||
                                    status?.commandCenterStageStatus
                                ]?.colorData?.bgColor ||
                                (status.id === 176 ? "#CDFAF4" : "#FFE77F "),
                            }}
                            className="w-[150px] flex items-center justify-center text-center"
                          >
                            {(status.id === 75 &&
                              caseData?.status.toLowerCase() === "complete") ||
                            caseData?.status.toLowerCase() === "l2 complete"
                              ? "COMPLETE"
                              : caseData?.submasterstagestatuses[
                                  status?.commandCenterStageText ||
                                    status?.commandCenterStageStatus
                                ]?.processTime
                              ? getTime(
                                  caseData.submasterstagestatuses[
                                    status?.commandCenterStageText ||
                                      status?.commandCenterStageStatus
                                  ].processTime
                                )
                              : status.id === 176
                              ? "NA"
                              : "PENDING"}
                          </div>
                        );
                      })}
                  </div>
                );
              })
            ) : (
              <div className="text-center mt-5 text-xl font-bold">
                No Data Found
              </div>
            )}
          </div>
        )}
      </div>
      <div className="flex justify-center mt-5">
        <CustomPagination
          page={pageNum}
          setPage={setPage}
          totalPages={totalPages}
        />
      </div>
    </div>
  );
};

export default Report;
