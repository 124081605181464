import { useState, useEffect, useRef } from "react";
import WidgetsOutlinedIcon from "@mui/icons-material/WidgetsOutlined";
import { NotificationServices } from "../services/NotificationServices";
import { CircularProgress } from "@mui/material";
import EditNotificationForm from "./EditNotificationForm";
import CustomPagination from "../utils/paginationUtils";
import { Switch, Stack } from "@mui/material";
import EditIcon from "../assets/editIcon.svg";
import SearchIcon from "@mui/icons-material/Search";
const NotificationsStructure = () => {
  const [defaultNotification, setDefaultNotification] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selecrtedNotification, setSelectedNotification] = useState(null);
  const [searchKey, setSearchKey] = useState("");
  const [openEditPopUp, setOpenEditPopUp] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  let searchTimeRef = useRef();
  const limit = 10;

  const handleSearch = (e) => {
    //debouncing structure
    if (searchTimeRef.current) {
      clearInterval(searchTimeRef.current);
    }
    searchTimeRef.current = setTimeout(() => {
      setSearchKey(e.target.value);
    }, 500);
  };
  const handleStatusChange = async (values) => {
    const data = [
      {
        ...values,
        status: values?.status
          ? values.status === "ACTIVE"
            ? "INACTIVE"
            : "ACTIVE"
          : "ACTIVE",
      },
    ];
    try {
      const response = await NotificationServices.updateDefaultNotifications(
        data
      );
      getDefaultNotificationData();
    } catch (error) {
      window.alert(error);
    }
  };
  const getDefaultNotificationData = async () => {
    setIsLoading(true);
    try {
      const response = await NotificationServices.getDefaultNotificationList(
        searchKey,
        limit,
        page
      );
      setTotalPages(Math.ceil(response?.data?.total / limit));
      setDefaultNotification(response?.data?.allNotifiacations);
    } catch (error) {
      window.alert(error);
    }
    setIsLoading(false);
  };
  useEffect(() => {
    getDefaultNotificationData();
  }, [page, searchKey]);
  return (
    <div
      style={{
        fontFamily: "Inter",
        padding: 32,
        marginTop: 93,
        width: "100%",
      }}
    >
      <span className="path_line">
        <WidgetsOutlinedIcon style={{ height: "24px" }} /> &nbsp; &nbsp;/ &nbsp;
        &nbsp; Profile &nbsp; &nbsp;/ &nbsp; &nbsp; Manage Notifications
      </span>
      <div className="flex justify-between items-center">
        <div className="heading">Manage Notifications</div>
        <div
          style={{ border: "1px solid #E4E4E4" }}
          className="flex gap-2 rounded-[8px] px-4 py-2 "
        >
          <SearchIcon />
          <input
            type="text"
            placeholder="Search Anything"
            className="outline-none border-0"
            onChange={handleSearch}
          />
        </div>
      </div>
      <div
        style={{
          border: "1px solid #E7E7E7",
          marginBottom: "28px",
        }}
      />
      {isLoading ? (
        <div className="flex h-[300px] justify-center items-center">
          <CircularProgress />
        </div>
      ) : (
        <div>
          <div className="pl-12 pr-7">
            <div className="px-4">
              <div className="grid grid-cols-6 justify-between items-center text-[#191919] font-semibold">
                <div>Title</div>
                <div>From</div>
                <div>To</div>
                <div>Event</div>
                <div>Notification Type</div>
                <div>Action</div>
              </div>
            </div>
          </div>
          {defaultNotification?.map((notification, idx) => {
            return (
              <article
                key={idx}
                className={`${
                  idx % 2 == 0
                    ? "bg-white rounded-[16px]"
                    : "bg-[#f9f9f9] rounded-[16px]"
                }`}
              >
                <div className="pl-12 pr-7">
                  <div className="px-4">
                    <div className="grid grid-cols-6 justify-between items-center">
                      <div className="space-y-4">
                        <p className="text-[#797979] text-[18px] ">
                          {notification.title ? notification.title : "--"}
                        </p>
                      </div>
                      <div className="space-y-4">
                        <p className="text-[#797979] text-[18px] ">
                          {notification.from ? notification.from : "--"}
                        </p>
                      </div>
                      <div className="space-y-4">
                        <p className="text-[#797979] text-[18px] ">
                          {notification.to ? notification.to : "--"}
                        </p>
                      </div>
                      <div className="space-y-4">
                        <p className="text-[#797979] text-[18px] max-w-[200px]">
                          {notification.event ? notification.event : "--"}
                        </p>
                      </div>
                      <div className="space-y-4">
                        <p className="text-[#797979] text-[18px] ">
                          {notification.notificationType
                            ? notification.notificationType
                            : "--"}
                        </p>
                      </div>
                      <div className="space-y-4">
                        <Stack direction="row" spacing={1} alignItems="center">
                          <span style={{ textTransform: "capitalize" }}>
                            {notification?.status === "ACTIVE"
                              ? "Active"
                              : "Inactive"}
                          </span>
                          <Switch
                            checked={
                              notification?.status === "ACTIVE" ? true : false
                            }
                            onChange={() => {
                              handleStatusChange(notification);
                            }}
                            inputProps={{ "aria-label": "toggle status" }}
                            style={{ color: "#017C57" }}
                          />
                          <span>
                            <img
                              src={EditIcon}
                              alt="edit-icon"
                              className="cursor-pointer"
                              onClick={() => {
                                setSelectedNotification(notification);
                                setOpenEditPopUp(true);
                              }}
                            />
                          </span>
                        </Stack>
                      </div>
                    </div>
                  </div>
                </div>
              </article>
            );
          })}
          <div className="flex justify-center mt-8">
            <CustomPagination
              page={page}
              setPage={setPage}
              totalPages={totalPages}
            />
          </div>
        </div>
      )}

      {openEditPopUp && (
        <div>
          <EditNotificationForm
            selectedNotification={selecrtedNotification}
            setOpen={setOpenEditPopUp}
            refreshNotifications={getDefaultNotificationData}
          />
        </div>
      )}
    </div>
  );
};

export default NotificationsStructure;
