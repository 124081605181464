import { useEffect, useState, useRef } from "react";
import CloseIcon from "../../../assets/crossIcon.svg";
import { Formik, Form, Field, FieldArray } from "formik";
import { CaseServices } from "../../../services/CaseServices";
import { CircularProgress } from "@mui/material";
import AddNewDocument from "./AddNewDocument";
import DeleteIcon from "../../../assets/deleteIcon.svg";
const ManageDocuments = ({
  setManageDocumentsOpen,
  specialityParam,
  procedureParam,
  specialityIdParam,
  procedureIdParam,
  compositIdParam,
}) => {
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [documentList, setDocumentList] = useState(null);
  const [openAddDocument, setOpenAddDocument] = useState(false);
  const [deleteItem, setDeleteItem] = useState(null);
  const [documentCount, setDocumentCount] = useState(0);
  const formikRef = useRef();
  const submitRef = useRef();
  const initialValues = { documentList: [] };
  const handleSubmit = async (values) => {
    setSaving(true);
    const data = values.documentList.filter((value) => {
      if (value.updated) {
        delete value.updated;
        return { value };
      }
    });
    try {
      await CaseServices.addDefaultDocuments(data);
      window.alert("Details Updated Successfully");
      setManageDocumentsOpen(false);
    } catch (error) {
      window.alert(error);
    }
    setSaving(false);
  };
  const getDefaultDocuments = async () => {
    setLoading(true);
    try {
      const response = await CaseServices.defaultDocumentsWithoutGrouping(
        1,
        100,
        "",
        procedureIdParam
      );
      setDocumentList(response?.data?.list);
      setDocumentCount(response?.data?.list?.length);
    } catch (error) {
      window.alert(error);
    }

    setLoading(false);
  };
  const removeDocument = async (id) => {
    try {
      const response = await CaseServices.removeDefaultDocument(id);
      window.alert("Document Removed Successfully");
      getDefaultDocuments();
    } catch (error) {
      window.alert(error);
    }
  };
  useEffect(() => {
    getDefaultDocuments();
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);
  useEffect(() => {
    if (documentList) {
      const data = documentList.map((document) => ({
        ...document,
        updated: false,
      }));
      formikRef?.current?.setFieldValue("documentList", data);
    }
  }, [documentList]);
  return (
    <div className="fixed h-screen w-screen top-[54px] left-0 flex justify-center items-center bg-[#017C5780] z-10">
      <div className="relative h-[80%] w-[50%] bg-white rounded-[16px] p-8">
        <div className="absolute flex items-center gap-2  right-8 top-4 cursor-pointer ">
          {saving ? (
            <CircularProgress />
          ) : (
            <div
              className="text-xs text-white bg-[#027C57] px-4 py-2 rounded-full"
              onClick={() => submitRef?.current?.click()}
            >
              Save Changes
            </div>
          )}

          <div
            className="text-xs text-white bg-[#017C57] rounded-full cursor-pointer py-2 px-4"
            onClick={() => setOpenAddDocument(true)}
          >
            Add Document
          </div>
          <img
            src={CloseIcon}
            alt="close-icon"
            onClick={() => setManageDocumentsOpen(false)}
          />
        </div>
        {openAddDocument && (
          <AddNewDocument
            specialityParam={specialityParam}
            specialityIdParam={specialityIdParam}
            procedureParam={procedureParam}
            procedureIdParam={procedureIdParam}
            setOpen={setOpenAddDocument}
            getDefaultDocuments={getDefaultDocuments}
          />
        )}
        <div className="text-2xl font-semibold mt-4 text-center">
          Manage Documents <span className="text-sm">{documentCount}</span>
        </div>
        <div className="h-[85%] overflow-y-scroll">
          {!loading && documentList && documentList.length > 0 ? (
            <Formik
              innerRef={formikRef}
              initialValues={initialValues}
              onSubmit={handleSubmit}
            >
              {({ values, setFieldValue }) => (
                <Form>
                  <FieldArray name="documentList">
                    {({ insert, remove, push }) => (
                      <div>
                        {values.documentList &&
                          values.documentList.length > 0 &&
                          values.documentList?.map((document, idx) => {
                            return (
                              <div
                                key={idx}
                                className=" bg-[#F9F9F9] p-4 rounded-[8px] mb-4"
                              >
                                <div className="flex justify-between items-center">
                                  <div className="text-xl text-[#191919] font-medium">
                                    {idx + 1}
                                  </div>
                                  <div>
                                    <img
                                      src={DeleteIcon}
                                      alt="delete-icon"
                                      className="cursor-pointer"
                                      onClick={() => setDeleteItem(document.id)}
                                    />
                                    {deleteItem === document.id && (
                                      <div className="fixed h-screen w-screen left-0 top-[53px] bg-[#017C5780] flex justify-center items-center">
                                        <div className="w-[30%] h-[30%] flex flex-col items-cnter justify-center bg-white rounded-[16px]">
                                          <div className="text-xl text-[#191919] text-center font-semibold ">
                                            Are You sure you want to delete
                                          </div>
                                          <div className="flex justify-center items-center gap-4 mt-8">
                                            <div
                                              className="text-sm text-white bg-[#017C57] px-8 py-1 rounded-full cursor-pointer"
                                              onClick={() => {
                                                removeDocument(document.id);
                                                setDeleteItem(null);
                                              }}
                                            >
                                              Yes
                                            </div>
                                            <div
                                              className="text-sm text-white bg-[#017C57] px-9 py-1 rounded-full cursor-pointer"
                                              onClick={() =>
                                                setDeleteItem(null)
                                              }
                                            >
                                              No
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <div className="grid grid-cols-2 gap-2 mt-4">
                                  <div>
                                    <div className="font-semibold mb-2">
                                      Document Name
                                    </div>
                                    <Field
                                      type="text"
                                      name={`documentList.${idx}.documentName`}
                                      placeholder="Enter Document Name"
                                      style={{ border: "1px solid #E4E4E4" }}
                                      className="w-full text-sm py-[8px] px-[12px] rounded-[8px]"
                                      onChange={(e) => {
                                        setFieldValue(
                                          `documentList.${idx}.updated`,
                                          true
                                        );
                                        setFieldValue(
                                          `documentList.${idx}.documentName`,
                                          e.target.value
                                        );
                                      }}
                                    />
                                  </div>
                                  <div>
                                    <div className="font-semibold mb-2">
                                      Document Type
                                    </div>
                                    <Field
                                      as="select"
                                      style={{ border: "1px solid #E4E4E4" }}
                                      name={`documentList.${idx}.documentType`}
                                      className="w-full text-sm py-[8px] px-[12px] rounded-[8px]"
                                      onChange={(e) => {
                                        setFieldValue(
                                          `documentList.${idx}.updated`,
                                          true
                                        );
                                        setFieldValue(
                                          `documentList.${idx}.documentType`,
                                          e.target.value
                                        );
                                      }}
                                    >
                                      <option value="">
                                        Select Document Type
                                      </option>
                                      <option
                                        selected={
                                          document.documentType ===
                                          "ADMISSIONDOCUMENT"
                                        }
                                        value="ADMISSIONDOCUMENT"
                                      >
                                        Admission Document
                                      </option>
                                      <option
                                        selected={
                                          document.documentType ===
                                          "DURINGTREATMENTDOCUMENT"
                                        }
                                        value="DURINGTREATMENTDOCUMENT"
                                      >
                                        During Treatment Document
                                      </option>
                                      <option
                                        selected={
                                          document.documentType ===
                                          "ONDISCHARGEDOCUMENT"
                                        }
                                        value="ONDISCHARGEDOCUMENT"
                                      >
                                        On Discharge Document
                                      </option>
                                    </Field>
                                  </div>
                                  <div>
                                    <div className="font-semibold mb-2">
                                      Speciality Name
                                    </div>
                                    <input
                                      type="text"
                                      value={specialityParam}
                                      style={{ border: "1px solid #E4E4E4" }}
                                      className="w-full text-sm py-[8px] px-[12px] rounded-[8px]"
                                      disabled
                                    />
                                  </div>
                                  <div>
                                    <div className="font-semibold mb-2">
                                      Speciality ID
                                    </div>
                                    <input
                                      type="text"
                                      style={{ border: "1px solid #E4E4E4" }}
                                      className="w-full text-sm py-[8px] px-[12px] rounded-[8px]"
                                      defaultValue={specialityIdParam}
                                      disabled
                                    />
                                  </div>
                                  <div>
                                    <div className="font-semibold mb-2">
                                      Procedure Name
                                    </div>
                                    <input
                                      type="text"
                                      style={{ border: "1px solid #E4E4E4" }}
                                      className="w-full text-sm py-[8px] px-[12px] rounded-[8px]"
                                      defaultValue={procedureParam}
                                      disabled
                                    />
                                  </div>
                                  <div>
                                    <div className="font-semibold mb-2">
                                      Procedure ID
                                    </div>
                                    <input
                                      type="text"
                                      style={{ border: "1px solid #E4E4E4" }}
                                      className="w-full text-sm py-[8px] px-[12px] rounded-[8px]"
                                      defaultValue={procedureIdParam}
                                      disabled
                                    />
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    )}
                  </FieldArray>
                  <div className="hidden">
                    <button
                      type="submit"
                      ref={submitRef}
                      className="w-[284px] bg-[#017C57] text-xs text-white text-center font-semibold py-2  mt-8 rounded-full cursor-pointer border-none"
                    >
                      SAVE CHANGES
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          ) : (
            <div className="flex justify-center items-center h-full">
              <CircularProgress />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ManageDocuments;
